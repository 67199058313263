<template>
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>
          <div class="col-xl-9 col-lg-8 col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="settings-widget">
                  <div class="settings-inner-blk p-0">
                    <div class="sell-course-head comman-space">
                      <div class="flex justify-between items-center">
                        <h3>Leçons du cours : {{ storeCours.course.title }}</h3>
                        <div class="go-dashboard text-center">
                          <button @click="showAddModal" class="btn btn-primary">
                            <i class="font-bold fa fa-plus"></i>
                            Ajouter
                          </button>
                        </div>
                        <dialog id="add_modal" class="modall bg-transparent w-full justify-center">
                          <div class="modal-box flex flex-col space-y-5">
                            <h3 class="font-bold text-lg">Nouvelle leçon</h3>
                            <input
                              type="text"
                              v-model="title"
                              class="input input-bordered w-full"
                              name="titre"
                              id="titre"
                              placeholder="Titre de la leçon"
                            />
                            <label for="formFileLg" :class="input_video ? 'bg-gray-100' : ''" class="custom-file-upload p-2 py-5 flex items-center justify-center rounded border-2 border-dashed input-bordered cursor-pointer hover:!cursor-pointer hover:bg-gray-100">
                              <span>{{ input_video ? input_video.name : 'Sélectionnez une video' }}</span>
                              <input
                                type="file"
                                id="formFileLg"
                                @change="uploadVideo"
                                ref="fileInput"
                                accept="video/*"
                                style="display:none;"
                                class="file-input file-input-bordered w-full"
                              />
                            </label>
                            <div class="form-group">
                              <video
                                v-if="input_video_url"
                                class="add-image-bx add-video-box rounded"
                                :src="input_video_url"
                                controls
                              ></video> 
                            </div>
                            <div
                              v-if="storeLessons.loading === true"
                              class="flex justify-between items-center justify-center space-x-3"
                              > 
                              <div class="progress-bar">
                                <div class="progress-bar-value"></div>
                              </div>
                              <span
                              class="text-md font-bold text-black md:leading-normal"
                              >{{ storeLessons.progress }}%</span
                              >
                            </div>
                            <div class="modal-action">
                              <button @click="closeAdd" class="btn bg-black text-white">
                                Fermer
                              </button>
                              <button
                                :disabled="storeLessons.loading"
                                @click="soumettre"
                                style="background-color: #58BBDE;"
                                class="btn bg-[#58BBDE] text-white"
                              > 
                                  <span>Ajouter</span>
                              </button>
                            </div>
                          </div>
                        </dialog>
                      </div> 
                     </div>

                    <div class="comman-space">
                      <div class="bg-white text-dark rounded-b-2xl ">
                        <div v-if="storeCours.course !== null" class="grid grid-cols-1 gap-3 flex justify-center ">
                          <!-- lesson -->
                          <div
                            v-for="(item, index) in storeLessons.coursLessons"
                            :key="index"
                            class="rounded border px-3 py-3 flex justify-between items-center"
                          >
                            <h3 class="text-lg items-center"> <strong>Leçon {{ index + 1 }} :</strong>  {{ item.title }}</h3>
                            <span class="flex space-x-2 items-center">
                              <button href="" @click="videoModal(item.video)">
                                <i class="fa fa-eye"></i>
                              </button>
                              <button href="" @click="showModalForm(index, item.id)">
                                <i class="fa fa-edit text-[#58bbde]"></i>
                              </button>
                              <button @click="deleteLessons(item.id)">
                                <i class="fa fa-trash text-red-500"></i>
                              </button>
                            </span>
                            <dialog :id="`edit_modal_${index}`" class="modall bg-transparent w-full justify-center">
                              <div class="modal-box flex flex-col space-y-5">
                                <h3 class="font-bold text-lg">Modifier la leçon</h3>
                                <input
                                  type="text"
                                  v-model="title"
                                  class="input input-bordered w-full"
                                  name="titre"
                                  id="titre"
                                  placeholder="Titre de la leçon"
                                />
                                <label for="formFileLg" :class="input_video ? 'bg-gray-100' : ''" class="custom-file-upload p-2 py-5 flex items-center justify-center rounded border-2 border-dashed input-bordered cursor-pointer hover:!cursor-pointer hover:bg-gray-100">
                                  <span>{{ input_video ? input_video.name : 'Sélectionnez une video' }}</span>
                                  <input
                                    type="file"
                                    id="formFileLg"
                                    @change="uploadVideo"
                                    ref="fileInput"
                                    accept="video/*"
                                    style="display:none;"
                                    class="file-input file-input-bordered w-full"
                                  />
                                </label>
                                <div class="form-group">
                                  <video
                                    v-if="input_video_url"
                                    class="add-image-bx add-video-box rounded"
                                    :src="input_video_url"
                                    controls
                                  ></video> 
                                </div>
                                <!-- <div class="mt-4" v-if="progress > 0">
                                  <div class="progress-stip">
                                      <div :style="{'width': `${progress}%`}" class="progress-bar bg-success progress-bar-striped active-stip"></div>
                                  </div> 
                                  <p class="mt-2">{{ Math.round(progress) }}%</p>
                                </div> -->
                                <div
                                  v-if="storeLessons.loading === true"
                                  class="flex justify-between items-center justify-center space-x-3"
                                  > 
                                  <div class="progress-bar">
                                    <div class="progress-bar-value"></div>
                                  </div>
                                  <span
                                  class="text-md font-bold text-black md:leading-normal"
                                  >{{ storeLessons.progress }}%</span
                                  >
                                </div>
                                <div class="modal-action">
                                  <button
                                    @click="closeUpdate"
                                    class="btn bg-black text-white"
                                  >
                                    Fermer
                                  </button>
                                  <button
                                    :disabled="storeLessons.loading"
                                    @click="update"
                                    style="background-color: #58BBDE;"
                                    class="btn bg-[#58BBDE] text-white"
                                  >
                                      <div v-if="storeLessons.loading === true" class="spinner-border" role="status">
                                          <span class="sr-only">Chargement...</span>
                                      </div>
              
                                      <span v-else>Modifier</span>
                                  </button>
                                </div>
                              </div>
                            </dialog>
                          </div>
                        </div>
                      </div>
                      <dialog id="modal_video" class="modall bg-transparent w-full justify-center">
                        <div class="modal-box flex flex-col space-y-5">
                          <h3 class="font-bold text-lg">Video de la leçon</h3>
                          <video
                            id="my-video"
                            class="rounded-2xl w-[100%]"
                            :src="videoUrl"
                            controls
                          ></video>
                          <div class="modal-action">
                            <button @click="closeVideo" class="btn bg-black text-white">
                              Fermer
                            </button>
                          </div>
                        </div>
                      </dialog>
                      <div
                        v-if="storeLessons.coursLessons.length === 0"
                        class="flex flex-col items-center h-screen"
                      > 
                        <p class=" mt-4">
                          Aucune leçon disponible pour ce cour
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <layouts1></layouts1>
  </div>
</template>
<script>
import { useAuthStore } from '@stores/auth'
import { router } from '@/router';
import { useLessonsStore } from '@stores/lessons'
import { useCoursesStore } from '@stores/courses'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  data() {
    return {
          storeAuth: useAuthStore(),
          storeLessons : useLessonsStore(),
          storeCours : useCoursesStore(),
          title : "",
          video : "",
          input_video : null,
          input_video_url : null,
          videoUrl : "",
          fileInput : "",
          index : 0,
          uploading : false,
          progress : 0,
          id : 0,
        }
    },

    async mounted() {
        document.title = "Leçons - The Music Hall";
        // await this.storeCours.get(router.currentRoute.value.params.slug);
        await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
    },
    async beforeMount() {
        await this.storeCours.get(router.currentRoute.value.params.slug);
    },

    methods: {
      async soumettre() {
        if (this.title !== "" && this.video !== "") {
          const data = new FormData();
          data.append("title", this.title);
          data.append("video", this.video);
          data.append("course_id", this.storeCours.course.id);

          await this.storeLessons.addLessons(data, this.id) ;
          await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
          const dialog = document.getElementById("add_modal");
          dialog.classList.remove('flex');
          await dialog.close();
          toast.success("Leçon ajoutée.", {autoClose: 3000,});
         
        }
      },
      showAddModal(){
        const dialog = document.getElementById("add_modal");
        this.input_video = null;
        this.input_video_url = null;
        dialog.classList.add('flex');
        this.resetDialog("add_modal");
        dialog.showModal();
      },

        videoModal(video) {
            const dialog = document.getElementById("modal_video");
            this.videoUrl = video;
            dialog.classList.add('flex');
            dialog.showModal();
        },
        closeVideo(){
            const dialog = document.getElementById("modal_video");
            dialog.classList.remove('flex');
            this.videoUrl = "";
            dialog.close();
        },
        async update() {
            const dialog = document.getElementById(`edit_modal_${this.index}`);
            if (this.title !== "") {
              const data = new FormData();
              data.append("title", this.title);
              if (this.video !== "") data.append("video", this.video);

              data.append("course_id", this.storeCours.course.id);
              await this.storeLessons.updateLessons(data, this.id);
              await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
              this.title = ""
              dialog.classList.remove('flex');
              await dialog.close();
              toast.success("Leçon mise à jour.", {autoClose: 3000,});
            }
        },
        closeAdd() {
            const dialog = document.getElementById("add_modal");
            this.storeLessons.loading = false;
            dialog.classList.remove('flex');
            dialog.close();
        },
        closeUpdate() {
            const dialog = document.getElementById(`edit_modal_${this.index}`);
            this.storeLessons.loading = false;
            this.storeLessons.progress = false;
            dialog.classList.remove('flex'); 
            dialog.close();
        },
        uploadVideo(event) {
            this.video = event.target.files[0];
            const file = event.target.files[0];
            this.input_video = file
            if (file) {
              this.input_video_url = URL.createObjectURL(file);
            }
        },
        showModalForm(indice, key) {
            console.log(indice)
            this.index = indice;
            this.id = key;
            const dialog = document.getElementById(`edit_modal_${indice}`);
            this.input_video = null;
            this.input_video_url = null;
            dialog.classList.add('flex');
            this.title = this.storeLessons.coursLessons[indice].title;
            dialog.showModal();
        },
        addModalForm() {
            const dialog = document.getElementById("add_modal");
            this.title = "";
            this.video = "";
            dialog.showModal();
        },
        resetDialog(dialog_id) {

          const dialog = document.getElementById(dialog_id);
          this.title = "";   
          this.$refs.fileInput.value = "";   
          dialog.close();
        },
        async deleteLessons(id) {
          if (confirm("Êtes vous sûr de vouloir supprimer cette leçon ? (Cette action sera irréversible!)")) {
            await this.storeLessons.deleteLessons(id);
            await this.storeCours.get(router.currentRoute.value.params.slug);
            toast.success("Leçon supprimée.", {autoClose: 3000,});
          }
          await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
        }
    },
}
</script>

<style>
.progress-bar {
  height: 10px;
  background-color: #000000 !important;
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #58bbde;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>
