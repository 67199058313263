<template lang="">
    <div>
        
    </div>
</template>
<script>
import { useAuthStore } from '@stores/auth'
import { router } from '@/router';
export default {
    data() {
        return {
            storeAuth: useAuthStore(), 
        }
    },
    async mounted() {
        await this.storeAuth.confirmPayment(router.currentRoute.value.params.id_paiement);
    },
}
</script>
<style lang="">
    
</style>