<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>
    <!-- Page Wrapper -->
    <div class="-mt-9 big_screen:-mt-[4%] pt-9 big_screen:pt-[4%]">
      <img src="https://v1.themusichall.fr/assets/wave.png" class=" w-full -mb-[450px] lg:h-[700px] lg:-mb-[750px] hidden md:flex" alt="">
      <section
        class=" bg-cover bg-no-repeat bg-white bg-no-repeat w-full flex flex-col space-y-0 justify-center items-center rounded-b-full"
      >
        <div
          class="pt-[70px] lg:pt-[240px] bg-[#F2F2F200] lg:px-40 big_screen:px-0 space-y-9 w-full"
        >
          <div class="relative flex flex-col justify-center items-center">
            <img src="https://v1.themusichall.fr/assets/go_premium.png" alt="" class="md:w-[70%] big_screen:w-[40%]" /><a
              href="#"
              class="transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300 absolute mt-[10%] big_screen:mt-[5%] inline-flex mb-0 md:mb-10 text-white bg-black border-0 py-3 px-8 font-bold focus:outline-none rounded-xl text-lg big_screen:text-2xl"
              >Passez en premium</a
            >
          </div>
        </div>
        <div
          class="bg-[#F2F2F200] w-full flex flex-col md:px-[31%] space-y-3 pt-0"
        >
          <h4
            class="mt-9 text-center font-bold text-black md:text-2xl big_screen:text-3xl mb-3 md:leading-normal"
          >
            Vos Badges
          </h4>
          <div class="flex flex-row space-x-5 lg:space-x-12 justify-center">
            <div class="flex flex-col space-y-2 items-center" :key="index" v-for="(item, index) in storeAuth.user.badges">
              <div class="avatar">
                <div
                  class="w-12 rounded-full transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  <div class="bg-black p-2">
                    <img
                      class="w-[24px]"
                      :src="item.icon"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h4
                class="xs:mt-5 text-center font-bold text-black text-sm md:text-xl mb-3 md:leading-normal"
              >
              {{ item.name }}
              </h4>
            </div>
            <div class="flex flex-col space-y-2 items-center">
              <div class="avatar">
                <div
                  class="w-12 rounded-full bg-black justify-center flex items-center transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  <div
                    class="bg-black p-2 justify-center items-center flex text-center"
                  >
                    <span class="text-white font-bold text-md mt-1 w-auto"
                      >{{ storeAuth.user.total_etudiant < 10 ? '0' : ''  }}{{ storeAuth.user.total_etudiant }}</span
                    >
                  </div>
                </div>
              </div>
              <h4
                class="xs:mt-5 text-center font-bold text-black text-sm md:text-xl mb-3 md:leading-normal"
              >
                Elève{{ storeAuth.user.total_etudiant > 1 ? 's' : ''  }}
              </h4>
            </div>
          </div>
        </div>
      </section>
    
      <section
        class="-mb-9 mt-7 bg-white w-full flex flex-col space-y-0 justify-center items-center rounded-b-full big_screen:py-9"
      >
        <div class="w-full flex flex-col space-y-3 pt-0">
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:pl-9"
          >
            <div class="h-auto relative px-6 md:px-0 md:flex md:items-center md:justify-center">
              <img
                class=" hidden md:flex xs:rounded-[45px] lg:rounded-[20px] big_screen:rounded-[20px] md:inset-0 md:w-[50%] lg:w-full lg:h-[700px] xs:m-auto big_screen:h-[755px] grayscale blur"
                :src="storeAuth.user.photo"
                alt=""
                />
              <img
                class="mx-auto m-auto md:absolute xs:rounded-[100px] md:rounded-[15px] lg:rounded-[80px] big_screen:rounded-[80px] md:inset-0 lg:p-[10%]   lg:h-[700px] xs:m-auto big_screen:h-[755px]"
                :src="storeAuth.user.photo"
                alt=""
                />
            </div> 
            <div class="flex flex-col space-y-6 px-5 py-9">
              <h4
                class="xs:mt-5 font-extrabold text-black xs:text-xl md:text-5xl  mb-3 md:leading-normal"
              >
                Bienvenue, {{ storeAuth.user.first_name }} {{ storeAuth.user.last_name }}
              </h4>
              <div class="flex flex-col space-y-3 big_screen:text-lg">
                <a href="/messages" class="flex space-x-2 items-center">
                  <span
                    class="rounded text-center bg-black text-white w-[35%] md:w-[15%] big_screen:w-[18%] font-extrabold text-xl py-1 big_screen:text-3xl"
                    >0</span
                  ><span class="font-bold">Nouveaux messages reçus</span>
                </a>
                <a href="/notifications" class="flex space-x-2 items-center">
                  <span
                    class="rounded text-center bg-black text-white w-[35%] md:w-[15%] big_screen:w-[18%] font-extrabold text-xl py-1 big_screen:text-3xl"
                    >{{ storeNotification.listNotifications.length < 10 ? '0' : '' }}{{ storeNotification.listNotifications.length }}</span
                  ><span class="font-bold">Nouvelles notifications reçues</span>
                </a>
                <div class="flex space-x-2 items-center">
                  <span
                    class="rounded text-center bg-black text-white w-[35%] md:w-[15%] big_screen:w-[18%] font-extrabold text-xl py-1 big_screen:text-3xl"
                    >{{ storeAuth.user.notes < 10 ? '0' : '' }}{{ storeAuth.user.notes }}</span
                  ><span class="font-bold"
                    >Votre note globale actuelle sur 5</span
                  >
                </div>
              </div>
            </div>
            <div
              class="md:col-span-2 lg:col-span-1 flex flex-col space-y-6 px-5 py-9 mt-9 big_screen:w-2/3  big_screen:text-2xl"
            >
              <a href="/instructor-statistic"
                class="rounded-2xl text-center bg-black text-white flex  flex-col space-y-3 py-3 px-6 "
              >
                <span class="text-md">vos gains</span
                ><span
                  class="font- text-4xl lg:text-5xl big_screen:text-7xl text-[#884283]"
                  >{{ storeAuth.user.total_gain }} €</span
                >
              </a>
              <div
                class="rounded-2xl text-center bg-black text-white flex flex-col space-y-3 py-3 px-6"
              >
                <span class="text-md">vos élèves inscrits</span
                ><span
                  class="font- text-4xl lg:text-5xl big_screen:text-7xl text-[#884283]"
                  >{{ storeAuth.user.total_etudiant < 10 ? '0' : ''  }}{{ storeAuth.user.total_etudiant }}</span
                >
              </div>
              <a href="/instructor-course"
                class="rounded-2xl text-center bg-black text-white flex flex-col space-y-3 py-3 px-6"
              >
                <span class="text-md">votre nombre de cours en ligne</span
                ><span
                  class="font- text-4xl lg:text-5xl big_screen:text-7xl text-[#884283]"
                  >{{ storeCourses.listCoursesProfs.length < 10 ? '0' : ''  }}{{ storeCourses.listCoursesProfs.length  }}</span
                >
              </a>
            </div>
          </div>
        </div>
      </section>
      <section
      class="mt-[5px] md:mt-0 bg-cover bg-no-repeat flex flex-col justify-center items-center">
        <img src="@/assets/bg/top_wave.png" class="hidden md:flex w-full h-[700px] -mb-[700px]" alt="">
        <div
          class=" w-full flex flex-col px-9 lg:px-[21%] space-y-9 pt-0"
        >
          <h4
            class="mt-5 text-center font-bold text-black text-2xl md:text-4xl mb-3 md:leading-normal"
          >
            Vos cours actuellement en ligne
          </h4>
          <div class="flex flex-col md:flex-row space-x-5 items-center justify-center" v-if="storeCourses.listCoursesProfs.length >0">
            <a :href="'/course-details/'+item.slug"  v-for="(item, index) in storeCourses.listCoursesProfs" :key="index" class="flex flex-col space-y-2 items-center cursor-pointer justify-end">
              <img
                :src="item.image"
                class="rounded-xl w-[300px] h-[200px]"
                alt=""
              />
              <h4
                class="xs:mt-5 text-center text-black big_screen:text-3xl mb-3 md:leading-normal"
              >
                {{item.name}}
              </h4>
              <span class="flex space-x-2"></span>
            </a >
          </div>

          <div class="flex flex-col items-center justify-center md:!h-[300px] space-y-5" v-else>
            <img src="@/assets/img/empty.png" class="w-[150px] mb-3" alt="">
            Vous n'avez aucun cours
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 md:gap-6 lg:px-[20%] pb-[10%]">
            <a
              href="/instructor-course"
              class="transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300 hover:bg-transparent border-2 hover:border-black hover:text-black xs:mt-5 bg-black rounded-xl p-3 text-center font-bold text-white text-xl md:text-2xl mb-3 md:leading-normal"
              >Gérez vos cours</a
            ><a
              href="/course"
              class="transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300 hover:bg-transparent border-2 hover:border-black hover:text-black xs:mt-5 bg-black rounded-xl p-3 text-center font-bold text-white text-xl md:text-2xl mb-3 md:leading-normal"
              >Formez-vous</a
            >
          </div>
        </div>
      </section>
    </div>
    <!-- /Page Wrapper -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { useAuthStore } from "@stores/auth";
import { useCoursesStore } from "@stores/courses";
import { useNotificationStore } from "@stores/notification";

export default {
  data: function () {
    return {
      storeAuth: useAuthStore(),
      storeCourses: useCoursesStore(), 
      storeNotification: useNotificationStore(), 
      page: 1,
    };
  },
  async mounted() {
    if(this.storeAuth.user !== null){
      await this.storeNotification.list();
    }
    await this.storeCourses.bestCoursesProf();
    await this.storeCourses.onlineProfCourses(this.storeAuth.user.id, this.page);
  },
};
</script>
