<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsHome v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layoutsHome>
    <layoutsindex ></layoutsindex>
    <blogmasonry></blogmasonry>
    <!-- Blog Grid -->
    <section v-if="storeBlog.listBlog.length > 0" class="course-content">
      <div class="container">
        <div class="row masonry-blog-blk">
          <div v-for="(item,index) in storeBlog.listBlog" :key="index" class="col-lg-4 col-md-6">
            <!-- Blog Post -->
            <div class="blog grid-blog">
              <div class="blog-image">
                <router-link :to="{ name: 'blog-details', params: { slug: item.slug } }"
                  ><img
                    class="img-fluid"
                    :src="item.image"
                    alt="Post Image"
                /></router-link>
              </div>
              <div class="blog-grid-box masonry-box">
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-22.svg"
                          alt=""
                        />{{ item.created_at }}
                      </li>
                      <li>
                        <img
                          class="img-fluid"
                          src="@/assets/img/icon/icon-23.svg"
                          alt=""
                        />{{ item.categorie }}
                      </li>
                    </ul>
                  </div>
                </div>
                <h3 class="blog-title">
                  <router-link :to="{ name: 'blog-details', params: { slug: item.slug } }"
                    >{{ item.title }}</router-link
                  >
                </h3>
                <div class="blog-content blog-read">
                  <p>
                    {{ item.content.substring(0, 100) }} […]
                  </p>
                  <router-link :to="{ name: 'blog-details', params: { slug: item.slug } }" class="read-more btn btn-primary"
                    >Lire la suite</router-link
                  >
                </div>
              </div>
            </div>
            <!-- /Blog Post -->
          </div>
          
        </div>

        <!-- Load button -->
        <div @click="storeBlog.loading === true ? page : page++" v-if="storeBlog.empty !== true" class="load-more text-center">
          <div v-if="storeBlog.loading === true" class="spinner-border" role="status">
            <span class="sr-only">Chargement...</span>
          </div>
          <span class="btn btn-primary" v-else>
            Voir plus
          </span>
        </div>
        <!-- /Load button -->
      </div>
    </section>
    <!-- /Blog Grid -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
  import { useBlogStore } from '@stores/blog'
  import { useAuthStore } from '@stores/auth'
  export default {
    data() {
      return {
        storeAuth: useAuthStore(),
        storeBlog: useBlogStore(),
        page: 1,
      };
    },
    watch: {
      async page(newValue) {
        await this.storeBlog.list(newValue)
      }
    },
    async mounted() {
      await this.storeBlog.list(this.page)
    },

    methods: {
      
    },
  };
</script>