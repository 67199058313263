<template>
  <router-view />
</template>

<script>
import { useAuthStore } from '@stores/auth'
import { useCartStore } from '@stores/cart'
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      storeCart: useCartStore()
    };
  },
  async created() {
    if (localStorage.getItem("access_token") !== null) {
      await this.storeAuth.Me()
      await this.storeCart.listCartCourse()
    }
  },
}
</script>