<template>
  <div class="col-lg-3 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="filter-clear">
        <div class="clear-filter d-flex align-items-center">
          <h4><i class="feather-filter"></i>Filtrer les cours par : </h4>
          <div class="clear-text">
            <!-- <p>CLEAR</p> -->
          </div>
        </div>

        <!-- Search Filter -->
        <div class="card search-filter">
          <div class="card-body">
            <div class="filter-widget mb-0">
              <div class="categories-head d-flex align-items-center">
                <h4>Catégories</h4>
                
              </div>
              <div v-for="(item, index) in storeCategorie.courseCategorie" :key="index">
                <label :class="index + 1 !== storeCategorie.courseCategorie.length ? 'custom_check' : 'custom_check mb-0'">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> {{ item.name }} ({{ item.courses }})
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- /Search Filter -->

        <!-- Search Filter -->
        <div class="card search-filter">
          <div class="card-body">
            <div class="filter-widget mb-0">
              <div class="categories-head d-flex align-items-center">
                <h4>Professeurs</h4>
                
              </div>
              <div v-for="(item, index) in storeProf.listProfs.filter(item => item.total_courses > 0)" :key="index">
                <label :class="index + 1 !== storeProf.listProfs.length ? 'custom_check' : 'custom_check mb-0'">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> {{ item.first_name }} {{ item.last_name }} ({{ item.total_courses }})
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- /Search Filter -->

        <!-- Search Filter -->
        <div class="card search-filter">
          <div class="card-body">
            <div class="filter-widget mb-0">
              <div class="categories-head d-flex align-items-center">
                <h4>Prix</h4>
                
              </div>
              <div>
                <label class="custom_check custom_one">
                  <input type="radio" name="prix" checked value="tout" />
                  <span class="checkmark"></span> Tout (18)
                </label>
              </div>
              <div>
                <label class="custom_check custom_one">
                  <input type="radio" name="prix" value="gratuit" />
                  <span class="checkmark"></span> Gratuit (3)
                </label>
              </div>
              <div>
                <label class="custom_check custom_one mb-0">
                  <input type="radio" name="prix" value="payant"  />
                  <span class="checkmark"></span> Payant (15)
                </label>
              </div>
            </div>
          </div>
        </div>
         
      </div>
    </div>
  </div>
</template>

<script>
import { useCategorieStore } from '@stores/categorie'
import { useProfStore } from '@stores/profs'
import { useCoursesStore } from '@stores/courses'
export default {
  data() {
    return {
      storeCategorie: useCategorieStore(),
      storeProf: useProfStore(),
      storeCourses: useCoursesStore(),
    };
  },
  async mounted() {
    await this.storeCategorie.courseCategories()
    await this.storeProf.list()
  },
};
</script>