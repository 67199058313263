<template>
  <!-- Main Wrapper -->
  <section class="flex w-full bg-gray-100 items-center min-h-screen justify-center">
    <div class="flex w-full md:w-[40%] big_screen:w-[40%] h-auto">
      <div class="bg-white w-full rounded-lg xs:mx-2 lg:mx-0 lg:w-full rounded-lg px-6 py-9 flex flex-col space-y-4 justify-center">
        <div class="flex justify-between w-full items-center">
          <img src="https://themusichall.fr/assets/logo.svg"  alt="Logo" class="w-12" />
        </div>
        <h2 class="font-bold text-2xl">Finalisez votre profil enseignant</h2>
        <div class="flex space-x-3 justify-between">
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span class="active-color active-bar">1</span>
            <div class="personal-text">
              <h4>Catégories et Profil</h4>
            </div>
          </div>
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span>2</span>
            <div class="personal-text">
              <h4>Profils sociaux</h4>
            </div>
          </div>
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span>3</span>
            <div class="personal-text">
              <h4>Biographie</h4>
            </div>
          </div> 
        </div>
        <form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group flex items-center justify-between">
                <label class="add-course-label font-bold">Categories</label>
                <vue-select :settings="{ multiple: true }" :options="getCategories" v-model="categories" class="w-1/2"/>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group flex justify-between items-center">
                <label class="add-course-label font-bold">Photo de profil (320 x 437 px)</label>
                <label for="formFileLg" :class="photoUrl ? 'bg-gray-100' : ''" class="w-1/2 custom-file-upload p-2 py- flex items-center justify-center rounded border-2 border-dashed input-bordered cursor-pointer hover:!cursor-pointer hover:bg-gray-100">
                  <span>{{ photoUrl ? photoUrl.name : 'Sélectionnez une image' }}</span>
                  <input id="formFileLg" @change="handleFileChange" accept="image/*" class="form-control form-control-lg" type="file" style="display:none;">
                </label>
              </div>
            </div> 
            <div class="col-lg-12">
              <div class="form-group flex justify-between items-center">
                <label class="add-course-label font-bold">Bannière (1450 x 686 px)</label>
                <label for="formFileLgBanner" :class="banner ? 'bg-gray-100' : ''" class="w-1/2  custom-file-upload p-2 py- flex items-center justify-center rounded border-2 border-dashed input-bordered cursor-pointer hover:!cursor-pointer hover:bg-gray-100">
                  <span>{{ banner ? banner.name : 'Sélectionnez une image' }}</span>
                  <input id="formFileLgBanner" @change="handleFileChangeBanner" accept="image/*" class="form-control form-control-lg" type="file" style="display:none;">
                </label>
              </div>
            </div> 
            <p>
              *Redimensionnez vos images <a class="text-[#58bbde] font-bold text-underline" href="https://www.iloveimg.com/crop-image" target="_blank"><u>ici</u></a> 
            </p>
            <div v-if="isCheck === true" class="col-lg-12 mt-4 flex justify-end">
              <div class="next-btn">
                <button class="btn btn-primary btn-start" type="submit">
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  <!-- /Main Wrapper -->
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css";
import { useCategorieStore } from '@stores/categorie'
import { useAuthStore } from '@stores/auth'
export default { 
  data() {
    return {
      storeCategorie: useCategorieStore(),
      storeAuth: useAuthStore(),
      imageUrl: null,
      bannerUrl: null,
      photoUrl: null,
      banner: null,
      categories: [],
      categoriesList: [],
    };
  },
  async mounted() {
    await this.storeCategorie.courseCategories()
    console.log(this.storeCategorie.courseCategorie)
  },
  computed: {
    isCheck(){
      if(this.photoUrl === null || this.banner === null || this.categories.length < 0){
        return false
      } else {
        return true
      }
    },
    getCategories(){
      let array = []
      if(this.storeCategorie.courseCategorie.length > 0){
        for (let index = 0; index < this.storeCategorie.courseCategorie.length; index++) {
          array.push({
            id: this.storeCategorie.courseCategorie[index].id,
            text: this.storeCategorie.courseCategorie[index].name
          })
          
        }
      }
      return array
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const maxWidth = 320; 
            const maxHeight = 437;

            if (img.width != maxWidth || img.height != maxHeight) {
              alert(`Veuillez sélectionner une image de dimensions ${maxWidth}x${maxHeight}px.`);
              event.target.value = '';
              this.photoUrl = null;
              return;
            }else{
              this.photoUrl = file;
              this.imageUrl = reader.result;
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handleFileChangeBanner(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const maxWidth = 1450; 
            const maxHeight = 686;
            
            if (img.width != maxWidth || img.height != maxHeight) {
              alert(`Veuillez sélectionner une image de dimensions ${maxWidth}x${maxHeight}px.`);
              event.target.value = '';
              this.banner = null;
              return;
            }else{
              this.banner = file
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    submitForm() {
      this.storeAuth.prof.photo = this.photoUrl
      this.storeAuth.prof.banner = this.banner
      this.storeAuth.prof.categories = this.categories
      this.$router.push("/register-step-two");
    },
    summernoteChange() {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert() {
      // console.log("summernoteImageLinkInsert()", args);
    },
  },
};
</script>
