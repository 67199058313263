<template>
  <ul class="main-nav">
  </ul>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      isVisiblehome: false,
      isVisibleinstructor: false,
      isVisiblestudent: false,
      isVisiblepages: false,
      isVisibleblog: false,
      isVisibleinstructorsub: false,
      isVisiblestudentsub: false,
      isVisiblecourse: false,
      isVisibleerrorpage: false,
    };
  },
  methods: {
    toggleVisibilityhome() {
      this.isVisiblehome = !this.isVisiblehome;
    },
    toggleVisibilityinstructor() {
      this.isVisibleinstructor = !this.isVisibleinstructor;
    },
    toggleVisibilitystudent() {
      this.isVisiblestudent = !this.isVisiblestudent;
    },
    toggleVisibilitypages() {
      this.isVisiblepages = !this.isVisiblepages;
    },
    toggleVisibilityblog() {
      this.isVisibleblog = !this.isVisibleblog;
    },
    toggleVisibilityinstructorsub() {
      this.isVisibleinstructorsub = !this.isVisibleinstructorsub;
    },
    toggleVisibilitystudentsub() {
      this.isVisiblestudentsub = !this.isVisiblestudentsub;
    },
    toggleVisibilitycourse() {
      this.isVisiblecourse = !this.isVisiblecourse;
    },
    toggleVisibilityerrorpage() {
      this.isVisibleerrorpage = !this.isVisibleerrorpage;
    },
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    HomeMenu() {
      return (
        this.$route.name == "index" ||
        this.$route.name == "index-two" ||
        this.$route.name == "index-three" ||
        this.$route.name == "index-four"
      );
    },
    InstructorMenu() {
      return (
        this.$route.name == "instructor-list" ||
        this.$route.name == "withdrawal-instructor" ||
        this.$route.name == "transactions-instructor" ||
        this.$route.name == "instructor-student-list" ||
        this.$route.name == "instructor-student-grid" ||
        this.$route.name == "instructor-profile" ||
        this.$route.name == "instructor-new-tickets" ||
        this.$route.name == "instructor-grid-2" ||
        this.$route.name == "instructor-edit" ||
        this.$route.name == "instructor-chat" ||
        this.$route.name == "deposit-instructor-dashboard" ||
        this.$route.name == "deposit-instructor" ||
        this.$route.name == "dashboard-instructor" ||
        this.$route.name == "instructor-dashboard" ||
        this.$route.name == "instructor-grid" ||
        this.$route.name == "instructor-course" ||
        this.$route.name == "instructor-reviews" ||
        this.$route.name == "instructor-earnings" ||
        this.$route.name == "instructor-orders" ||
        this.$route.name == "instructor-payouts" ||
        this.$route.name == "instructor-tickets" ||
        this.$route.name == "instructor-edit-profile" ||
        this.$route.name == "instructor-security" ||
        this.$route.name == "instructor-social-profiles" ||
        this.$route.name == "instructor-notification" ||
        this.$route.name == "instructor-profile-privacy" ||
        this.$route.name == "instructor-delete-profile" ||
        this.$route.name == "instructor-linked-account"
      );
    },
    InstructorlistMenu() {
      return (
        this.$route.name == "instructor-list" ||
        this.$route.name == "instructor-grid" ||
        this.$route.name == "instructor-grid-2"
      );
    },
    StudentMenu() {
      return (
        this.$route.name == "students-list" ||
        this.$route.name == "view-invoice" ||
        this.$route.name == "transactions-student" ||
        this.$route.name == "students-grid2" ||
        this.$route.name == "student-profile" ||
        this.$route.name == "setting-support-view-tickets" ||
        this.$route.name == "setting-student-delete-profile" ||
        this.$route.name == "deposit-student-dashboard" ||
        this.$route.name == "deposit-student" ||
        this.$route.name == "purchase-history" ||
        this.$route.name == "mes-course" ||
        this.$route.name == "course-message" ||
        this.$route.name == "students-grid" ||
        this.$route.name == "setting-edit-profile" ||
        this.$route.name == "setting-student-security" ||
        this.$route.name == "setting-student-social-profile" ||
        this.$route.name == "setting-student-notification" ||
        this.$route.name == "setting-student-privacy" ||
        this.$route.name == "setting-student-accounts" ||
        this.$route.name == "setting-student-referral" ||
        this.$route.name == "setting-student-subscription" ||
        this.$route.name == "setting-student-billing" ||
        this.$route.name == "setting-student-payment" ||
        this.$route.name == "setting-student-invoice" ||
        this.$route.name == "setting-support-tickets"
      );
    },
    StudentlistMenu() {
      return (
        this.$route.name == "students-list" ||
        this.$route.name == "students-grid" ||
        this.$route.name == "students-grid2"
      );
    },
    PagesMenu() {
      return (
        this.$route.name == "notifications" ||
        this.$route.name == "term-condition" ||
        this.$route.name == "privacy-policy" ||
        this.$route.name == "course-wishlist" ||
        this.$route.name == "pricing-plan-2" ||
        this.$route.name == "pricing-plan" ||
        this.$route.name == "wishlist" ||
        this.$route.name == "add-course" ||
        this.$route.name == "course-list" ||
        this.$route.name == "course-grid" ||
        this.$route.name == "course-details" ||
        this.$route.name == "course-details1" ||
        this.$route.name == "course-details2" ||
        this.$route.name == "come-soon" ||
        this.$route.name == "error-404" ||
        this.$route.name == "error-500" ||
        this.$route.name == "under-construction" ||
        this.$route.name == "help-center" ||
        this.$route.name == "faq" ||
        this.$route.name == "support" ||
        this.$route.name == "job-category" ||
        this.$route.name == "cart" ||
        this.$route.name == "checkout" ||
        this.$route.name == "login" ||
        this.$route.name == "register" ||
        this.$route.name == "forgot-password" ||
        this.$route.name == "course-lesson"
      );
    },
    PageslistMenu() {
      return (
        this.$route.name == "add-course" ||
        this.$route.name == "course-details1" ||
        this.$route.name == "course-details2" ||
        this.$route.name == "course-list" ||
        this.$route.name == "course-grid" ||
        this.$route.name == "course-details"
      );
    },
    ErrorMenu() {
      return (
        this.$route.name == "come-soon" ||
        this.$route.name == "error-404" ||
        this.$route.name == "error-500" ||
        this.$route.name == "under-construction"
      );
    },
    BlogMenu() {
      return (
        this.$route.name == "blog-list" ||
        this.$route.name == "blog-grid" ||
        this.$route.name == "blog-masonry" ||
        this.$route.name == "blog-modern" ||
        this.$route.name == "blog-details"
      );
    },
  },
};
</script>
