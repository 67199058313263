<template>
  <!-- Header -->
  <header v-if="storeAuth.user" class="header header-page">
    <div class="header-fixed">
      <nav
        class="navbar navbar-expand-lg header-nav scroll-sticky "
        :class="{ 'add-header-bg': isSticky, 'md:!bg-[#F2F2F2]': isDashboardRoute }"

      >
        <div class="container">
          <div class="navbar-header flex justify-between w-full ">
            <router-link to="/" class="navbar-brand logo">
              <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <signpages v-if="storeAuth.user === null"></signpages>
            <headerpage></headerpage>
          </div>
          
        </div>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      isSticky: false,
      isSidebarOpen: true,
    };
  },
  computed: {
    isDashboardRoute() {
      return this.$route.path === '/instructor-dashboard';
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>
