<template>
  <!-- Home Banner -->
  <section :class="storeAuth.isLogin() === false ? 'mt-[5%] md:mt-[15%] big_screen:mt-[12%]' : 'md:mt-[10%] big_screen:mt-[7%]' " class="home-slide d-flex align-items-center  " style="height: auto;min-height: auto;/*min-height: 100vh;*/">
    <div class="container">
      <div class="row lg:pb-6">
        <div class="col-lg-6 md:w-full md:pt-24 lg:pt-0">
          <div class="home-slide-face aos" data-aos="fade-up">
            <div style="margin-top: -8%;" class="home-slide-text flex flex-col place-items-center   text-center lg:items-start lg:text-left space-y-">
              <h5>Mettez de la musique dans votre vie</h5>
              <h1 class="text-black lg:text-left">Voyage à travers les mélodies et les émotions!</h1>
              <p>Trouvez le cours de musique parfait pour vous</p>
              <div class="flex items-center space-x-3 rounded-full py-2 px-3 mb-3 border w-full">
                <i class="fa fa-search text-gray-400"></i>
                <input v-model="inputValue"  @keyup.enter="redirectToLink" type="text" class="w-full text-black" placeholder="Rechercher un cours dans notre catalogue">
                <label for="categories" class="cursor-pointer hover:!cursor-pointer bg-[#58bbda]  rounded-full text-left bg-[#58bbda] text-white py-1 px-3  py-2 flex justify-between  items-center w-1/3">
                  <select id="categories" v-model="selectedCategory" class="cursor-pointer hover:!cursor-pointer bg-[#58bbda] w-full" style="appearance: none;">
                    <option value="" class="text-white" disabled>Catégorie </option>
                    <option v-for="(item, index) in storeCategorie.courseCategorie.slice(0, 6)" :key="index" :value="item.name">{{ item.name }}</option>
                  </select>
                  <i class="fa fa-chevron-down"></i>
                </label>
                <a :href="generateLink()" class="" id="search_link">
                  <i class="fa fa-arrow-right rounded-full bg-[#58bbde] text-white p-3"></i>
                </a>
              </div>
              <!-- <div class=" mt-4 w-full justify-center">
                <div v-if="storeAuth.isLogin() !== true && storeAuth.user === null" class="flex justify-center lg:justify-start w-full md:space-y-0 space-x-3">
                  <router-link class="col-md-4 lg:mx-2 text-center nav-link text-white rounded py-1 px-4 shadow transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300" to="login" style="background-color: rgb(88 187 222 )">S'inscrire</router-link>
                  <router-link class="col-md-4 text-center nav-link border border-black font-gray rounded py-1 px-4 transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300" to="/register-prof" >Donner des cours</router-link>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex align-items-center">
          <div class="girl-slide-img aos" data-aos="fade-up">
            <img id="hero-banner" src="https://themusichall.fr/assets/hero.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Home Banner -->
  <div class="home pt-9 big_screen:pt-[1%]">
    <section
      class="bg-black py-[75px] lg:py-[140px] trigger flex flex-col items-center justify-center"
    >
      <div class="lg:max-w-7xl">
        <div
          class="flex items-center justify-between lg:px-40 big_screen:px-0 px-3 space-y-5 lg:space-y-0 lg:space-x-3 lg:flex-row flex-col"
        >
          <div
            data-aos="slide-up"
            class="lg:w-1/2 text-center aos-init aos-animate"
          >
            <h3
              class="font-bold text-white text-2xl md:text-4xl text-white lg:text-left md:leading-tight"
            >
              Apprenez avec les meilleurs <br />
              enseignants
            </h3>
          </div>
          <div
            data-aos="slide-up"
            class="lg:w-1/2 lg:mt-4 text-center aos-init aos-animate"
          >
            <p class="text-white lg:pl-0 pl-2 pr-2 md:text-xl lg:text-left">
              Profitez de cours interactifs, apprenez la musique à votre rythme.
              Démarrez votre aventure musicale maintenant.
            </p>
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-3 lg:gap-16 space-y-9 md:space-y-0 p-10 lg:px-40 big_screen:px-0 px-3"
        >
          <a
            v-for="(item, index) in storeProf.topProfs"
            :key="index"
            :href="'/instructor-profile/'+item.pseudo"
            data-aos="slide-up"
            data-aos-delay="0"
            data-aos-duration="900"
            class="flex flex-col cursor-pointer space-y-3 animated-content items-center md:items-start aos-init aos-animate"
          >
            <img
              :src="item.photo"
              alt=""
              class="rounded-xl"
              />
              <!-- class=" lg:w-[319px] lg:h-[435px] rounded-xl" -->
            <h3
              class="font-semibold text-white text-2xl text-white md:leading-normal"
            >
              {{ item.first_name }} {{ item.last_name }}
            </h3>
            <p v-for="(items, index) in item.categories.slice(0,1)" :key="index" class=" text-white text-lg">
              {{ items.name }}
            </p>
            <!-- <div class="flex">
            </div> -->
            <p class="text-white md:pl-0 lg:text-lg " v-if="item.description != null" >
            </p>
            <!-- <p class="text-gray-300 text-md mt-0 mb-2 py-2">
              consectetur adipiscing elit. Vivamus pulvinar accumsan.
            </p> -->
            <p class="text-gray-300 text-md mt-0 mb-2 py-2">
              {{ item.description.slice(0,54) }}...
            </p>
            <div class="">
              <router-link class="text-white w-auto text-sm bg-[#58BBDE] border-0 px-8 py-2 focus:outline-none rounded-sm transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              :to="{ name: 'instructor-profile', params: { pseudo: item.pseudo } }" >En savoir plus</router-link>
            </div>
          </a>
        </div>
      </div>
    </section>
  
    <section
      class="py-[25px] lg:py-[30px] bg-gradient-to-b to-50% from-[#BCEFFE] to-white py-9 trigger flex justify-center"
    >
      <div class="lg:max-w-7xl">
        <div
          data-aos="slide-up"
          class="pt-10 lg:px-40 big_screen:px-0 px-3 space-y-4 text-center flex-col items-center aos-init aos-animate"
        >
          <h3
            class="mt-5 text-center font-bold text-black text-2xl md:text-4xl mb-3 md:leading-normal"
          >
            Jamais il n'a été aussi facile d'apprendre.
          </h3>
          <p class="md:text-xl text-black">
            Profitez de cours interactifs, apprenez la musique à votre rythme.
            Démarrez votre aventure musicale maintenant.
          </p>
        </div>
        <div
          class="mt-8 grid grid-cols-1 lg:grid-cols-2 lg:gap-16 space-y-9 lg:space-y-0 mx-auto p-10 lg:px-40 big_screen:px-0 px-3"
        >
          <div
            data-aos="slide-up"
            data-aos-delay="100"
            data-aos-duration="1000"
            class="flex justify-center lg:justify-end animated-content aos-init"
          >
            <div
              class="md:w-[80%] rounded-xl md:p-9 p-3 flex flex-col space-y-4 bg-[#FDF2E6]"
            >
              <img src="https://themusichall.fr/assets/edu1.png" alt="" />
              <h3
                class="!mt-[35px] font-bold text-black text-2xl md:text-3xl text-black md:leading-normal"
              >
                Trouvez votre instrument
              </h3>
              <p class="md:text-lg text-black !mb-[25px]">
                Parcourez tous les profils. Choisissez votre super prof selon ce
                que vous voulez (prix, qualifications, commentaires, évaluations).
              </p>
            </div>
          </div>
          <div
            data-aos="slide-up"
            data-aos-delay="250"
            data-aos-duration="1000"
            class="flex justify-center lg:justify-start animated-content aos-init"
          >
            <div
              class="md:w-[80%] rounded-xl md:p-9 p-3 flex flex-col space-y-4 bg-[#D7E9E8]"
            >
              <img src="https://themusichall.fr/assets/edu2.png" alt="" />
              <h3
                class="font-bold text-black text-2xl md:text-3xl text-black md:leading-normal"
              >
                Apprenez d’où vous voulez
              </h3>
              <p class="md:text-lg text-black">
                Tout ce dont vous avez besoin c’est un ordinateur, une tablette ou
                même juste de votre téléphone mobile et vous êtes prêt à commencer
                votre cours.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section
      class="py-[75px] lg:py-[140px] md:py-9 items-center relative bg-center bg-cover bg-no-repeat h-[350px]"
    >
      <img
        src="https://themusichall.fr/assets/bg_start.png"
        class="w-full h-[300px] md:h-auto aos-init aos-animate"
        alt=""
        data-aos="slide-up"
        data-aos-delay="200"
        data-aos-duration="1200"
      />
      <div
        data-aos="fade-in"
        data-aos-duration="1500"
        data-aos-delay="1100"
        style=""
        class="mt-5 md:-mt-[42%] lg:mt-9 big_screen:mt-0 m-auto absolute inset-1 lg:inset-40 xl:inset-[250px] big_screen:inset-[300px] my-auto lg:px-8 space-y-6 text-center flex-col items-center aos-init aos-animate"
      >
      <!-- -mt-[65%] -->
        <h3
          class="mt-5 text-center font-bold text-white xs:text-2xl md:text-3xl xl:text-4xl big_screen:text-6xl mb-3 md:leading-normal"
        >
          Commencez à jouer
        </h3>
        <p
          class="md:text-lg xl:text-3xl big_screen:text-4xl text-white big_screen:leading-normal"
        >
          Accédez instantanément à votre cours et avancez <br />
          à apprendre à votre propre rythme
        </p>
      <router-link class="shadow big_screen:text-2xl mt-3 inline-flex mb-10 text-white hover:bg-black bg-[#58BBDE] border-0 px-8 py-3 font-bold focus:outline-none hover:bg-[#58BBDE] rounded text-md xl:text-xl transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300" to="course">Trouvez votre cours</router-link>
      </div>
    </section>
  
    <section
      class="md:-mt-10 bg-top bg-white lg:h-[230px] xl:h-[400px] big_screen:h-[650px] rounded-t-[200%]"
    ></section>
  
    <section class="bg-white py-[10%] flex justify-center big_screen:w-full">
      <div class="flex flex-col lg:px-40 lg:space-y-10 big_screen:space-y-24 big_screen:px-0 px-3 big_screen:w-full">
        <div
          data-aos="fade-in"
          class="lg:pt-10 px-2 lg:px-8 space-y-4 lg:space-y-8 text-center flex-col items-center aos-init"
        >
          <h3
            class="mt-5 text-center font-semibold text-black xs:text-xl md:text-5xl big_screen:text-5xl mb-3 md:leading-normal"
          >
            Découvrez nos masterclass.
          </h3>
          <p class="md:text-2xl big_screen:text-2xl text-black">
            Retrouvez nos meilleurs professeurs dans une collection de cours
            spéciaux <br />
            et à durée limités, ne les manquez surtout pas !
          </p>
        </div>
        <div
          class="mt-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 big_screen:gap-16 gap-3 md:gap-0 big_screen:px-[10%]"
        >
          <a
            v-for="(item, index) in storeCategorie.courseCategorie.slice(0, 6)"
            :key="index"
            :href="'/course?c='+item.name"
            data-aos="fade-in"
            data-aos-delay="0"
            data-aos-duration="900"
            class="flex flex-col lg:space-y-5 items-center aos-init"
          >
            <div
              class="avatar transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-110 duration-300"
            >
              <div class="w-16 md:w-32 big_screen:w-48">
                <div
                  class="bg-gradient-to-t from-[#0054C7] to-[#50AAC8] p-3 md:p-7 big_screen:p-12 rounded-full"
                >
                  <img
                    :src="item.image"
                    alt=""
                    class="w-30 lg:p-4 big_screen:p-8"
                  />
                </div>
              </div>
            </div>
            <span
              class="mt-3 text-center font-bold text-black x:text-xl md:text-3xl big_screen:text-4xl mb-3 md:leading-normal"
            >
              {{ item.name }}
          </span>
          </a>
        </div>
      </div>
    </section>
  
    <section class="md:pt-9">
      <div
        class="pb-[75px] lg:pb-[140px] md:mt-10 bg-top bg-center bg-cover h-100 pt-10 pb-[5%] lg:px-8 space-y-9 text-center flex-col items-center"
        style="
          background-image: url('https://themusichall.fr/assets/bg-player.svg');
        "
      >
        <div
          data-aos="fade-in"
          class="w-full flex items-center justify-center lg:-mt-9 aos-init"
        >
          <img
            src="https://themusichall.fr/assets/player.svg"
            alt=""
            class="w-[40%]"
          />
        </div>
        <h3
          data-aos="slide-up"
          class="mt-5 text-center font-bold text-white text-2xl md:text-4xl mb-3 md:leading-normal aos-init"
        >
          Vous êtes un(e) Maître de votre instrument ?
        </h3>
        <p data-aos="slide-up" class="md:text-2xl text-white aos-init">
          Rejoignez THE MUSIC HALL <br />
          et partagez votre passion et votre talent pour la musique <br />
          avec des milliers d'élèves dans le monde entier.
        </p>
        <a
          v-if="storeAuth.isLogin() !== true && storeAuth.user === null"
          href="/register"
          class="mt-3 inline-flex mb-10 text-white px-8 py-3 font-bold focus:outline-none bg-[#58BBDE] rounded text-md shadow transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-103 duration-300 aos-init"
          data-aos="slide-up"
        >
          Inscrivez-vous dès aujourd'hui
        </a>
      </div>
    </section>
  </div>
</template>
<script>

  import dashboard from "@/assets/json/dashboard.json";
  import "vue3-carousel/dist/carousel.css";
  import AOS from "aos";
  import "aos/dist/aos.css";
  import { useAuthStore } from '@stores/auth'
  import { useProfStore } from '@stores/profs'
  import { useCategorieStore } from '@stores/categorie'
  export default {
    components: {
    },
    data() {
      return {
        storeAuth: useAuthStore(),
        storeProf: useProfStore(),
        storeCategorie: useCategorieStore(),
        Category: ["Category", "Angular", "Node Js", "React", "Python"],
        dashboard: dashboard,
        inputValue: "",
        selectedCategory: ""
      };
    },
    setup() {
      return {
        // carousel settings
        settings: {
          itemsToShow: 1,
          snapAlign: "center",
        },

        breakpoints: {
          // 700px and up
          700: {
            itemsToShow: 3.5,
            snapAlign: "center",
          },
          // 1024 and up
          1024: {
            itemsToShow: 4,
            snapAlign: "start",
          },
        },
      };
    },
    methods: {
      submitForm() {
        this.$router.push("/course-list");
      },
      generateLink() {
        const query = `q=${encodeURIComponent(this.inputValue)}&c=${encodeURIComponent(this.selectedCategory)}`;
        return `/course?${query}`;
      },
      redirectToLink() {
        const searchLink = document.getElementById('search_link');
        if (searchLink) {
          window.location.href = searchLink.href;
        } 
      }
    },
    async mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
      await this.storeProf.topProf()
      await this.storeCategorie.courseCategories()
    },
  };

</script>
