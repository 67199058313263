  <template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      
      <layoutsHome v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layoutsHome>
      <layoutsindex ></layoutsindex>
      
      <coursegridbreadcrumb></coursegridbreadcrumb>
      <!-- Course -->
      <section class="course-content">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <!-- <coursefilter></coursefilter> -->
              <div class="showing-list">
                <div v-if="storeCourses.meta !== null" class="row">
                  <div class="col-lg-6">
                    <div class="d-flex align-items-center">
                      <div class="view-icons">
                        <router-link
                          :class="currentPath == 'course-grid' ? 'active' : 'notactive'"
                          to="course-grid"
                          class="grid-view"
                          ><i class="feather-grid"></i
                        ></router-link>
                        <router-link
                          :class="currentPath == 'course' ? 'active' : 'notactive'"
                          to="course"
                          class="list-view"
                          ><i class="feather-list"></i
                        ></router-link>
                      </div>
                      <div class="show-result">
                        <h4>Affichage de {{ filteredCourses.length }} resultat{{ filteredCourses.length >1 ? 's':'' }}</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="show-filter add-course-info">
                      <form action="#">
                        <div class="row gx-2 align-items-center">
                          <div class="col-md-12 col-item">
                            <div class="search-group">
                              <i class="feather-search"></i>
                              <input
                                type="text"
                                v-model="filter.title"
                                name="title"
                                class="form-control"
                                placeholder="Rechercher un cours"
                              />
                            </div>
                          </div>
                          
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="storeCourses.listCourses.length > 0">
                <!-- <div v-for="(item, index) in storeCourses.listCourses" :key="index" class="col-lg-12 col-md-12 d-flex"> -->
                <div v-if="filteredCourses.length >0">
                  <div v-for="(item, index) in filteredCourses" :key="index" class="col-lg-12 col-md-12 d-flex">
                  <div class="course-box course-design list-course d-flex">
                    <div class="product">
                      <div class="product-img">
                        <router-link :to="{ name: 'course-details', params: { slug: item.slug } }">
                          <img
                            class="img-fluid"
                            style="width: 100% ; height: 100%"
                            alt=""
                            :src="item.image !== null ? item.image : '@/assets/img/course/course-10.jpg'"
                          />
                        </router-link>
                        <div class="price">
                          <h3 v-if="item.price !== 0">{{ item.price }} <span v-if="item.old_price !==' €' && item.old_price !==null">{{ item.old_price }}</span></h3>
                          <h3 v-else class="free-color">GRATUIT</h3>
                        </div>
                      </div>
                      <div class="product-content">
                        <div class="head-course-title">
                          <h3 class="title">
                            <router-link :to="{ name: 'course-details', params: { slug: item.slug } }"
                              >{{ item.title }}</router-link
                            >
                          </h3>
                        </div>
                        <div
                          class="course-info border-bottom-0 pb-0 d-flex align-items-center"
                        >
                          <div class="rating-img d-flex align-items-center">
                            <img src="@/assets/img/icon/icon-01.svg" alt="" />
                            <p>{{ item.total_lessons }}+ Leçon(s)</p>
                          </div>
                          <div class="course-view d-flex align-items-center">
                            <img src="@/assets/img/icon/icon-02.svg" alt="" />
                            <p>{{ item.duration }}</p>
                          </div>
                        </div>
                        <div :class="[item.note > 0 ? 'rating ' : 'rating-img text-sm ', 'rating-img my-4 flex items-center']">
                          <template v-if="item.note >0">
                            <i v-for="index in 5" :key="index" :class="{ 'fas fa-star filled me-1': index <= item.note, 'fas fa-star me-1': index > item.note }"></i>
                            <span class="d-inline-block average-rating">{{ item.note }}</span>
                          </template>
                          <template v-else>
                            <span class="p-1 bg-gray-100">
                              Pas d'avis
                            </span>
                          </template>
                        </div>
                        <div class="course-group d-flex mb-0">
                          <div class="course-group-img d-flex align-items-center">
                            <router-link :to="'/instructor-profile/'+item.prof.pseudo"
                              ><img
                                :src="item.prof.photo"
                                alt=""
                                class="img-fluid"
                            /></router-link>
                            <div class="course-name">
                              <h4>
                                <router-link :to="'/instructor-profile/'+item.prof.pseudo">{{ item.prof.first_name }} {{ item.prof.last_name.substring(0,1) }}</router-link>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div class="all-btn all-category d-flex align-items-center">
                        <button @click="addCartCourses(item)" style="min-width: 200px; !important" class="btn btn-primary " :id="item.id">
                          {{ storeAuth.isLogin() === true && storeAuth.user !== null ? item.is_paid === true ? "Acceder au cours" : "Ajouter au panier" : "Ajouter au panier" }}
                        </button>

                        <a  href="/cart" style="min-width: 200px; !important" class="btn btn-primary !hidden" :id="item.id">
                          Voir le panier
                        </a>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                <div v-else class="d-flex justify-content-center">
                  Aucun cours trouvé
                </div>  
              </div>

              <div @click="storeCourses.loading === true ? page : page++" v-if="storeCourses.empty !== true" class="load-more text-center">
                <div v-if="storeCourses.loading === true" class="spinner-border" role="status">
                  <span class="sr-only">Chargement...</span>
                </div>
                <span class="btn btn-primary" v-else>
                  Voir plus
                </span>
              </div>

              <!-- <pagination></pagination> -->
            </div>
            <!-- <gridlistsidebar></gridlistsidebar> -->
            <div class="col-lg-3 theiaStickySidebar">
              <div class="stickysidebar">
                <div class="filter-clear">
                  <div class="clear-filter d-flex align-items-center">
                    <h4><i class="feather-filter"></i>Filtrer les cours par : </h4>
                    <div class="clear-text">
                      <!-- <p>CLEAR</p> -->
                    </div>
                  </div>

                  <!-- Search Filter -->
                  <div class="card search-filter">
                    <div class="card-body">
                      <div class="filter-widget mb-0">
                        <div class="categories-head d-flex align-items-center">
                          <h4>Catégories</h4>
                          
                        </div>
                        <div v-for="(item, index) in storeCategorie.courseCategorie" :key="index">
                          <label class="custom_check" v-if="item.courses >0" >
                            <input
                              v-model="filter.category"
                              type="checkbox"
                              name="course_categorie"
                              :value="item.name"
                            />
                            <span class="checkmark"></span> {{ item.name }} ({{ item.courses }})
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Search Filter -->

                  <!-- Search Filter -->
                  <div class="card search-filter">
                    <div class="card-body">
                      <div class="filter-widget mb-0">
                        <div class="categories-head d-flex align-items-center">
                          <h4>Professeurs</h4>
                          
                        </div>
                        <div v-for="(item, index) in storeProf.listProfs.filter(item => item.total_courses > 0)" :key="index">
                          <label class="custom_check" >
                            <input
                              v-model="filter.instructor"
                              type="checkbox"
                              name="course_prof"
                              :value="item.pseudo"
                            />
                            <span class="checkmark"></span> {{ item.first_name }} {{ item.last_name }} ({{ item.total_courses }})
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Search Filter -->

                  <!-- Search Filter -->
                  <div class="card search-filter">
                    <div class="card-body">
                      <div class="filter-widget mb-0">
                        <div class="categories-head d-flex align-items-center">
                          <h4>Prix</h4>
                          
                        </div>
                        <div>
                          <label class="custom_check custom_one">
                            <input
                              v-model="filter.price"
                              type="radio"
                              name="prix"
                              value="tout"
                            />
                            <span class="checkmark"></span> Tout 
                          </label>
                        </div>
                        <div>
                          <label class="custom_check custom_one">
                            <input
                              v-model="filter.price"
                              type="radio"
                              name="prix"
                              value="gratuit"
                            />
                            <span class="checkmark"></span> Gratuit 
                          </label>

                        </div>
                        <div>
                          <label class="custom_check custom_one mb-0">
                            <input
                              v-model="filter.price"
                              type="radio"
                              name="prix"
                              value="payant"
                            />
                            <span class="checkmark"></span> Payant
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /Course -->

      <layouts1></layouts1>
    </div>
    <!-- /Main Wrapper -->
  </template>

<script>
import { useCoursesStore } from '@stores/courses'
import { useAuthStore } from '@stores/auth'
import { useCartStore } from '@stores/cart'
import { useCategorieStore } from '@stores/categorie'
import { useProfStore } from '@stores/profs'
import { router } from '@/router';
export default {
  data() {
    return {
      storeCourses: useCoursesStore(),
      storeAuth: useAuthStore(),
      storeCart: useCartStore(),
      storeCategorie: useCategorieStore(),
      storeProf: useProfStore(),
      page: 1,
      filter: {
        title: '',
        category: [],
        instructor: [],
        price: 'tout', // 'tout', 'gratuit', 'payant'
      },
    };
  },
  watch: {
    async page(newValue) {
      if (this.storeAuth.isLogin() === true) {
        await this.storeCourses.listAuth(newValue);
      } else {
        await this.storeCourses.list(newValue);
      }
    },
  },
  async mounted() {
    await this.storeCategorie.courseCategories();
    await this.storeProf.list();
    
    // Récupérer les valeurs de l'URL et initialiser les valeurs de recherche
    const { q: title, c: category } = this.$route.query;
    this.filter.title = title || '';
    this.filter.category = category ? [category] : [];
    
    if (this.storeAuth.isLogin() === true) {
      await this.storeCourses.listAuth(this.page);
    } else {
      await this.storeCourses.list(this.page);
    }
  },

    methods: { 
      async addCartCourses(item) {
      if (this.storeAuth.isLogin() === true && this.storeAuth.user !== null) {
        if (item.is_paid === false) {
          await this.storeCart.addCart({ course_id: item.id });
          // Ajoute la classe !hidden au bouton "Voir le panier" et la retire du bouton "Ajouter au panier"
          document.getElementById(item.id).classList.add('!hidden');
          document.getElementById(item.id).nextElementSibling.classList.remove('!hidden');
        } else {
          router.push({ name: 'course-lesson', params: { slug: item.slug } });
        }
      } else {
        router.push("/login");
      }
    },
      async pay(id){
        if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
          await this.storeCourses.payCourses(
            {
              course_id: id,
            }
          )
        } else{
          router.push("/login")
        }  
      }
    },
    computed: {
    filteredCourses() {
      const { title, category, instructor, price } = this.filter;
      
      return this.storeCourses.listCourses.filter(course => {
        // Filtrer par titre
        const titleMatch = course.title.toLowerCase().includes(title.toLowerCase());

        const statusMatch = course.status === 'Publié';

        // Filtrer par catégorie
        const categoryMatch = category.length === 0 || category.includes(course.categorie);

        // Filtrer par enseignant
        const instructorMatch = instructor.length === 0 || instructor.includes(course.prof.pseudo);

        // Filtrer par prix
        const priceMatch =
          price === 'tout' ||
          (price === 'gratuit' && (!course.price || course.price === "0 €" || course.price === " €" )) ||
          (price === 'payant' && course.price !== null && course.price !== "0 €");

        return titleMatch && categoryMatch && instructorMatch && priceMatch && statusMatch;
      });
    },
  },
  };
  </script>