import { router } from '@/router';
import axios from '@axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useCoursesStore = defineStore('course', {
  state: () => ({ listCourses: [], listCoursesProfs: [], listCoursesStudents: [],
    listPaiementCourses: [], bestCoursesProfs: [], course: null, similars: [], loading: false, meta: null, empty: false }),
  actions: {
    list(page) {
      this.loading = true;
      axios.get(`/api/courses?page=${page}`).then((response) => {
        if (page === 1) {
          this.listCourses = response.data.data
          this.meta = response.data.meta
        } else {
          this.listCourses = [...this.listCourses, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    listAuth(page) {
      this.loading = true;
      axios.get(`/api/courses-auth?page=${page}`).then((response) => {
        if (page === 1) {
          this.listCourses = response.data.data
          this.meta = response.data.meta
        } else {
          this.listCourses = [...this.listCourses, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    listUserPaiement(page) {
        this.loading = true;
      axios.get(`/api/userPayments?page=${page}`).then((response) => {
        if (page === 1) {
          this.listPaiementCourses = response.data.data
        } else {
          this.listPaiementCourses = [...this.listPaiementCourses, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    listProf(page) {
      this.loading = true;
      axios.get(`/api/the-prof-courses`).then((response) => {
        if (page === 1) {
          this.listCoursesProfs = response.data.data
        } else {
          this.listCoursesProfs = [...this.listCoursesProfs, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    onlineProfCourses(id, page) {
      this.loading = true;
      axios.get(`/api/prof-online-courses/${id}`).then((response) => {
        if (page === 1) {
          this.listCoursesProfs = response.data.data
        } else {
          this.listCoursesProfs = [...this.listCoursesProfs, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    get(slug) {
      this.loading = true;
      axios.get(`/api/courses/${slug}`).then((response) => {
        this.course = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    getAuth(slug) {
      this.loading = true;
      axios.get(`/api/courses-auth/${slug}`).then((response) => {
        this.course = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    paidCoursesStudent(page) {
      this.loading = true;
      axios.get(`/api/paidCourses`).then((response) => {
        if (page === 1) {
          this.listCoursesStudents = response.data.data;
        } else {
          this.listCoursesStudents = [...this.listCoursesStudents, ...response.data.data];
        }

        if(response.data.data.length < 30) {
          this.empty = true;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    bestCoursesProf() {
      this.loading = true;
      axios.get(`/api/best-courses`).then((response) => {
        this.bestCoursesProfs = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    blogSimilaire(slug) {
      axios.get(`/api/articleSimilar/${slug}`).then((response) => {
        this.similars = response.data.data;
      }).catch(() => {
        console.error("Une erreur est survenue!")
      })
    },
    payCourses(body) {
      this.loading = true;
      axios.post(`/api/payCourse`, body).then((response) => {
        window.open(`${response.data.lien}`);
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        if(e.response){
          toast.error(e.response.data.message, {
            autoClose: 3000,
          });
        } else{
          toast.error("Ouups nous avons rencontre une erreur", {
            autoClose: 3000,
          });
        }
        console.error("Une erreur est survenue!")
      })
    },
    addCourses(body) {
      this.loading = true;
      axios.post('/api/courses', body).then(() => {
        this.loading = false;
        router.push("/instructor-course");
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    // uploadVideoToVimeo(){
    //   const body = {
    //     "upload" : {
    //       "approach" : "tus",
    //       "size" : this.video_file.size
    //     },
    //     "name" : this.video_file.name
    //   };
    // },
    updateCourses(body, id) {
      this.loading = true;
      axios.post(`/api/modifier-courses/${id}`, body).then(() => {
        this.loading = false;
        router.push("/instructor-dashboard");
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    publishCourses(id) {
      this.loading = true;
      axios.post(`/api/publish-courses/${id}`, {}).then(() => {
        const index = this.listCoursesProfs.findIndex(obj => obj.id === id);
        this.listCoursesProfs[index].status = 'Publié'
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    unPublishCourses(id) {
      this.loading = true;
      axios.post(`/api/unpublish-courses/${id}`, {}).then(() => {
        const index = this.listCoursesProfs.findIndex(obj => obj.id === id);
        this.listCoursesProfs[index].status = 'Brouillon'
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    deleteCourses(id) {
      this.loading = true;
      axios.delete(`/api/courses/${id}`).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    async checkUserLike(id){
      try {
        let response = await axios.get(`/api/userlikeCourse/${id}`)
        return response.data.message
      } catch (error) {
        this.loading = false;
        console.error("Une erreur est survenue!")
        return false;
      }
    },
    async liked(id){
      try {
        let response = await axios.put(`/api/likeCourse/${id}`)
        return response.data.message
      } catch (error) {
        this.loading = false;
        console.error("Une erreur est survenue!")
        return false;
      }
    }
  },
})