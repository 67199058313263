<template>
<!-- Blog pagination -->
<div class="row">
    <div class="col-md-12">
        <ul class="pagination lms-page">
            <li class="page-item prev">
                <a class="page-link" href="javascript:void(0);" tabindex="-1"><i class="fas fa-angle-left"></i></a>
            </li>
            <li class="page-item first-page active">
                <a class="page-link" href="javascript:void(0);">1</a>
            </li>
            <li class="page-item">
                <a class="page-link" href="javascript:void(0);">2</a>
            </li>
            <li class="page-item">
                <a class="page-link" href="javascript:void(0);">3</a>
            </li>
            <li class="page-item">
                <a class="page-link" href="javascript:void(0);">4</a>
            </li>
            <li class="page-item">
                <a class="page-link" href="javascript:void(0);">5</a>
            </li>
            <li class="page-item next">
                <a class="page-link" href="javascript:void(0);"><i class="fas fa-angle-right"></i></a>
            </li>
        </ul>
    </div>
</div>
<!-- /Blog pagination -->
</template>

<script>
import { useCoursesStore } from '@stores/courses'
export default {
    props: {
        total: {
            type: Number,
            default: 0
        }
    },
  data() {
    return {
      storeCourses: useCoursesStore(),
      page: 1,
    };
  },
  watch: {
    async page(newValue) {
      await this.storeCourses.list(newValue)
    }
  },
};
</script>