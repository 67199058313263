<template>
  <div class="error-pag flex items-center justify-center">
    <!-- Main Wrapper -->
    <!-- <div class="main-wrapper">
      <div class="error-box">
        <div class="error-logo">
          <router-link to="/">
            <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="error-box-img">
          <img src="@/assets/img/error-01.png" alt="" class="img-fluid" />
        </div>
        <h3 class="h2 mb-3">Oh non ! Erreur 404</h3>
        <p class="h4 font-weight-normal">
          Cette page que vous avez demandée n'a pas pu être trouvée. Que la force soit avec vous !
        </p>
        <router-link to="/" class="btn btn-primary">Retour à la page d'accueil.</router-link>
      </div>
    </div> -->
    <!-- /Main Wrapper -->

    <div class="pt-9 3xl:pt-[4%]">
      <div class="error-logo flex justify-center ">
          <router-link to="/">
            <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid " style="width: 70px;" alt="Logo" />
          </router-link>
        </div>
      <section class="container my-12 px-2 md:px-[10%] text-black">
        <div class="mx-auto px-3 min-[576px]:max-w-[540px] md:max-w-[720px] lg:max-w-[930px] xl:max-w-[1434px]">
          <div class="grid grid-cols-1 items-center">
            <div class="text-center justify-center flex flex-col space-y-9">
              <h1 class="font-extrabold mb-2 text-9xl leading-[72px] text-black">404</h1>
              <p class="font-bold text-[22px] leading-[28px] lg:text-header-3 mb-6"> Page introuvable </p>
              <p class="text-base text-center font-medium text-gray-500 mb-[72px]"> Désolé, nous n'avons pas trouvé la
                page que vous cherchez. </p><a class="flex items-center gap-x-3 justify-center" href="/"><svg
                  class="w-5 h-5 text-gray-900 rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3">
                  </path>
                </svg><span class="font-bold text-header-9 text-gray-900 transition-common ">Retourner à
                  l'accueil</span></a>
            </div>
          </div>
      </div>
    </section>
  </div>


</div></template>
<script>
import Vue from "vue";
export default {
  components: {},
  data() {
    return {};
  },

};
</script>
