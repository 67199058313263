<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructorborder></layoutsinstructorborder>

    <!-- <instructoredit v-if="storeProfs.prof !== null"></instructoredit> -->
    <div class="pt-9 big_screen:pt-[4%] " v-if="storeProfs.prof !== null">
      <div class="flex flex-col items-center">
        <section
          class="bg-white w-full flex flex-col space-y-0 justify-center items-center rounded-b-full lg:px-40 big_screen:px-0 big_screen:max-w-7xl"
        >
          <div class="flex flex-col space-y-3 big_screen:space-y-4 pt-0 xs:gap-24">
            <div class="flex w-full flex-col lg:flex-row space-y-5 lg:space-x-5 lg:space-y-0">
              <div class="w-full lg:w-[50%]">
                <div class="h-auto relative px-6 md:px-0 md:flex md:items-center md:justify-center">
                  <img
                    class=" hidden md:flex xs:rounded-[45px] lg:rounded-[20px] big_screen:rounded-[20px] md:inset-0 md:w-[50%] lg:w-full lg:h-[700px] xs:m-auto big_screen:h-[755px] grayscale blur"
                    :src="storeProfs.prof.photo"
                    alt=""
                    />
                  <img
                    class="mx-auto m-auto md:absolute xs:rounded-[100px] md:rounded-[15px] lg:rounded-[80px] big_screen:rounded-[80px] md:inset-0 lg:p-[10%]   lg:h-[700px] xs:m-auto big_screen:h-[755px]"
                    :src="storeProfs.prof.photo"
                    alt=""
                    />
                </div>
              </div>
              <div
                class="lg:w-[50%] flex flex-col space-y-3 md:space-y-4 big_screen:space-y-9 px-2 md:px-9"
              >
                <div
                  class="text-sm flex space-x-9 justify-center lg:justify-start overflow-visible"
                >
                <div class="flex flex-col space-y-2 items-center" v-for="(item, index) in storeProfs.prof.badges"
                  :key="index">
                    <div class="avatar">
                      <div
                        class="w-10 rounded-full transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        <div class="bg-black p-2">
                          <img
                            class="w-[24px]"
                            :src="item.icon"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <h4
                      class="xs:mt-5 text-center font-bold text-black text-sm md:text-xl mb-3 md:leading-normal"
                    >
                    {{ item.name }}
                    </h4>
                  </div>
                  <div class="flex flex-col space-y-2 items-center">
                    <div class="avatar">
                      <div
                        class="w-10 rounded-full transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-110 duration-300"
                      >
                        <div
                          class="bg-black p-2 justify-center items-center flex text-center"
                        >
                          <span class="text-white font-bold text-sm md:text-lg text-lg w-auto"
                            >{{
                          storeProfs.prof.total_etudiant
                        }}</span
                          >
                          <!-- <span class="text-white font-bold text-sm md:text-lg  w-auto">128</span> -->
                        </div>
                      </div>
                    </div>
                    <h4
                      class="xs:mt-5 text-center font-bold text-black text-sm md:text-xl mb-3 md:leading-normal"
                    >
                      Elèves
                    </h4>
                  </div>
                  
                </div>
                <h4
                  class="flex flex-col space-y-3 md:space-y-5 font-extrabold text-black text-3xl md:text-4xl lg:text-5xl mb-3   md:text-left hidden lg:flex leading-10"
                >
                {{ storeProfs.prof.first_name }}  {{ storeProfs.prof.last_name }} "{{ storeProfs.prof.pseudo }}"
                </h4>
                <h4
                  class="flex flex-col space-y-3 md:space-y-5 font-extrabold text-black text-3xl md:text-4xl lg:text-5xl mb-3   text-center  lg:hidden leading-10"
                >
                {{ storeProfs.prof.first_name }}  {{ storeProfs.prof.last_name }} "{{ storeProfs.prof.pseudo }}"
                </h4>
                <div
                  class="xs:hidden lg:flex flex lg:justify-start justify-center space-x-2 items-center"
                >
                  <span v-if="storeProfs.prof.notes>0" class="mt-1 font-extrabold text-black md:text-xl"
                    >{{ storeProfs.prof.notes }} / 5 </span
                  ><span class="flex space-x-2"
                    ><i v-for="items in  storeProfs.prof.notes" :key="items" class="fa fa-star text-yellow-500 text-2xl"></i
                  ></span>
                </div>
                 
                <p class="xs:hidden lg:flex text-justify text-black big_screen:text-xl" v-html="storeProfs.prof.description">
                </p>
                <div 
                  class=" grid grid-cols-1 md:grid-cols-2 md:gap-3"
                >
                  <button  
                        @click="contactProf"
                    class="hover:bg-transparent border-2 border-black hover:text-black mt-5 bg-black rounded px-14 py-2 big_screen:py-5 big_screen:px-16 text-center font-bold text-white md:text-lg mb-3 md:leading-normal"
                  >
                    <i class="fa fa-comment"></i> Contacter
                  </button> 
                  <span class="w-full flex items-end">
                    <a
                      href="#prof-courses"
                      class="w-full bg-transparent border-2 border-black text-black xs:mt-5 hover:bg-black rounded px-14 py-2 big_screen:py-5 big_screen:px-16 text-center font-bold hover:text-white md:text-lg mb-3 md:leading-normal"
                      >Ses cours</a
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="4xl:mt-[8%] xl:mt-[5%] md:h-[150px] h-[150px]"></div>
      <section
        class="md:mt-9 py-9 bg-black flex flex-col justify-center items-center pb-[75px] lg:pb-[140px]"
      >
        <div
          class="w-full flex flex-col items-center px-9 md:px-[10%] space-y-5 pt-0"
        >
          <img v-if="storeProfs.prof.banner!=null"
            class="-mt-[35%] md:-mt-[15%] rounded-3xl md:w-[80%] md:h-[600px]"
            :src="storeProfs.prof.banner"
            alt=""
          />
          <div class="py-[25px] lg:py-[40px] flex flex-col space-y-14" id="prof-courses" v-if="storeCourses.listCoursesProfs.length > 0">
            <h4
              class="mt-5 text-center font-bold text-white text-2xl md:text-5xl big_screen:text-6xl mb-3 md:leading-normal"
            >
              Ses cours en ligne
            </h4>
            <div class="grid grid-cols-1 lg:grid-cols-3 xs:gap-6 lg:gap-12 big_screen:gap-16 big_screen:max-w-7xl lg:px-40 big_screen:px-0">
              <a :href="'/course-details/'+item.slug" class="" v-for="(item, index) in storeCourses.listCoursesProfs"
                  :key="index">
                <div class="relative cursor-pointer space-y-3" >
                  <img
                    :src="item.image"
                    alt=""
                    class="h-[200px] lg:h-[350px] rounded-3xl opacity-50"
                  />
                  <div
                    class="absolute inset-0 m-auto flex flex-col justify-center items-center text-3xl"
                  >
                    <span class="flex space-x-2"
                      ><i class="fa fa-star text-white"></i
                      ><i class="fa fa-star text-white"></i
                      ><i class="fa fa-star text-white"></i
                      ><i class="fa fa-star text-white"></i
                      ><i class="fa fa-star text-white"></i
                    ></span>
                  </div>
                  <h4
                    class="xs:mt-6 text-center font-semibold text-white text-md md:text-2xl mb-3 md:leading-normal"
                  >
                   {{ item.title }}
                  </h4>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section
        class="py-[35px] lg:py-[60px] md:mt-9 bg-white flex flex-col justify-center items-center"
      >
        <div
          class="w-full flex flex-col items-center xs:px-9 space-y-20 pt-0 big_screen:max-w-7xl xs:px-5 lg:px-40 big_screen:px-0"
        >
          <div class="flex space-x-3 items-center">
            <span v-if="storeProfs.prof.notes>0" class="mt-1 font-extrabold text-black md:text-xl mb-"
              >{{ storeProfs.prof.notes }} / 5 </span
            ><span class="flex space-x-2"
              ><i v-for="items in  storeProfs.prof.notes" :key="items" class="fa fa-star text-yellow-500 text-2xl"></i
            ></span>
          </div>
          <div>
            <h4
              class="mt-9 text-center font-semibold text-black text-2xl md:text-4xl big_screen:text-5xl mb-3 md:leading-normal"
            >
              Professeurs similaires
            </h4>
            <div
              class="mt-9 flex flex-col md:flex-row items-center space-y-5 md:space-y-0 md:space-x-5 px-3 md:px-0"
              style="cursor: pointer"
            >
            <a :href="'/instructor-profile/'+item.pseudo"  v-for="(item, index) in storeProfs.listProfs.slice(0,3)" :key="index">
              <img
                :src="item.photo"
                :alt="item.pseudo"
                class="m-auto h-full rounded-3xl transition ease-in-out delay-250 hover:-translate-y-1 hover:scale-104 duration-300"
              />
            </a>
             
            </div>
          </div>
        </div>
      </section>
    </div>
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { useProfStore } from "@stores/profs";
import { useCoursesStore } from "@stores/courses";
import { useMessagesStore } from "@stores/messages";
import { useNoteStore } from "@stores/notes";
import { useAuthStore } from "@stores/auth";
import { router } from "@/router";
export default {
  data() {
    return {
      storeCourses: useCoursesStore(),
      storeProfs: useProfStore(),
      storeMessage: useMessagesStore(),
      storeNotes: useNoteStore(),
      storeAuth: useAuthStore(),
      commentaire: "",
      isComment: false,
      currentRating: 0,
      maxRating: 5,
      page: 1,
    };
  },
  computed: {
    stars: function () {
      return Array.from({ length: this.maxRating }, (_, index) => index + 1);
    },
    getRating: {
      get() {
        return this.currentRating;
      },
      set(value) {
        this.currentRating = value;
      },
    },
  },
  watch: {
    // async page(newValue) { 
    //   await this.storeCourses.listProf(this.storeProfs.prof.id, newValue);
    // },
  },
  async mounted() {
    await this.storeNotes.listNoteProf(router.currentRoute.value.params.pseudo);
    this.storeProfs.$subscribe(async (mutation, state) => {
      // import { MutationType } from 'pinia'
      mutation.type // 'direct' | 'patch object' | 'patch function'
      // same as cartStore.$id
      mutation.storeId // 'cart'
      // only available with mutation.type === 'patch object'
      mutation.payload
      if(state.prof !== null){
        await this.storeCourses.onlineProfCourses(state.prof.id, this.page);
      }
      // persist the whole state to the local storage whenever it changes
      // localStorage.setItem('cart', JSON.stringify(state))
    })
    await this.storeProfs.list(1)
  },

  async beforeMount() {
    await this.storeProfs.get(router.currentRoute.value.params.pseudo);
  },

  methods: { 
    async similar(pseudo) {
      await router.push({ name: "instructor-profile", params: { pseudo: pseudo } });
      router.go();
    },
    async contactProf() {
      if (this.storeAuth.isLogin() === true && this.storeAuth.user !== null) {
        await this.storeMessage.startMessage({
          receiver_id: this.storeProfs.prof.id,
        });
      } else {
        router.push("/login");
      }
    },
    async pay(id) {
      if (this.storeAuth.isLogin() === true && this.storeAuth.user !== null) {
        await this.storeCourses.payCourses({
          course_id: id,
        });
      } else {
        router.push("/login");
      }
    },
  },
};
</script>