<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <!--Dashbord Student -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>

          <!-- Profil Details -->
          <div class="col-xl-9 col-md-8">
            <div style="padding: 20px;" class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3>Détails du profil</h3>
                  <p>
              *Redimensionnez vos images <a class="text-[#58bbde] font-bold text-underline" href="https://www.iloveimg.com/crop-image" target="_blank"><u>ici</u></a> 
            </p>
                  <!-- <p>Vous avez un contrôle total pour gérer les paramètres de votre compte.</p> -->
                </div>
                <div class="course-group mb-0 d-flex">
                  <div class="course-group-img d-flex align-items-center space-x-5">
                    <label for="formFileLg" :class="input_photo ? 'bg-gray-100' : ''" class="custom-file-upload p-2 py- flex items-center justify-center rounded border-2 border-dashed input-bordered cursor-pointer hover:!cursor-pointer hover:bg-gray-100">
                      <span>{{ input_photo ? input_photo.name : 'Sélectionnez une image' }}</span>
                      <input id="formFileLg" @change="handleFileChange" accept="image/*" class="form-control form-control-lg" type="file" style="display:none;">
                    </label>
                    <router-link to="#"
                      ><img :src="imageUrl === null ? storeAuth.user.photo !== null ? storeAuth.user.photo : '@/assets/img/user/user11.jpg' : imageUrl" alt="" class="img-fluid" style="width: 70px !important; height: auto !important;"
                    /></router-link>
                    <div class="course-name">
                      <h4><router-link to="#">Photo de profil</router-link></h4>
                      <p>PNG ou JPG (taille recommandée 320 x 437 px)</p>
                    </div>
                  </div>
                  <div
                    v-if="photoUrl !== null && photoUrl !== ''"
                    class="profile-share d-flex align-items-center justify-content-center"
                  >
                    <button @click="updatedPhotoUser" class="btn btn-success">
                      <div v-if="storeAuth.loadingPhoto === true" class="spinner-border" role="status">
                        <span class="sr-only">Chargement...</span>
                      </div>
                      <span v-else>
                        Modifier
                      </span>
                    </button>
                  </div>
                </div>
                <div class="checkout-form personal-address add-course-info">
                  <div class="personal-info-head">
                    <h4>Détails personnels</h4>
                    <p>Modifiez vos informations personnelles et votre adresse.</p>
                  </div>
                  <div v-if="update === false" class="contact-info-list row">
                    <div class="col-lg-6">
                      <div class="edu-wrap">
                      <div class="edu-name">	
                        <span><i class="fas fa-user"></i></span>
                      </div>
                      <div class="edu-detail">	
                        <h6>Nom</h6>
                        <p>{{ getFirstName }}</p>
                      </div>
                    </div>
                    </div>
                    <div class="mt-4 col-lg-6">
                      <div class="edu-wrap">
                      <div class="edu-name">	
                        <span><i class="fas fa-user"></i></span>
                      </div>
                      <div class="edu-detail">	
                        <h6>Prénom</h6>
                        <p>{{ getLastName }}</p>
                      </div>
                    </div>
                    </div>
                    <div class="mt -4 col-lg-6">
                      <div class="edu-wrap">
                      <div class="edu-name">	
                        <span><i class="fas fa-user"></i></span>
                      </div>
                      <div class="edu-detail">	
                        <h6>Pseudo</h6>
                        <p>{{ getPseudo }}</p>
                      </div>
                    </div>
                    </div>
                    <div class="mt-4 col-lg-6">
                      <div class="edu-wrap">
                      <div class="edu-name">	
                        <span><i class="fas fa-envelope"></i></span>
                      </div>
                      <div class="edu-detail">	
                        <h6>Email</h6>
                        <p>{{ storeAuth.user.email }}</p>
                      </div>
                    </div>
                    </div>
                    <div class="mt-4 col-lg-12">
                      <div class="edu-wrap">
                        <div class="edu-name">	
                          <span><i class="fas fa-book"></i></span>
                        </div>
                        <div class="edu-detail" style="width: 100%;">	
                          <h6>Biographie</h6>
                          <textarea
                            rows="4"
                            :readonly="true"
                            v-model="storeAuth.user.description"
                            required
                            class="form-control"
                            placeholder="Biographie"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <!-- <button @click="update = true" style="width: 30%; margin-top: 20px;" type="button" class="btn btn-primary">
                      Modifier
                    </button> -->
                  </div>
                  <form v-else action="#">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">Nom</label>
                          <input
                            v-model="getFirstName"
                            type="text"
                            class="form-control"
                            placeholder="Nom"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">Prénom</label>
                          <input
                            v-model="getLastName"
                            type="text"
                            class="form-control"
                            placeholder="Prénom"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">Pseudo</label>
                          <input
                            v-model="getPseudo"
                            type="text"
                            class="form-control"
                            disabled
                            placeholder="Pseudo"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">Banniere (1450 * 686 px)</label>
                          <label for="formFileLgBanner" :class="input_banner ? 'bg-gray-100' : ''" class="custom-file-upload p-2 py- flex items-center justify-center rounded border-2 border-dashed input-bordered cursor-pointer hover:!cursor-pointer hover:bg-gray-100">
                            <span>{{ input_banner ? input_banner.name : 'Sélectionnez une image' }}</span>
                            <input id="formFileLgBanner" @change="handleFileChangeBanner" accept="image/*" class="form-control form-control-lg" type="file" style="display:none;">
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="add-course-label">Categories</label>
                          <vue-select :settings="{ multiple: true }" :options="getCategories" v-model="storeAuth.prof.categories" />
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <label class="add-course-label">Biographie</label>
                        <textarea
                          rows="6"
                          v-model="storeAuth.prof.description"
                          required
                          class="form-control"
                          placeholder="Biographie"
                        ></textarea>
                      </div>
                      <div v-if="bannerUrl !== null" :style="{backgroundImage: 'url(' + bannerUrl + ')'}" class="container-fluid custom-container">
                        <!-- Ajoutez votre contenu supplémentaire ici -->
                      </div>
                      <div class="update-profile">
                        <button @click="updatedProfilUser" type="button" style="margin-right: 30px;" class="btn btn-primary">
                          <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                            <span class="sr-only">Chargement...</span>
                          </div>
                          <span v-else>
                            Mettre à jour
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <hr>
                <div class="profile-details">
                    <div class="settings-menu p-0">
                      <div class="profile-heading">
                        <h3>Securité</h3>
                      </div>
                      <div class="checkout-form personal-address">
                        <div class="personal-info-head">
                          <h4>Changer le mot de passe</h4>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <form method="post" @submit.prevent="updatedPassword">
                              <div class="form-group">
                                <label class="form-control-label">Mot de passe actuel</label>
                                <input required v-model="old_password" type="password" class="form-control" />
                              </div>
                              <div class="form-group">
                                <label class="form-control-label">Nouveau mot de passe</label>
                                <div class="pass-group" ref="passwordGroup">
                                  <input
                                    ref="passwordInput"
                                    type="password"
                                    required
                                    class="form-control pass-input"
                                    placeholder=""
                                    :class="{ 'password-error': validationError }"
                                    v-model="password"
                                    @input="handlePasswordChange"
                                  />
                                </div>
                                <div
                                  id="passwordStrength"
                                  style="display: flex"
                                  :class="[
                                    'password-strength',
                                    strength === 'poor' ? 'poor-active' : '',
                                    strength === 'weak' ? 'avg-active' : '',
                                    strength === 'strong' ? 'strong-active' : '',
                                    strength === 'heavy' ? 'heavy-active' : '',
                                  ]"
                                >
                                  <span
                                    id="poor"
                                    class="active"
                                    :class="{ active: strength === 'poor' }"
                                  ></span>
                                  <span
                                    id="weak"
                                    class="active"
                                    :class="{ active: strength === 'weak' }"
                                  ></span>
                                  <span
                                    id="strong"
                                    class="active"
                                    :class="{ active: strength === 'strong' }"
                                  ></span>
                                  <span
                                    id="heavy"
                                    class="active"
                                    :class="{ active: strength === 'heavy' }"
                                  ></span>
                                </div>
                                <div id="passwordInfo">
                                  <span v-if="validationError === 1"></span>
                                  <span v-else-if="validationError === 2" style="color: red"
                                    >😠 Faible. Doit contenir au moins 8 caractères.</span
                                  >
                                  <span v-else-if="validationError === 3" style="color: orange"
                                    >😲 Moyen. Doit contenir au moins 1 lettre ou chiffre.</span
                                  >
                                  <span v-else-if="validationError === 4" style="color: blue"
                                    >🙂 Presque. Doit contenir un symbole spécial.</span
                                  >
                                  <span v-else-if="validationError === 5" style="color: green"
                                    >😊 Génial ! Vous avez un mot de passe sécurisé.</span
                                  >
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="form-control-label">Confirmez le nouveau mot de passe</label>
                                <input required v-model="new_password_confirmation" type="password" class="form-control" />
                              </div>
                              <div class="update-profile save-password">
                                <button type="submit" class="btn btn-primary">
                                  <div v-if="storeAuth.loadingPasse === true" class="spinner-border" role="status">
                                    <span class="sr-only">Chargement...</span>
                                  </div>
                                  <span v-else>
                                    Modifier mon mot de passe
                                  </span>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Profil Details -->
        </div>
      </div>
    </div>
    <!-- /Dashbord Student -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
import { useCategorieStore } from '@stores/categorie'
export default {
  components: {},
  data() {
    return {
      imageUrl: null,
      bannerUrl: null,
      storeAuth: useAuthStore(),
      storeCategorie: useCategorieStore(),
      prof: {
        first_name: '',
        last_name: '',
        pseudo: '',
      },
      update: true,
      photoUrl: null,
      input_photo: null,
      input_banner: null,
      banner: null,
      showPassword: true,
      password: "",
      old_password: "",
      new_password_confirmation: "",
      validationError: 0,
      isPasswordValid: false,
      strength: "",
    };
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    getUserInfo(){
      if(this.storeAuth.user !== null){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.getFirstName = this.storeAuth.user.first_name
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.getLastName = this.storeAuth.user.last_name
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.getPseudo = this.storeAuth.user.pseudo
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.storeAuth.prof.description = this.storeAuth.user.description
        let array = []
        console.log(this.storeAuth.user.categories)
        for (let index = 0; index < this.storeAuth.user.categories.length; index++) {
          const element = this.storeCategorie.courseCategorie.filter( item => item.name === this.storeAuth.user.categories[index].name)[0].id;
          array.push(element); 
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.storeAuth.prof.categories = array;
      }
    },
    getFirstName: {
      get(){
        return this.prof.first_name
      },
      set(value) {
        this.prof.first_name = value
      }
    },
    getLastName: {
      get(){
        return this.prof.last_name
      },
      set(value) {
        this.prof.last_name = value
      }
    },
    getPseudo: {
      get(){
        return this.prof.pseudo
      },
      set(value) {
        this.prof.pseudo = value
      }
    },
    getDescription: {
      get(){
        return this.prof.description
      },
      set(value) {
        this.prof.description = value
      }
    },
    getCategories(){
      let array = []
      if(this.storeCategorie.courseCategorie.length > 0){
        for (let index = 0; index < this.storeCategorie.courseCategorie.length; index++) {
          array.push({
            id: this.storeCategorie.courseCategorie[index].id,
            text: this.storeCategorie.courseCategorie[index].name
          })
          
        }
      }
      return array
    }
  },

  async mounted() {
    this.validatePassword(this.password);
    await this.storeCategorie.courseCategories()
    this.storeCategorie.$subscribe((mutation, state) => {
      // import { MutationType } from 'pinia'
      mutation.type // 'direct' | 'patch object' | 'patch function'
      // same as cartStore.$id
      mutation.storeId // 'cart'
      // only available with mutation.type === 'patch object'
      mutation.payload
      if(state.courseCategorie.length > 0){
        this.getUserInfo
      }
      // persist the whole state to the local storage whenever it changes
      // localStorage.setItem('cart', JSON.stringify(state))
    })
  },

  methods: {
    summernoteChange(newValue) {
      console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      console.log("summernoteImageLinkInsert()", args);
    },
    async updatedPassword() {
      await this.storeAuth.updatePassword({
        old_password: this.old_password,
        new_password: this.password,
        new_password_confirmation: this.new_password_confirmation
      })
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const maxWidth = 320; 
            const maxHeight = 437;

            if (img.width != maxWidth || img.height != maxHeight) {
              alert(`Veuillez sélectionner une image de dimensions ${maxWidth}x${maxHeight}px.`);
              event.target.value = '';
              this.input_photo = null;
              this.photoUrl = null;
              return;
            }else{
              this.input_photo = file;
              this.photoUrl = file;
              this.imageUrl = reader.result;
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handleFileChangeBanner(event) {
      const file = event.target.files[0];
      this.banner = file
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const maxWidth = 1450; 
            const maxHeight = 686;
            
            if (img.width != maxWidth || img.height != maxHeight) {
              alert(`Veuillez sélectionner une image de dimensions ${maxWidth}x${maxHeight}px.`);
              event.target.value = '';
              this.input_banner = null;
              return;
            }else{
              this.input_banner = file
              this.bannerUrl = reader.result;
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async updatedPhotoUser() {
      const data = new FormData();
      data.append("photo", this.photoUrl);
      await this.storeAuth.updatePhoto(data)
    },
    async updatedBanner() {
      const data = new FormData();
      data.append("banner", this.banner);
      await this.storeAuth.updateBanner(data)
    },
    clearFileInput() {
      var fileInput = document.getElementById('fileInput');

      // Create a new file input element
      var newFileInput = document.createElement('input');
      newFileInput.type = 'file';
      newFileInput.id = 'fileInput';

      // Replace the old file input with the new one
      fileInput.parentNode.replaceChild(newFileInput, fileInput);
    },
    clear() {
      this.update = true
      this.getFirstName = this.storeAuth.user.first_name
      this.getLastName = this.storeAuth.user.last_name
      this.getPseudo = this.storeAuth.user.pseudo
      this.getDescription = this.storeAuth.user.description
    },
    async updatedProfilUser() {

      if(this.banner !== null && this.banner !== ""){
        await this.updatedBanner()
      }

      const data = this.prof
      
      if (this.prof.first_name === '' || this.prof.first_name === this.storeAuth.user.first_name) {
        delete data.first_name
      }

      if (this.prof.last_name === '' || this.prof.last_name === this.storeAuth.user.last_name) {
        delete data.last_name
      }

      if (this.prof.pseudo === '' || this.prof.pseudo === this.storeAuth.user.pseudo) {
        delete data.pseudo
      }

      await this.storeAuth.updateProf();

      if(Object.keys(this.prof).length === 0){
        await this.storeAuth.updateProfil(this.prof,this.storeAuth.user.id)
      }
      
      // this.clear()
    },
    handlePasswordChange() {
      const newPassword = this.password;
      this.validatePassword(newPassword);
    },
    validatePassword(value) {
      this.validationError = 0;
      if (!value) {
        this.validationError = 0;
      } else if (value.length < 8) {
        this.validationError = 2;
      } else if (!/[a-zA-Z]/.test(value) && !/[0-9]/.test(value)) {
        this.validationError = 3;
      } else if (!/[!@#$%^&*()]/.test(value)) {
        this.validationError = 4;
      } else {
        this.validationError = 5;
      }
      this.strength = this.strengthColor(this.validationError);
    },
    strengthColor(count) {
      if (count < 1) return "";
      if (count < 2) return "poor";
      if (count < 3) return "weak";
      if (count < 4) return "strong";
      return "heavy";
    },
    hasNumber(value) {
      return /[0-9]/.test(value);
    },
    hasMixed(value) {
      return /[a-z]/.test(value) && /[A-Z]/.test(value);
    },
    hasSpecial(value) {
      return /[!#@$%^&*)(+=._-]/.test(value);
    },
    strengthIndicator(value) {
      let strengths = 0;

      if (value.length >= 8) strengths = 1;
      if (this.hasNumber(value) && value.length >= 8) strengths = 2;
      if (this.hasSpecial(value) && value.length >= 8 && this.hasNumber(value))
        strengths = 3;
      if (
        this.hasMixed(value) &&
        this.hasSpecial(value) &&
        value.length >= 8 &&
        this.hasNumber(value)
      )
        strengths = 3;
      return strengths;
    },
  },
};
</script>

<style>
  .custom-container {
    width: 100%;
    height: 40vh; /* 40% de la hauteur de la vue (viewport height) */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; /* Couleur du texte à l'intérieur du container */
  }
</style>