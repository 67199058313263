<template>
<!-- Breadcrumb -->
<div class="breadcrumb-bar">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="breadcrumb-list">
                    <nav aria-label="breadcrumb" class="page-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">Accueil</router-link></li>
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link to="/instructor-list">Nos professeurs</router-link>
                            </li>
                            <li class="breadcrumb-item" aria-current="page">{{ storeProfs.prof.first_name }} {{ storeProfs.prof.last_name }}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Breadcrumb -->

<!-- Breadcrumb -->
<!-- <div :style="{backgroundImage: 'url(' + storeProfs.prof.banner + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}" class="page-banner instructor-bg-blk"> -->
<div class="page-banner instructor-bg-blk bg-[#58BBDE]">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="profile-info-blk">
                    <router-link to="#" class="profile-info-img">
                        <img style="height: 100%; width: 100%;" :src="storeProfs.prof.photo" alt="" class="img-fluid">
                    </router-link>
                    <h4><router-link to="#">{{ storeProfs.prof.first_name }} {{ storeProfs.prof.last_name }}</router-link>
                        <!-- <span>Beginner</span> -->
                    </h4>
                    <p>Enseignant</p>
                    <ul class="list-unstyled inline-inline profile-info-social">
                        <li class="list-inline-item">
                            <a :href="storeProfs.prof.twitter" target="_blank"><i class="fa-brands fa-x me-1"></i></a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="storeProfs.prof.facebook" target="_blank"><i class="fa-brands fa-facebook me-1"></i></a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="storeProfs.prof.instagram" target="_blank"><i class="fa-brands fa-instagram me-1"></i></a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="storeProfs.prof.youtube" target="_blank"><i class="fa-brands fa-youtube me-1"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Breadcrumb -->	
</template>

<script>
  import { useProfStore } from '@stores/profs'
  import { router } from '@/router';
  export default {
    data() {
      return {
        storeProfs: useProfStore(),
      };
    },
    async mounted() {
      await this.storeProfs.get(router.currentRoute.value.params.pseudo)
    },

    methods: {
      
    },
  };
</script>