<template>
    <!-- Main Wrapper -->
    <div v-if="storeAuth.user !== null" class="main-wrapper">
		<div class="min-h-screen">
			<layoutsHome v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layoutsHome>
			<layoutsindex ></layoutsindex>
			<courseheader v-if="storeAuth.user !== null"></courseheader>
				<!-- Course Lesson -->
				<section class="page-content course-sec course-message">
					<div class="container">
						<div class="student-widget message-student-widget">
							<div class="student-widget-group"> 
								<div class="col-md-12">
									<div class="chat-window" v-if="storeMessage.users.length > 0">
									
										<!-- Chat Left -->
										<div class="chat-cont-left">
											<div class="chat-users-list">
												<div class="chat-scroll">
													<a style="cursor: pointer;" @click="getMessages(item)" v-for="(item, index) in storeMessage.users" :key="index" class="media d-flex place-items-center hover:!bg-[#58bbde] hover:!text-white " :class="item.first_name  == gUser.first_name  ? '!text-white !bg-[#58bbde]' : ''">
														<div class="media-img-wrap flex-shrink-0 ">
															<div class="avatar avatar-away">
																<img :src="item.photo" :alt="item.pseudo" class="avatar-img rounded-circle">
															</div>
														</div>
														<div class="media-body flex-grow-1 ">
															<div>
																<div class="font-bold ">{{ item.first_name }} {{ item.last_name }}  </div>
															</div>
														</div>
													</a> 
												</div>
											</div>
										</div>
										<!-- /Chat Left -->
									
										<!-- Chat Right -->
										<div class="chat-cont-right" v-if="gUser !== ''">
											<div class="chat-header">
												<a id="back_user_list" href="javascript:void(0)" class="back-user-list">
													<i class="material-icons">chevron_left</i>
												</a>
												<div v-if="gUser !== ''" class="media d-flex">
													<div class="media-img-wrap flex-shrink-0">
														<div class="avatar avatar-online">
															<img :src="gUser.photo" alt="User Image" class="avatar-img rounded-circle">
														</div>
													</div>
													<div class="media-body flex-grow-1">
														<div class="user-name">{{ gUser.first_name }} {{ gUser.last_name }}</div>
													</div>
												</div>
											</div>
											<div class="chat-body">
												<div class="chat-scroll">
													<ul class="list-unstyled">
														<li v-for="(item, index) in storeMessage.listMessages" :key="index" :class="item.sender !== storeAuth.user.pseudo ? 'media received d-flex' : 'media sent d-flex'">
															<div class="media-body flex-grow-1">
																<div class="msg-box">
																	<div class="msg-bg">
																		<p>{{ item.content }}</p>
	
																	</div> 
																	<ul class="chat-msg-info">
																		<li>
																			<div class="chat-time">
																				<span>{{ item.created_at }}</span>
																			</div>
																		</li>
																	</ul>
																</div>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="chat-footer">
												<div class="input-group"> 
													<input type="text" @keyup.enter="send" v-model="content" class="input-msg-send form-control" placeholder="Votre message...">
													<button v-if="user !== ''" @click="send" type="button" class="btn btn-primary msg-send-btn rounded-pill"><img src="@/assets/img/send-icon.svg" alt="" ></button>
												</div>
											</div>
										</div>
										<div class="chat-cont-right d-flex  justify-content-center align-items-center" v-else>
											Veuillez selectionner une discussion pour afficher son contenu
										</div>
										<!-- /Chat Right -->
									</div>	
									<div v-else class="d-flex align-items-center justify-content-center py-9">
										Vous n'avez aucun message 
									</div>			
								</div>													
							</div>													
						</div>	
					</div>
				</section>
		</div>
			<!-- /Course Lesson -->
			<input type="hidden" name="name" :value="user.id">
        
        <layouts1></layouts1>
    </div>
    <!-- /Main Wrapper -->
</template>

<script>
import { useMessagesStore } from '@stores/messages'
import { useAuthStore } from '@stores/auth'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
  components: {},
  data() {
    return {
		storeMessage: useMessagesStore(),
		storeAuth: useAuthStore(),
		content: '',
		gUser: '',
		pointer: 0,
		intervalId: null,
    };
  },

  computed: {
	user() {
    const { conv: userId } = this.$route.query;
	if(this.pointer !== 0){
		return this.gUser;
	}
    if (userId ) {
      const foundUser = this.storeMessage.users.find(the_user => the_user.id == userId);
      if (foundUser) {
        this.getMessages(foundUser);
        this.gUser = foundUser;
		console.log('iic');
      } 
    }
    return this.gUser;
  }
},

  async created() {
	// await this.storeMessage.getUser();
	// this.initUser();
},
async mounted() {
	await this.storeMessage.getUser();
  },
  unmounted() {
	// clearInterval(this.intervalId);
  },
  methods: { 
	async getMessages(user){
		this.pointer = 2;
		await this.storeMessage.getMessages(user.id);
		console.log('ici');
		this.gUser = user;
	},
	async send(){
		if (this.content !== '') {
			await this.storeMessage.sendMessage({
				content: this.content,
				receiver_id: this.user.id,
			})
			this.content = ''
		} else {
			toast.error("Veuillez saisir un message.", {
				autoClose: 3000,
			});
		}
			
	}
  },
};
</script>