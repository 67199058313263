<template>
    <!-- Footer Top -->
    <div class="hidden footer-top">
        <div class="container">
            <div class="row">


                <div class="col-lg-3 col-md-6">

                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">The Music hall</h2>
                        <ul>
                            <li><router-link to="/">Accueil</router-link></li>
                            <li><router-link to="/course">Catalogue de formation</router-link></li>
                            <li><router-link to="#">Notre appplication</router-link></li>
                            <li><router-link to="#">Mentions légales / Confidentialité </router-link></li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->

                </div>

                <div class="col-lg-3 col-md-6">

                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">Nos liens </h2>
                        <ul>
                            <li><router-link to="/blog-masonry">Blog/Actualité</router-link></li>
                            <li><router-link to="#">Toutes nos masterclass</router-link></li>
                            <li><router-link to="#">Devenir Super prof</router-link></li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->

                </div>

                <div class="col-lg-3 col-md-6">

                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">Les Ressources </h2>
                        <ul>
                            <li><router-link to="#">Mises à jour</router-link></li>
                            <li><router-link to="#">La communauté</router-link></li>
                            <li><router-link to="#">Nous contacter</router-link></li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->

                </div>


            </div>
        </div>
    </div>

<!-- /Footer Top --></template>