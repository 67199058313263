<template>
<!-- Login Banner -->
<div class="col-md-6 login-bg">
    <div class="owl-carousel login-slide owl-theme">
        <div class="welcome-login">
            <div class="login-banner">
                <!-- <img src="@/assets/img/login-img.png" class="img-fluid" alt="Logo"> -->
                <img src="https://themusichall.fr/assets/hero.png" class="img-fluid " alt="Logo">
            </div>
            <!-- <div class="mentor-course text-center">
                <h2> Bienvenue sur <br> The Music Hall</h2>
            </div> -->
        </div>
    </div>
</div>
<!-- /Login Banner -->
</template>
<script>
    import { defineComponent } from 'vue'
    import 'vue3-carousel/dist/carousel.css';
    
    export default defineComponent({
    name: 'Autoplay',
    components: {
    },
});
</script>