<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
		<div class="min-h-screen">
			<layoutsHome v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layoutsHome>
			<layoutsindex ></layoutsindex>
			<notificationsbreadcrumb></notificationsbreadcrumb>
			<!-- Notification -->
			<section class="course-content">
				<div class="container">
					
					<div class="title-sec">
						<div class="row">
							<div class="col-sm-6 col-lg-5">
								<h2>Notifications</h2>
								<p></p>
							</div>	
							<div class="col-sm-6 col-lg-7" v-if="storeNotification.listNotifications.length > 0">
								<div class="text-end">
									<button @click="allRead" class="btn btn-mark">Lire tout</button>
								</div>
							</div>	
						</div>	
					</div>	
					
					<!-- Notifications List -->					
					<div class="notify-sec">
						<div class="row">
							<div class="col-md-12" v-if="storeNotification.listNotifications.length > 0">
								<div class="notify-item" :style="{backgroundColor: item.user_read === 0 ? null : 'rgb(59 130 246 / 0.5)'}" v-for="(item, index) in storeNotification.listNotifications.slice(0,1)"
									:key="index">
									<div class="row align-items-center">
										<div class="col-md-9">
											<div class="notify-content">
												<div class="notify-detail">
													<p :style="{color: item.user_read === 0 ? null : 'white'}">{{ item.content }}</p>
													<p :style="{color: item.user_read === 0 ? null : 'white'}">{{ dfhFormat(item.created_at) }}</p>
												</div>
											</div>
										</div>
										<div class="col-md-3">
											<div  class="text-md-end">
												<button v-if="item.user_read === 0" @click="readNotification(item.id)" class="btn">Marquer comme lue</button>
												<button v-else class="text-white">Déjà lue</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12" v-else >
								Aucune notification
							</div>
						</div>						
					</div>
					<!-- /Notifications List -->	
					
				</div>
			</section>
			<!-- /Notification -->
		</div>
        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>

<script>
import moment from "moment";
moment.locale("fr");
import { useAuthStore } from '@stores/auth'
import { useNotificationStore } from '@stores/notification'
export default {
  components: {},
  data() {
    return {
      storeAuth: useAuthStore(),
      storeNotification: useNotificationStore(),
    };
  },
  async mounted() {
    if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
      await this.storeNotification.list();
    }
  },
  methods: {
	dfhFormat(date) {
      if(date == null) return '----'
      return moment(new Date(date).getTime()).fromNow();
    },
	async allRead(){
		await this.storeNotification.readAll()
		await this.storeNotification.list();
	},
	async readNotification(id){
		await this.storeNotification.read(id)
		await this.storeNotification.list();
	}
  },
};
</script>