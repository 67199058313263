<template>
<!-- Student Header -->
<div v-if="storeAuth.user !== null" class="course-student-header" >
    <div class="container">
        <div class="student-group">
            <div class="course-group ">
                <div class="course-group-img d-flex">
                    <router-link to="student-profile"><img :src="storeAuth.user.photo !== null ? storeAuth.user.photo : '@/assets/img/user/user11.jpg'" alt="" class="img-fluid"></router-link>
                    <div class="d-flex align-items-center">
                        <div class="course-name">
                            <h4><router-link to="student-profile">{{ storeAuth.user.first_name }} {{ storeAuth.user.last_name }}</router-link></h4>
                            <p>{{ storeAuth.user.role }}</p>
                        </div>
                    </div>
                </div>
                <div class="course-share ">
                    <a href="javascript:;" class="btn btn-primary">Paramètres du compte</a>
                </div>
            </div>
        </div>
        <div class="my-student-list">
            <ul>
                <li :class="currentPath == 'setting-edit-profile' ? 'active' : 'notactive'"><router-link to="/setting-edit-profile">Profil</router-link></li>
                <li :class="currentPath == 'mes-course' ? 'active' : 'notactive'"><router-link to="/mes-course">Mes cours</router-link></li>
                <li :class="currentPath == 'course-wishlist' ? 'active' : 'notactive'"><router-link to="/course-wishlist">Listes de souhaits</router-link></li>
                <li :class="currentPath == 'course-message' ? 'active' : 'notactive'"><router-link to="/course-message">Messages</router-link></li>
                <li :class="currentPath == 'purchase-history' ? 'active' : 'notactive'"><router-link to="/purchase-history">historique des achats</router-link></li>
                <!-- <li :class="currentPath == 'deposit-student' ? 'active' : 'notactive'"><router-link to="deposit-student">Deposit</router-link></li>
                <li :class="currentPath == 'transactions-student' ? 'active' : 'notactive'" class="mb-0"><router-link to="transactions-student">Transactions</router-link></li> -->
            </ul>
        </div>
    </div>
</div>
<!-- /Student Header -->
</template>

<script>
import { useAuthStore } from '@stores/auth'
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
    };
  },
  async mounted() {},
  methods: {},
};
</script>