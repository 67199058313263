<template>
  <!-- sidebar -->
  <div class="col-xl-3 col-md-4 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="settings-widget dash-profile mb-3">
        <div class="settings-menu p-0">
          <div class="profile-bg">
            <!-- <h5>Beginner</h5> -->
            <img :src="storeAuth.user.banner" alt="" />
            <div class="profile-img">
              <router-link to="student-profile"
                ><img :src="storeAuth.user.photo" alt=""
              /></router-link>
            </div>
          </div>
          <div class="profile-group">
            <div class="profile-name text-center">
              <h4><router-link to="student-profile">{{ storeAuth.user.first_name }} {{ storeAuth.user.last_name }}</router-link></h4>
              <p>Étudiant</p>
            </div>
            <div class="go-dashboard text-center">
              <router-link to="deposit-student-dashboard" class="btn btn-primary"
                >Aller au tableau de bord</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="settings-widget account-settings">
        <div class="settings-menu">
          <h3>PARAMÈTRES DU COMPTE</h3>
          <ul>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'setting-edit-profile' ? 'active' : 'notactive'"
                to="setting-edit-profile"
                class="nav-link"
              >
                <i class="feather-settings"></i> Modifier le profil
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-student-security' ? 'active' : 'notactive'
                "
                to="setting-student-security"
                class="nav-link"
              >
                <i class="feather-user"></i> Sécurité
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-student-social-profile' ? 'active' : 'notactive'
                "
                to="setting-student-social-profile"
                class="nav-link"
              >
                <i class="feather-refresh-cw"></i> Profils sociaux
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-student-notification' ? 'active' : 'notactive'
                "
                to="setting-student-notification"
                class="nav-link"
              >
                <i class="feather-bell"></i> Notifications
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'setting-student-privacy' ? 'active' : 'notactive'"
                to="setting-student-privacy"
                class="nav-link"
              >
                <i class="feather-lock"></i> Confidentialité du profil
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-student-delete-profile' ? 'active' : 'notactive'
                "
                to="setting-student-delete-profile"
                class="nav-link"
              >
                <i class="feather-trash-2"></i> Supprimer le profil
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-student-accounts' ? 'active' : 'notactive'
                "
                to="setting-student-accounts"
                class="nav-link"
              >
                <i class="feather-user"></i> Comptes associés
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-student-referral' ? 'active' : 'notactive'
                "
                to="setting-student-referral"
                class="nav-link"
              >
                <i class="feather-user-plus"></i> Références
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'login' ? 'active' : 'notactive'"
                to="login"
                class="nav-link"
              >
                <i class="feather-power"></i> Se déconnecter
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'setting-support-tickets' ? 'active' : 'notactive'"
                to="setting-support-tickets"
                class="nav-link"
              >
                <i class="feather-clipboard"></i> Demandes d'assistance
              </router-link>
            </li>
          </ul>
          <h3 class="student-title">ABONNEMENT</h3>
          <ul>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-student-subscription' ? 'active' : 'notactive'
                "
                to="setting-student-subscription"
                class="nav-link"
              >
                <i class="feather-calendar"></i> Mes abonnements
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'setting-student-billing' ? 'active' : 'notactive'"
                to="setting-student-billing"
                class="nav-link"
              >
                <i class="feather-credit-card"></i> Informations de facturation
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'setting-student-payment' ? 'active' : 'notactive'"
                to="setting-student-payment"
                class="nav-link"
              >
                <i class="feather-credit-card"></i> Paiement
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :class="currentPath == 'setting-student-invoice' ? 'active' : 'notactive'"
                to="setting-student-invoice"
                class="nav-link"
              >
                <i class="feather-clipboard"></i> Facture
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- /sidebar -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
export default {
  data() {
    return {
      storeAuth: useAuthStore()
    };
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
