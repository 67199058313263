<template>
  <ul class="nav header-navbar-rht">
    <li class="nav-item">
      <router-link class="" to="/register-prof">Donner des cours </router-link>
    </li>
    <li class="nav-item">
      <router-link class="" to="/login">Connexion</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link border  bg-black text-white rounded px-4" to="/register">S'inscrire</router-link>
    </li>
  </ul>
</template>
<script>
  export default {
    computed: {
      isHometwoRoute() {
        return this.$route.path === "/index-two";
      },
      isHomethreeRoute() {
        return this.$route.path === "/index-three";
      },
      isHomefourRoute() {
        return this.$route.path === "/index-four";
      },
    },
  };
</script>
