<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <!-- Page Wrapper -->
    <div class="page-content instructor-page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>

          <!-- Instructor Dashboard -->
          <div class="col-xl-9 col-lg-8 col-md-12">
            <div class="row">
              <div class="col-md-4 d-flex">
                <div class="card instructor-card w-100">
                  <div class="card-body">
                    <div class="instructor-inner">
                      <h6>Montant à retirer</h6>
                      <h4 class="instructor-text-success">{{ storeAuth.user.solde_retirable }}€</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-flex">
                <div class="card instructor-card w-100">
                  <div class="card-body">
                    <div class="instructor-inner">
                      <h6>Revenus</h6>
                      <h4 class="instructor-text-success">{{ storeAuth.user.prof_dash_orders[11] }}€</h4>
                      <p>ce mois</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-flex">
                <div class="card instructor-card w-100">
                  <div class="card-body">
                    <div class="instructor-inner">
                      <h6>ÉLEVES</h6>
                      <h4 class="instructor-text-info">{{ storeAuth.user.prof_dash_total_orders[11]<10 ? '0' : '' }}{{ storeAuth.user.prof_dash_total_orders[11] }}</h4>
                      <p>ce mois</p>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div class="col-md-12">
              <div class="card instructor-card">
                <div class="card-header">
                  <h4>Revenus</h4>
                </div>
                <div class="card-body">
                  <apexchart
                    type="area"
                    height="300"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card instructor-card">
                <div class="card-header">
                  <h4>Eleves</h4>
                </div>
                <div class="card-body">
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptions1"
                    :series="series1"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="settings-widget">
                  <div class="settings-inner-blk p-0">
                    <div class="sell-course-head comman-space">
                      <h3>Vos cours les plus vendus</h3>
                    </div>
                    <div class="comman-space pb-0">
                      <div
                        class="settings-tickets-blk course-instruct-blk table-responsive"
                      >
                        <!-- Referred Users-->
                        <table class="table table-nowrap">
                          <thead>
                            <tr>
                              <th>COURS</th>
                              <th>VENTES</th>
                              <th>MONTANT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in storeCourses.bestCoursesProfs" :key="index">
                              <td>
                                <div class="sell-table-group d-flex align-items-center">
                                  <div class="sell-group-img">
                                    <router-link :to="'/course-details/'+item.slug">
                                      <img
                                        :src="item.image"
                                        class="img-fluid"
                                        alt=""
                                      />
                                    </router-link>
                                  </div>
                                  <div class="sell-tabel-info">
                                    <p>
                                      <router-link :to="'/course-details/'+item.slug"
                                        >{{ item.title }}</router-link
                                      >
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>{{ item.total_etudiant }}</td>
                              <td>{{ item.price }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- /Referred Users-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Instructor Dashboard -->
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
import { useCoursesStore } from '@stores/courses'
export default {
  data: function () {
    return {
      storeAuth: useAuthStore(),
      storeCourses: useCoursesStore(),
      chartOptions: { 
        colors: ["#FF9364"],
        chart: {
          id: "vuechart-example",
          type: "area",
          height: 300,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        markers: {
          size: 3,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
        },
        grid: {
          show: false,
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
        },
        xaxis: {
          categories: [], 
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "€";
            },
          },
        },
      },
      series: [
        {
          name: "Mois",
          data: [],
          // data: [0, 10, 40, 43, 40, 25, 35, 25, 40, 30],
        },
      ],
      chartOptions1: { 
        chart: {
          id: "vuechart-example",
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
            endingShape: "rounded",
            startingShape: "rounded",
          },
        },
        colors: ["#1D9CFD"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        xaxis: {
          categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "" + val + "";
            },
          },
        },
      },
      series1: [
        {
          name: "Revenu",
          data: [], 
        },
      ],
    };
  },
  async mounted() {
    await this.storeCourses.bestCoursesProf();

    this.chartOptions.xaxis.categories = this.storeAuth.user.prof_dash_months;
    this.chartOptions1.xaxis.categories = this.storeAuth.user.prof_dash_months;

    this.series = [
      {
        name: "Revenu",
        data: this.storeAuth.user.prof_dash_orders,
      },
    ];
    this.series1 = [
      {
        name: "Élèves",
        data: this.storeAuth.user.prof_dash_total_orders,
      },
    ];
  }, 
};
</script>
