import axios from '@axios'
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'
import { router } from '@/router';

export const useMessagesStore = defineStore('messages', {
  state: () => ({ listMessages: [], message: null, users: [], loading: false }),
  actions: {
    getMessages(id) {
      this.loading = true;
      axios.get(`/api/get-messages/${id}`).then((response) => {
        this.listMessages = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    getUser() {
      this.loading = true;
      axios.get(`/api/get-messages-users`).then((response) => {
        this.users = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    sendMessage(body) {
      axios.post(`/api/send-message`, body).then((response) => {
        this.listMessages.push(response.data.data)
      }).catch(() => {
        console.error("Une erreur est survenue!")
      })
    },
    startMessage(body) {
      axios.post(`/api/start-message`, body).then(() => {
        router.push('/course-message?conv='+body.receiver_id)
      }).catch(() => {
        console.error("Une erreur est survenue!")
      })
    },
  },
})