<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsHome v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layoutsHome>
    <layoutsindex ></layoutsindex>

    <blogdetails></blogdetails>

    <!-- Blog Details -->
    <section v-if="storeBlog.blog !== null" class="course-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-12">
            <!-- Blog Post -->
            <div class="blog">
              <div class="blog-image">
                <router-link to="blog-details"
                  ><img
                    class="img-fluid"
                    :src="storeBlog.blog.image"
                    alt="Post Image"
                /></router-link>
              </div>
              <div class="blog-info clearfix">
                <div class="post-left">
                  <ul>
                    <li>
                      <div class="post-author">
                        <router-link to="instructor-profile"
                          ><img
                            src="@/assets/img/user/user.jpg"
                            alt="Post Author"
                          />
                          <span>{{ storeBlog.blog.author }}</span></router-link
                        >
                      </div>
                    </li>
                    <li>
                      <img
                        class="img-fluid"
                        src="@/assets/img/icon/icon-22.svg"
                        alt=""
                      />{{ storeBlog.blog.created_at }}
                    </li>
                    <li>
                      <img
                        class="img-fluid"
                        src="@/assets/img/icon/icon-23.svg"
                        alt=""
                      />{{ storeBlog.blog.categorie }}
                    </li>
                  </ul>
                </div>
              </div>
              <h3 class="blog-title">
                <router-link to="#"
                  >{{ storeBlog.blog.title }}</router-link
                >
              </h3>
              <div class="blog-content">
                <p>
                  {{ storeBlog.blog.content }}
                </p>
              </div>
            </div>
            <!-- /Blog Post -->
          </div>

          <blogsidebar></blogsidebar>
        </div>
      </div>
    </section>
    <!-- /Blog Details -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
  import { useBlogStore } from '@stores/blog'
  import { useCategorieStore } from '@stores/categorie'
  import { useAuthStore } from '@stores/auth'
  import { router } from '@/router';
  export default {
    data() {
      return {
        storeAuth: useAuthStore(),
        storeBlog: useBlogStore(),
        storeCategorie: useCategorieStore(),
      };
    },
    async mounted() {
      await this.storeBlog.get(router.currentRoute.value.params.slug)
      await this.storeCategorie.articleCategories()
    },

    methods: {
      
    },
  };
</script>