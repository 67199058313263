<template>
  <!-- Main Wrapper -->
  <section class="flex w-full bg-gray-100 items-center min-h-screen justify-center">
    <div class="flex w-full md:w-[50%] big_screen:w-[40%] h-auto">
      <div class="hidden lg:flex !bg-[#58bbde] lg:w-1/3 rounded-l-lg px-6 py-9  flex-col space-y-4 justify-center">
        <img src="https://themusichall.fr/assets/hero.png" class="w-full">
      </div>
      <div class="bg-white w-full rounded-lg md:rounded-l-none xs:mx-2 lg:mx-0 lg:w-2/3 rounded-r-lg px-6 py-9 flex flex-col space-y-4 justify-center">
        <div class="flex justify-between w-full items-center">
          <a href="/"><img src="https://themusichall.fr/assets/logo.svg"  alt="Logo" class="w-12" /></a>
          <a href="/" class="p-2 rounded bg-black text-white font-bold"> <i class="fa fa-arrow-left"></i> Retour</a>
        </div>
        <h2 class="font-bold text-2xl">Connexion</h2>
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
          <div class="form-group">
            <label class="form-label">Adresse email <span>*</span></label>
            <Field
              name="email"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.email }"
            />
            <div class="invalid-feedback">{{ errors.email }}</div>
            <div class="emailshow text-danger" id="email"></div>
          </div>
          <div class="form-group">
            <label class="form-control-label">Mot de passe</label>
            <div class="pass-group">
              <Field
                name="password"
                :type="showPassword ? 'text' : 'password'"
                class="form-control pass-input mt-2"
                :class="{ 'is-invalid': errors.password }"
              />
              <span @click="toggleShow" class="toggle-password">
                <i
                  :class="{
                    'fas fa-eye': showPassword,
                    'fas fa-eye-slash': !showPassword,
                  }"
                ></i>
              </span>
              <div class="invalid-feedback">{{ errors.password }}</div>
              <div class="emailshow text-danger" id="password"></div>
            </div>
          </div>
          <div class="forgot flex justify-between">
            <span
              ><router-link class="forgot-link" to="forgot-password"
                >Mot de passe oublié ?</router-link
              ></span
            >
            <span>
              <router-link class="forgot-link" to="register">
                S'inscrire
              </router-link>
            </span>
          </div> 
          <div class="d-grid">
            <button class="btn btn-primary btn-start" type="submit">
              <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                <span class="sr-only">Chargement...</span>
              </div>
              <span v-else>
                Connexion
              </span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </section>
  <!-- /Main Wrapper -->
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuthStore } from '@stores/auth'
export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      emailError: "",
      passwordError: "",
      storeAuth: useAuthStore()
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async onSubmit(values) {
      this.emailError = "";
      this.passwordError = "";

      await this.storeAuth.login({email: values.email, password: values.password})
    },
  },
  setup() {
    let users = localStorage.getItem("storedData");
    if (users === null) {
      let password = [
        {
          email: "johndoe@themusichall.fr",
          password: "123456",
        },
      ];
      const jsonData = JSON.stringify(password);
      localStorage.setItem("storedData", jsonData);
    }
    const schema = Yup.object().shape({
      email: Yup.string().required("Veuillez insérer votre email").email("Email invalide"),
      password: Yup.string()
        .min(6, "Votre mot de passe doit contenir au moins 6 caractères.")
        .required("Veuillez insérer votre mot de passe"),
    });
    return {
      schema,
    };
  },
};
</script>
