<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsHome v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layoutsHome>
    <layoutsindex ></layoutsindex>

    <!-- Course Lesson -->
    <section class="page-content course-sec course-lesson">
      <div class="container">
        <div v-if="storeCourses.course !== null" class="row">
          <div class="col-lg-8">
            <!-- Introduction -->
            <div
              v-if="storeLessons.coursLessons[0] !== null"
              class="student-widget lesson-introduction"
              style="border: 0px"
            >
              <div
                class="lesson-widget-group rounded mb-0"
                style="padding: 0px !important"
              >
                <div class="w-full flex"> 
                  <span
                    class="-mb-5 text-white tittle pb-4 pl-3 pt-3 pr-9 w-auto rounded-t bg-black"
                  >
                    {{ titleChange.slice(0,70) }}
                  </span>
                </div>
                <video
                  ref="videoPlayer"
                  style="width: 100%"
                  class="video-thumbnail rounded max-h-[400px]"
                  data-fancybox=""
                  :src="videoChange"
                  controls
                  controlsList="nodownload"
                  @ended="handleVideoEnded"
                ></video>
              </div>
            </div>
            <div class="hidden mt-4 lg:flex justify-between items-center">
              <div class="flex space-x-2 3xl:text-xl">
                <button
                  :disabled="liked"
                  @click="userLikedCourse"
                  class="bg-gray-300 border rounded py-3 px-5 hover:bg-black hover:text-white"
                >
                  <i class="text-red-500 fa fa-heart"></i> {{ liked === true ? "Vous avez aimé ce cours" : "J'aime" }}</button
                ><button
                  @click="shareCourse()"
                  href=""
                  class="bg-gray-300 border rounded py-3 px-5 hover:bg-black hover:text-white"
                >
                  Partager le cours</button
                ><button
                  @click="startMessage(storeCourses.course.prof.id)"
                  class="bg-gray-300 border rounded py-3 px-5 hover:bg-black hover:text-white"
                >
                  Contacter le professeur</button
                >
              </div>
              <!-- <a href=""
                ><i
                  class="fa fa-ellipsis-v rotate-90 text-gray-400 text-3xl"
                ></i
              ></a> -->
            </div>

            <div
              class="3xl:text-xl mt-[10%] flex flex-col md:flex-row md:space-x-2 space-y-3 md:space-y-0"
            >
              <a
                href=""
                class="px-8 py-3 font-bold bg-gray-400 text-white hover:bg-[#66C9F5]"
                >Description</a
              ><a
                href=""
                class="px-8 py-3 font-bold bg-gray-400 text-white hover:bg-[#66C9F5]"
                >Fichiers</a
              ><a
                href=""
                class="px-8 py-3 font-bold bg-gray-400 text-white hover:bg-[#66C9F5]"
                >Challenge</a
              >
            </div>

            <form @submit.prevent="newCommentaire"  class="mt-5 flex space-x-3 items-center w-full ">
              <i class="fa fa-user text-[#58bbde] p-2 w-auto rounded bg-gray-200"></i>
              <input type="text" v-model="content" name="content" class="bg-transparent w-full border-b p-2" id="" placeholder="laissez un commentaire">
              <div
                v-if="storeCommentaire.loading === true"
                class="spinner-border"
                role="status"
              >
                <span class="sr-only">Chargement...</span>
              </div>
              <button v-else class="p-2 rounded bg-black hover:bg-[#58bbde] text-white flex items-center space-x-2"><i class="fa fa-comment"></i><span class="text-white">Commenter</span></button>
            </form>
 
            <div class="my-5 ">
              <h5 class="subs-title">Commentaires</h5>
              <div class="  py- flex flex-col space-y-3">
                <div v-for="(item,index) in storeCommentaire.listCommentCourses" :key="index"  class=" bg-white rounded border items-center  space-x-5 flex border-b p-3">
                  <div class="flex items-center space-x-2">
                    <img :src="item.author_icon" class="rounded-full w-8 h-8" alt="">
                    <h3 class="text-black font-extrabold text-sm mb-0">{{ item.author.toUpperCase() }}</h3>
                  </div>
                  <span class="text-black">{{ item.content }}</span>
                </div> 
              </div>
            </div>
            <!-- /Reviews -->

            <!-- /Introduction -->
          </div>
          <div class="col-lg-4">
            <!-- Course Lesson -->
            <div class="lesson-group">
              <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class=""
                    data-bs-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="true"
                    >Voir le(s) leçon(s)
                    <span
                      >{{ storeCourses.course.total_lessons }} Leçon(s)</span
                    >
                  </a>
                </h6>
                <div
                  id="collapseOne"
                  class="card-collapse collapse show"
                  style=""
                >
                  <div class="modal-action mb-4">
                    <button v-if="index > 0" @click="prev"  class="btn bg-black text-white " :class="index + 1 < storeLessons.coursLessons.length && index > 0 ? 'w-1/2' : 'w-full'">
                      Leçon précédente
                    </button>
                    <button v-if="index + 1 < storeLessons.coursLessons.length" :class="index + 1 < storeLessons.coursLessons.length && index > 0 ? 'w-1/2' : 'w-full'" @click="next" class=" btn bg-black text-white">
                      Leçon suivante
                    </button>
                  </div>
                  <div class="progress-stip">
                    <div
                      :style="{ width: `${progressionChange}%` }"
                      class="progress-bar bg-success progress-bar-striped active-stip"
                    ></div>
                  </div>
                  <div class="student-percent lesson-percent">
                    <p>
                      {{ storeCourses.course.duration
                      }}<span>{{ progressionChange }}%</span>
                    </p>
                  </div>
                  <ul>
                    <li
                      @click="watchLessons(item, index)"
                      style="cursor: pointer"
                      v-for="(item, index) in storeLessons.coursLessons"
                      :key="index"
                    >
                      <p :class="lu === item.id ? 'play-intro' : ''">
                        {{ item.title }}
                      </p>
                      <div>
                        <img
                          v-if="item.user_start === true"
                          src="@/assets/img/icon/play-icon.svg"
                          alt=""
                        />
                        <img v-else src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
                <dialog id="add_modal" class="modall bg-transparent w-full items-center justify-center">
                  <div class="modal-box flex flex-col space-y-5">
                    <h3 class="font-bold text-lg">Noter ce cours</h3>
                    <form @submit.prevent="saveCommentaire" class="space-y-5">
                      <div class="form-group rating flex items-center w-full">
                        <i
                          v-for="star in stars"
                          :key="star"
                          style="cursor: pointer"
                          @click="rate(star)"
                          :class="{
                            'fas fa-star filled me-1': star <= getRating,
                            'fas fa-star me-1': star > getRating,
                          }"
                          class="text-3xl"
                        ></i>
                      </div>

                      <div class="form-group">
                        <textarea
                          rows="4"
                          v-model="commentaire"
                          required
                          class="form-control"
                          placeholder="Laissez un commentaire"
                        ></textarea>
                      </div>
                      <div class="submit-section flex justify-end space-x-3"> 
                        <button class="btn submit-btn !bg-[#58bbde]" type="submit">
                          <div
                            v-if="storeNotes.loadingAdd === true"
                            class="spinner-border"
                            role="status"
                          >
                            <span class="sr-only">Chargement...</span>
                          </div>
                          <span class="text-white hover:text-white" v-else> Noter </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </dialog>


                <dialog id="share_modal" class="modall bg-transparent w-full items-center justify-center">
                  <div class="modal-box flex flex-col space-y-5">
                    <div class="flex justify-between">
                      <h3 class="font-bold text-lg">Partager ce cours</h3>
                      <button @click="closeShareCourse()" class="font-bold text-sm">X</button>
                    </div>
                     <div class="w-full flex flex-col ">
                      <div class=" flex  ">
                        <span class="rounded-l border border-black text-black w-[70%] p-2">{{   link }}</span>
                        <button @click="copyLinkToClipboard()"  class="rounded-r border font-bold text-white bg-black  border-black w-[30%] p-2"> <i class="fa fa-link"></i> {{ copied ? 'Copié' : 'Copier' }}</button>
                      </div>
                      <i class="fa facebook"></i>
                     </div>
                  </div>
                </dialog>
              </div>
            </div>
            <!-- /Course Lesson -->

            <!-- Outil -->
            <div class="lesson-group">
              <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class=""
                    data-bs-toggle="collapse"
                    href="#collapse2"
                    aria-expanded="true"
                    >Outil pratique
                  </a>
                  <div id="collapse2" class="card-collapse collapse show">
                    <!-- <img src="https://themusichall.fr/assets/metronome.svg"/> -->
                    <li class="flex">
                      <img
                        src="@/assets/img/icon/cloud.svg"
                        class="me-2"
                        alt=""
                      />
                      Métronome
                    </li>
                  </div>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Course Lesson -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { useAuthStore } from "@stores/auth";
import { useNoteStore } from "@stores/notes";
import { useLessonsStore } from "@stores/lessons";
import { useCoursesStore } from "@stores/courses";
import { useMessagesStore } from "@stores/messages";
import { useCommentaireStore } from "@stores/commentaire";
import { router } from "@/router";

export default {
  components: {},
  data() {
    return {
      storeMessages: useMessagesStore(),
      storeCommentaire: useCommentaireStore(),
      storeAuth: useAuthStore(),
      storeLessons: useLessonsStore(),
      storeCourses: useCoursesStore(),
      storeNotes: useNoteStore(),
      commentaire: "",
      content: "",
      isComment: false,
      liked: false,
      link: "",
      copied: false,
      currentRating: 4,
      maxRating: 5,
      progression: 0,
      video: null,
      title: "",
      lu: 0,
      index: 0,
      countStart: 3,
    };
  },

  computed: {
    stars: function () {
      return Array.from({ length: this.maxRating }, (_, index) => index + 1);
    },
    getRating: {
      get() {
        return this.currentRating;
      },
      set(value) {
        this.currentRating = value;
      },
    },
    videoChange: {
      get() {
        return this.video;
      },
      set(value) {
        this.video = value;
      },
    },
    titleChange: {
      get() {
        return this.title;
      },
      set(value) {
        this.title = value;
      },
    },
    progressionChange: {
      get() {
        return this.progression;
      },
      set(value) {
        this.progression = value;
      },
    },
    countChange: {
      get() {
        return this.countStart;
      },
      set(value) {
        this.countStart = value;
      },
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getFirstLesson() {
      if (this.storeLessons.coursLessons.length > 0 && this.index === 0) {
        if (this.storeLessons.coursLessons.length == 1) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isComment = true;
        }
        this.watchLessons(this.storeLessons.coursLessons[0]);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.countChange = this.storeLessons.coursLessons.filter(
          (item) => item.user_start === true
        ).length;
        if (this.countChange > 0) {
          this.CalculeProgression();
        }
      }
    },
  },

  async mounted() {
    await this.storeCourses.getAuth(router.currentRoute.value.params.slug);
    await this.storeLessons.getCoursLessons(router.currentRoute.value.params.slug);
    this.link = window.location.href;
    this.storeLessons.$subscribe((mutation, state) => {
      // import { MutationType } from 'pinia'
      mutation.type // 'direct' | 'patch object' | 'patch function'
      // same as cartStore.$id
      mutation.storeId // 'cart'
      // only available with mutation.type === 'patch object'
      mutation.payload // patch object passed to cartStore.$patch()
      this.startFirstLesson(state.coursLessons);
      console.log(`state change ${state.coursLessons}`)
    })
    await this.storeNotes.listNoteCourses(router.currentRoute.value.params.slug);
    await this.storeCommentaire.listCourses(router.currentRoute.value.params.slug);

    this.storeCourses.$subscribe(async (mutation, state) => {
      // import { MutationType } from 'pinia'
      mutation.type // 'direct' | 'patch object' | 'patch function'
      // same as cartStore.$id
      mutation.storeId // 'cart'
      // only available with mutation.type === 'patch object'
      mutation.payload // patch object passed to cartStore.$patch()
      if(state.course !== null){
        console.log(await this.storeCourses.checkUserLike(state.course.id))
        this.liked = await this.storeCourses.checkUserLike(state.course.id);
      }
    })
  },  

  methods: {
    async startMessage(id){
      await this.storeMessages.startMessage({
        receiver_id: id,
      })
    },
    async copyLinkToClipboard() {
      await navigator.clipboard.writeText(this.link);
      this.copied = true;
    },
    async userLikedCourse(){
      await this.storeCourses.liked(this.storeCourses.course.id);
      this.liked = true;
    },
    handleVideoEnded() {
      this.next(); // Appelé à la fin de la lecture de la vidéo
    },
    rate(rating) {
      console.log(rating);
      this.getRating = rating;
      console.log(this.getRating);
    },

    startFirstLesson(lessons) {
      console.log(`storeLesson ${lessons.length}`);
      if (lessons.length > 0) {
        if (lessons.length === 1) {
          this.isComment = true;
        }

        this.watchLessons(lessons[0], 0);

        this.countChange = lessons.filter(
          (item) => item.user_start === true
        ).length;

        if (this.countChange > 0) {
          this.CalculeProgression();
        }
      }
    },
    async next() {
      if (this.index + 1 < this.storeLessons.coursLessons.length) {
        this.index++;
        await this.loadLesson();
      }
    },

    async prev() {
      if (this.index > 0) {
        this.index--;
        await this.loadLesson();
      }
    },
    async loadLesson() {
    // Arrêter la lecture vidéo en cours
    const videoPlayer = this.$refs.videoPlayer;
    videoPlayer.pause();
    videoPlayer.currentTime = 0;

    // Charger la nouvelle leçon
    this.watchLessons(this.storeLessons.coursLessons[this.index], this.index);
  },
    async saveCommentaire() {
      try {
        await this.storeNotes.addNoteCourses(
          {
            commentaire: this.commentaire,
            note: this.currentRating,
            course_id: this.storeCourses.course.id,
          },
          router.currentRoute.value.params.slug
        );
        this.isComment = true;
        const dialog = document.getElementById("add_modal");
        dialog.remove(); 
      } catch (e) {
        console.log(e);
      }
    },

    async newCommentaire() {
      try {
        await this.storeCommentaire.postCourses(
          {
            content: this.content,
            course_id: this.storeCourses.course.id,
          },
          router.currentRoute.value.params.slug
        );
        this.content = "";
        
      } catch (e) {
        console.log(e);
      }
      await this.storeCommentaire.listCourses(router.currentRoute.value.params.slug);
    },
    async watchLessons(lesson, index) {
      if (lesson.user_start === false) {
        await this.storeLessons.watchLesson({
          lesson_id: lesson.id,
        });
        this.storeLessons.coursLessons[index].user_start = true;
        this.countChange = this.countChange + 1;
        this.CalculeProgression();
      }

      this.videoChange = lesson.video;
      this.titleChange = lesson.title;
      this.lu = lesson.id;
      if (
        this.index + 1 === this.storeLessons.coursLessons.length &&
        this.storeCourses.course.user_comment === false
      ) {
        const dialog = document.getElementById("add_modal");
        dialog.classList.add('flex')
        dialog.showModal();
      }
    },
    CalculeProgression() {
      this.progressionChange = Math.trunc(
        (this.countChange / this.storeLessons.coursLessons.length) * 100
      );
    },

    shareCourse() {
      const dialog = document.getElementById("share_modal");
        dialog.classList.add('flex')
        dialog.showModal();
    },

    async closeShareCourse() {
      const dialog = document.getElementById("share_modal");
      await dialog.classList.remove('flex')
      await dialog.close();
    },
  },
};
</script>
