<template>
  <!-- Main Wrapper -->
  <section class="flex w-full bg-gray-100 items-center min-h-screen justify-center">
    <div class="flex w-full md:w-[30%] big_screen:w-[30%] h-auto">
      <div class="bg-white w-full rounded-lg xs:mx-2 lg:mx-0 px-6 py-9 flex flex-col space-y-4 justify-center">
        <a href="/" class="w-full flex justify-center"><img src="https://themusichall.fr/assets/logo.svg"  alt="Logo" class="w-12" /></a>
        <h2 class="font-bold text-2xl w-full text-center">Mot de passe oublié</h2>
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="form-group">
                <label class="form-control-label">Email</label>
                <Field
                  name="email"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                />
              </div>
              <div class="d-grid">
                <button class="btn btn-start" type="submit">
                  <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                    <span class="sr-only">Chargement...</span>
                  </div>
                  <span v-else>
                    Réinitialiser
                  </span>
                </button>
              </div>
            </Form>
      </div>
    </div>
  </section>
  <!-- /Main Wrapper -->
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuthStore } from '@stores/auth'
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      emailError: "",
    };
  },
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
  },
  methods: {
    // submitForm() {
    //   this.$router.push("/login");
    // },
    async onSubmit(values) {
      this.storeAuth.email = values.email
      await this.storeAuth.forgotPassword({email: values.email})
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required").email("Email is invalid"),
    });
    return {
      schema,
    };
  },
};
</script>
