import axios from '@axios'
import 'vue3-toastify/dist/index.css';
import { defineStore } from 'pinia'

export const useCategorieStore = defineStore('categorie', {
  state: () => ({ listCategorie: [], categorie: null, blogCategorie: [], courseCategorie: [], loading: false }),
  actions: {
    list() {
        this.loading = true;
      axios.get(`/api/categories`).then((response) => {
        this.listCategorie = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    get(id) {
      this.loading = true;
      axios.get(`/api/categories/${id}`).then((response) => {
        this.categorie = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.error("Une erreur est survenue!")
      })
    },
    articleCategories() {
      axios.get(`/api/articleCategories`).then((response) => {
        this.blogCategorie = response.data.data;
      }).catch(() => {
        console.error("Une erreur est survenue!")
      })
    },
    courseCategories() {
      axios.get(`/api/courseCategories`).then((response) => {
        this.courseCategorie = response.data.data;
      }).catch(() => {
        console.error("Une erreur est survenue!")
      })
    },
  },
})