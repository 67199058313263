<template>
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>
          <div class="col-xl-9 col-lg-8 col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="settings-widget">
                  <div class="settings-inner-blk p-0">
                    <div class="sell-course-head comman-space">
                      <div class="flex justify-between items-center">
                        <h3>{{ slug ? 'Modifier un cours' : 'Ajouter un nouveau cours' }}</h3>
                        <div class="go-dashboard text-center">
                          <router-link to="/instructor-course" class="btn btn-primary"> <i class="fa fa-arrow-left"></i> Retour</router-link>
                        </div>
                      </div>
                    </div>

                    <div class="comman-space">
                      <div class="container ">
                        <div class="row flex flex-col items-center">
                          <div class="col-md-12">
                            <div class="card">
                              <!-- Course Wizard -->
                              <div class="widget-set">
                                <div class="widget-setcount">
                                  <ul id="progressbar" v-show="currentStep === 1">
                                    <li class="progress-active">
                                      <p><span></span> Informations de base</p>
                                    </li>
                                    <li>
                                      <p><span></span> Médias du cours</p>
                                    </li>
                                    <li>
                                      <p><span></span> Prix</p>
                                    </li>
                                    <li>
                                      <p><span></span> Validation</p>
                                    </li>
                                  </ul>
                                  <ul id="progressbar" v-show="currentStep === 2">
                                    <li>
                                      <p><span></span> Informations de base</p>
                                    </li>
                                    <li class="progress-active">
                                      <p><span></span> Médias du cours</p>
                                    </li>
                                    <li>
                                      <p><span></span> Prix</p>
                                    </li>
                                    <li>
                                      <p><span></span> Validation</p>
                                    </li>
                                  </ul>
                                  <ul id="progressbar" v-show="currentStep === 3">
                                    <li>
                                      <p><span></span> Informations de base</p>
                                    </li>
                                    <li>
                                      <p><span></span> Médias du cours</p>
                                    </li>
                                    <li class="progress-active">
                                      <p><span></span> Prix</p>
                                    </li>
                                    <li>
                                      <p><span></span> Validation</p>
                                    </li>
                                  </ul>
                                  <ul id="progressbar" v-show="currentStep === 4">
                                    <li>
                                      <p><span></span> Informations de base</p>
                                    </li>
                                    <li>
                                      <p><span></span> Médias du cours</p>
                                    </li>
                                    <li>
                                      <p><span></span> Prix</p>
                                    </li>
                                    <li class="progress-active">
                                      <p><span></span> Validation</p>
                                    </li>
                                  </ul>
                                </div>
                                <div class="widget-content multistep-form">
                                  <fieldset id="first" v-if="currentStep === 1">
                                    <div class="add-course-info">
                                      <div class="add-course-inner-header">
                                        <h4>Informations de base</h4>
                                      </div>
                                      <div class="add-course-form">
                                        <form action="#">
                                          <div class="form-group">
                                            <label class="add-course-label">Titre du cours</label>
                                            <input
                                              type="text"
                                              v-model="cours.title"
                                              class="form-control"
                                              placeholder="Entrer le titre du cours"
                                            />
                                          </div>
                                          <div class="form-group">
                                            <label class="add-course-label">Catégorie du cours</label>
                                            <vue-select v-model="cours.categorie_id" :options="getCategories" placeholder="Choisissez votre catégorie" />
                                          </div> 
                                          <div class="form-group mb-0">
                                            <label class="add-course-label">Description du cours</label>
                                            <textarea 
                                            class="form-control"
                                            v-model="cours.description"
                                            name="" id="" cols="30" rows="10">

                                            </textarea> 
                                          </div>
                                        </form>
                                      </div>
                                      <div class="widget-btn">
                                        <a
                                          v-if="cours.title !== '' && cours.categorie_id !== 0 && cours.description !== ''"
                                          class="btn btn-info-light next_btn"
                                          @click="nextStep"
                                          >Suivant</a
                                        >
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset class="field-card" v-if="currentStep === 2">
                                    <div class="add-course-info">
                                      <div class="add-course-inner-header">
                                        <h4>Médias du cours</h4>
                                      </div>
                                      <div class="add-course-form">
                                        <form action="#">
                                          <div class="form-group">
                                            <label class="add-course-label">Image (taille recommandée : 271 x 203 pixels)</label>
                                            <div class="relative-form col-lg-8">
                                              <span>{{ cours.image ? cours.image.name : 'Aucune image sélectionnée' }} </span>
                                              <label class="relative-file-upload">
                                                Choisir une image <input type="file" @change="handleFileChange" accept="image/*"/>
                                              </label>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <div :style="imageUrl ? {backgroundImage: 'url(' + imageUrl + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'} : null" class="add-image-box">
                                              <a v-if="imageUrl === null" href="javascript:void(0);">
                                                <i class="far fa-image"></i>
                                              </a>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <label class="add-course-label">Vidéo de présentation</label>
                                            <div class="relative-form col-lg-8">
                                              <span>{{ cours.video ? cours.video.name : 'Aucune video sélectionnée' }}</span>
                                              <label class="relative-file-upload">
                                                Choisir une vidéo<input type="file" @change="handleFileVideo" accept="video/*"/>
                                              </label>
                                            </div>
                                          </div>
                                          
                                          <div class="form-group">
                                            <video
                                              v-if="videoUrl"
                                              class="add-image-box add-video-box"
                                              :src="videoUrl"
                                              controls
                                            ></video>
                                            <div v-else class="add-image-box add-video-box">
                                              <a href="javascript:void(0);">
                                                <i class="fas fa-circle-play"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      <div v-if="slug" class="widget-btn">
                                        <a
                                          class="btn btn-black prev_btn"
                                          @click="previousStep"
                                          >Précédent</a
                                        >
                                        <a
                                          class="btn btn-info-light next_btn"
                                          @click="nextStep"
                                          >Suivant</a
                                        >
                                      </div>
                                      <div v-else class="widget-btn">
                                        <a
                                          class="btn btn-black prev_btn"
                                          @click="previousStep"
                                          >Précédent</a
                                        >
                                        <a
                                          v-if="cours.image !== null && cours.video !== null"
                                          class="btn btn-info-light next_btn"
                                          @click="nextStep"
                                          >Suivant</a
                                        >
                                      </div>
                                    </div>
                                  </fieldset>
                                  
                                  <fieldset class="field-card" v-if="currentStep === 3">
                                    <div class="add-course-info">
                                      <div class="add-course-inner-header">
                                        <h4>Prix du cours</h4>
                                      </div>
                                      <div class="add-course-form">
                                        <form action="#">
                                          <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                            <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                            </symbol>
                                            <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                            </symbol>
                                            <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                                              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                            </symbol>
                                          </svg>
                                          <div class="alert alert-primary d-flex align-items-center" role="alert">
                                            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
                                            <div>
                                              Si votre cours est offert gratuitement, veuillez maintenir le prix du cours à 0.
                                            </div>
                                          </div>
                                          <div class="form-group mb-0">
                                            <label class="add-course-label">Prix du cours</label>
                                            <input type="number" v-model="price1" class="form-control" />
                                          </div>

                                          <div class="form-group mb-0">
                                            <label class="add-course-label">Ancien prix promotionnel</label>
                                            <input type="number" v-model="price2" class="form-control" />
                                          </div>

                                          <div v-if="price2 !== 0 && price2 < price1" class="alert alert-success mt-4" role="alert">
                                            <p class="mb-0">Veuillez noter que le prix promotionnel doit impérativement être supérieur au prix du cours.</p>
                                          </div>
                                        </form>
                                      </div>
                                      <div class="widget-btn">
                                        <a
                                          class="btn btn-black prev_btn"
                                          @click="previousStep"
                                          >Précédent</a
                                        >
                                        <a
                                          v-if="price1 !== '' && getPrice == true"
                                          class="btn btn-info-light next_btn"
                                          @click="null"
                                          >Suivant</a
                                        >
                                        <a
                                          v-if="price1 !== '' && getPrice == false"
                                          class="btn btn-info-light next_btn"
                                          @click="nextStep"
                                          >Suivant</a
                                        >
                                      </div>
                                    </div>
                                  </fieldset>
                                  <fieldset class="field-card" v-if="currentStep === 4">
                                    <div class="add-course-info">
                                      <div class="add-course-msg">
                                        <i class="fas fa-circle-check"></i>
                                        <h4>Vous venez de terminer la configuration de votre cours</h4>
                                        <p>Si vous souhaitez l'enregistrer, veuillez cliquer sur le bouton "{{ slug ? 'Modifier' : 'Enregistrer' }}".</p>
                                      </div>
                                      <div class="widget-btn">
                                        <a
                                          class="btn btn-black prev_btn"
                                          @click="previousStep"
                                          >Précédent</a
                                        >
                                        <a v-show="currentStep === 4" @click="submit" class="btn btn-success-dark">
                                          <div v-if="storeCourses.loading === true" class="spinner-border" role="status">
                                            <span class="sr-only">Chargement...</span>
                                          </div>
                                          <span v-else>
                                            {{ slug ? 'Modifier' : 'Enregistrer' }}
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                              <!-- /Course Wizard -->
                            </div>
                          </div>
                        </div>
                        {{ getValue }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <layouts1></layouts1>
  </div>
</template>

<script>
import vimeoUpload from "vimeo-upload"
import { useCategorieStore } from '@stores/categorie'
import { useCoursesStore } from '@stores/courses'
import { useAuthStore } from '@stores/auth'
import { router } from '@/router';
export default { 
  data() {
    return {
      storeCategorie: useCategorieStore(),
      storeAuth: useAuthStore(),
      price1: 0,
      price2: 0,
      storeCourses: useCoursesStore(),
      // myValue: "",
      currentStep: 1,
      totalSteps: 4,
      slug: null,
      imageUrl: null,
      videoUrl: null,
      cours: {
        title: '',
        description: '',
        price: 0,
        old_price: 0,
        categorie_id: 0,
        image: null,
        video: null,
      },
    };
  },
  computed: {
    getCategories(){
      let array = []
      if(this.storeCategorie.courseCategorie.length > 0){
        for (let index = 0; index < this.storeCategorie.courseCategorie.length; index++) {
          array.push({
            id: this.storeCategorie.courseCategorie[index].id,
            text: this.storeCategorie.courseCategorie[index].name
          })
          
        }
      }
      return array
    },
    getPrice(){
      if(this.price2 !== 0) {
        if(this.price2 < this.price1){
          return true
        } else {
          return false
        }
      } else{
        return false
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getValue() {
      if (this.storeCourses.course) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.cours = {
          title: this.storeCourses.course.title,
          description: this.storeCourses.course.description,
          price: 0,
          old_price: 0,
          categorie_id: this.storeCategorie.courseCategorie.filter(
            (item) => item.name == this.storeCourses.course.categorie
          )[0].id,
          image: null,
          video: null,
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.price1 = parseInt(this.storeCourses.course.price.split(' ')[0])
        if(this.storeCourses.course.old_price){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.price2 = parseInt(this.storeCourses.course.old_price.split(' ')[0])
        }  
      }
    }
  },
  async mounted() {
    await this.storeCategorie.courseCategories()
    if (router.currentRoute.value.params.slug) {
      this.slug = router.currentRoute.value.params.slug
      await this.storeCourses.get(router.currentRoute.value.params.slug);
      this.cours = {
        title: this.storeCourses.course.title,
        description: this.storeCourses.course.description,
        price: 0,
        old_price: 0,
        categorie_id: this.storeCategorie.courseCategorie.filter(
          (item) => item.name == this.storeCourses.course.categorie
        )[0].id
      }
      if(this.storeCourses.course.old_price){
        this.price1 = this.storeCourses.course.price
        this.price2 = this.storeCourses.course.old_price
      } else{
        this.price1 = this.storeCourses.course.price
      } 
    }
  },
  methods: {
    async submit(){
      const data = new FormData
      if (router.currentRoute.value.params.slug) {
        data.append('title', this.cours.title)
        data.append('categorie_id', this.cours.categorie_id)
        data.append('description', this.cours.description)

        if (this.cours.image !== null) {
          data.append('image', this.cours.image)
        }

        if (this.cours.video !== null) {
          data.append('video', this.cours.video)
        }

        if(this.price1 === ''){
          this.price1 = 0
        }
        
        if (this.price2 !== 0 && this.price2 !== '') {
          this.cours.price = this.price1
          this.cours.old_price = this.price2
          data.append('price', parseInt(this.cours.price))
          data.append('old_price', parseInt(this.cours.old_price))
        } else {
          this.cours.price = this.price1
          delete this.cours.old_price
          data.append('price', parseInt(this.cours.price))
        }
        
        await this.storeCourses.updateCourses(data, this.storeCourses.course.id)
      } else {
        data.append('title', this.cours.title)
        data.append('categorie_id', this.cours.categorie_id)
        data.append('description', this.cours.description)
        data.append('image', this.cours.image)
        data.append('video', this.cours.video)
        if (this.price2 !== 0 && this.price2 !== '') {
          this.cours.price = this.price1
          this.cours.old_price = this.price2
          data.append('price', parseInt(this.cours.price))
          data.append('old_price', parseInt(this.cours.old_price))
        } else {
          this.cours.price = this.price1
          delete this.cours.old_price
          data.append('price', parseInt(this.cours.price))
        }
        
        await this.storeCourses.addCourses(data)
      }
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.cours.image = file
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handleFileVideo(event) {
      const file = event.target.files[0];
      this.cours.video = file
      if (file) {
        this.videoUrl = URL.createObjectURL(file);
      }
      var content = new Blob(["Hello world"], {"type": "text/plain"})
      var uploader = new vimeoUpload({
        file: content,
        name: file.name,
        description: '',
        private: true,
        Accept: "application/vnd.vimeo.*+json;version=3.2",
        token: "0a42f69d1737ffaa4e63cbf2df7cb1b8",
      });
      
      var vimeo = uploader.upload();
      console.log(vimeo);
    }, 
  },
};
</script>
