<template>
<!-- Login Banner -->
<div class="col-md-6 login-bg">
    <div class="owl-carousel login-slide owl-theme">
        <div class="welcome-login">
            <div class="login-banner">
                <!-- <img src="@/assets/img/prof.png" class="img-fluid" alt="Logo"> -->
            </div>
            <div class="mentor-course text-left">
                <h2>Enseigne avec cœur, fais de ta passion ton gagne-pain !</h2>
                <p class="mx-0">
                    🎶 Tu es un(e) expert(e) dans ton domaine, et tu veux partager ta passion avec le monde ? <br>
                    Rejoins notre communauté de Super Professeurs de musique ! <br>

                    Libre et sans contraintes, notre plateforme te permet de connecter avec des élèves inspirés qui sont prêts à plonger dans le monde magique de la musique avec toi. Que tu sois un professionnel expérimenté, un étudiant talentueux ou un autodidacte passionné, il y a une place pour toi ici.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- /Login Banner -->
</template>
<script>
    import { defineComponent } from 'vue'
    
    import 'vue3-carousel/dist/carousel.css';
    
    export default defineComponent({
    name: 'Autoplay',
    components: {
    },
});
</script>