<template>
  <!-- Main Wrapper -->
  <div v-if="storeAuth.user" class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <!--Dashbord Student -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>

          <!-- Profil Details -->
          <div class="col-xl-9 col-md-8">
            <div class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3>Réseaux sociaux</h3>
                  <p>Ajouter les liens de vos réseaux sociaux ici</p>
                </div>
                <div class="checkout-form personal-address">
                  <form action="#">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <div class="flex space-x-3">
                            <!-- <img src="https://www.svgrepo.com/show/513008/twitter-154.svg" style="width: 24px;height: 24px;" alt="twitter"> -->
                          </div>
                          <label class="form-control-label text-black">Twitter</label>
                          <input
                            type="text"
                            v-model="storeAuth.user.twitter"
                            class="form-control"
                            :placeholder="twitter"
                            
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label text-black">Facebook</label>
                          <input
                            type="text"
                            v-model="storeAuth.user.facebook"
                            
                            class="form-control"
                            :placeholder="facebook"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label text-black">Instagram</label>
                          <input
                            type="text"
                            v-model="storeAuth.user.instagram"
                            class="form-control"
                          />
                        </div>
                      </div> 
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label text-black">YouTube</label>
                          <input v-model="storeAuth.user.youtube"  type="text" class="form-control" :placeholder="youtube" />
                        </div>
                      </div>
                      <div v-if="is_disabled === true" class="update-profile save-social">
                        <button @click="is_disabled = false" type="button" class="btn btn-primary">
                          Mettre à jour
                        </button>
                      </div>
                      <div v-else class="update-profile save-social">
                        <button @click="updatedProfilUser" type="button" style="margin-right: 30px;" class="btn btn-primary">
                          <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                            <span class="sr-only">Chargement...</span>
                          </div>
                          <span v-else>
                            Mettre à jour
                          </span>
                        </button> 
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Profil Details -->
        </div>
      </div>
      {{ getUserInfo }}
    </div>
    <!-- /Dashbord Student -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { useAuthStore } from '@stores/auth'
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      facebook: '',
      youtube: '',
      twitter: '',
      instagram: '',
      is_disabled: false,
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    getUserInfo() {
      if (this.storeAuth.user){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.facebook = this.storeAuth.user.facebook
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.youtube = this.storeAuth.user.youtube
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.twitter = this.storeAuth.user.twitter
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.instagram = this.storeAuth.user.instagram
      }
    }
  },
  methods: {
    async updatedProfilUser() {
      await this.storeAuth.updateProfil({
        facebook: this.facebook,
        instagram: this.instagram,
        twitter: this.twitter,
        youtube: this.youtube
      },this.storeAuth.user.id)
    }
  },
}
</script>