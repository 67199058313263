<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsHome v-if="storeAuth.isLogin() === true && storeAuth.user !== null"></layoutsHome>
    <layoutsindex ></layoutsindex>
    
    
    <coursedetails v-if="storeCourses.course !== null"></coursedetails>
    <inner-page v-if="storeCourses.course !== null"></inner-page>
    <!-- Course Content -->
    <section v-if="storeCourses.course !== null" class="page-content course-sec">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <!-- lesson list -->
            <div class="mb-5">
              <h5 class="subs-title">Contenu du cours</h5>
              <div class="rounded border py- flex flex-col bg-white">
                <div v-for="(item,index) in storeCourses.course.lessons" :key="index"  class="flex justify-between border-b p-3">
                  <span>{{ item.title }}</span>
                  <span>{{ item.duration }}</span>
                </div> 
              </div>
            </div>
            <!-- Overview -->
            <div class="card overview-sec">
              <div class="card-body">
                <h5 class="subs-title">Description</h5>
                <p v-html="storeCourses.course.description">
                </p>
                 
              </div>
            </div>

            <!-- Instructor -->
            <div class="card instructor-sec">
              <div class="card-body">
                <h5 class="subs-title">À propos du professeur</h5>
                <div class="instructor-wrap">
                  <div class="about-instructor">
                    <div class="abt-instructor-img">
                      <router-link :to="'/instructor-profile/'+storeCourses.course.prof.pseudo"
                        ><img
                          :src="storeCourses.course.prof.photo !== null ? storeCourses.course.prof.photo : '@/assets/img/user/user1.jpg'"
                          alt="img"
                          class="img-fluid"
                      /></router-link>
                    </div>
                    <div class="instructor-detail">
                      <h5>
                        <router-link :to="'/instructor-profile/'+storeCourses.course.prof.pseudo">{{ storeCourses.course.prof.first_name }} {{ storeCourses.course.prof.last_name }}</router-link>
                      </h5>
                      <p>{{ storeCourses.course.prof.pseudo }}</p>
                    </div>
                  </div>
                  <div :class="[storeCourses.course.prof.note > 0 ? 'rating' : 'rating-img text-sm ', 'rating-img flex items-center']">
                    <template v-if="storeCourses.course.prof.note >0">
                      <i v-for="index in 5" :key="index" :class="{ 'fas fa-star filled me-1': index <= storeCourses.course.prof.note, 'fas fa-star me-1': index > storeCourses.course.prof.note }"></i>
                      <span class="d-inline-block average-rating">{{ storeCourses.course.prof.note }}</span>
                    </template>
                  </div> 
                </div>
                <div class="course-info d-flex align-items-center">
                  <div class="cou-info">
                    <img src="@/assets/img/icon/icon-01.svg" alt="" />
                    <p>{{ storeCourses.course.prof.total_courses }} Cours</p>
                  </div> 
                  <div class="cou-info">
                    <img src="@/assets/img/icon/people.svg" alt="" />
                    <p>{{ storeCourses.course.prof.total_etudiant }} Étudiant{{ storeCourses.course.prof.total_etudiant > 1 ? "s": "" }}</p>
                  </div>
                </div>
                <p v-html="storeCourses.course.prof.description"></p>
                 
              </div>
            </div>
            <!-- /Instructor -->

            <!-- Reviews -->
            <div class="card review-sec" v-if="storeNotes.listNotes.length>0">
              <div class="card-body">
                <h5 class="subs-title">Avis</h5>
                <span v-for="(item, index) in storeNotes.listNotes" :key="index">
                  <div class="instructor-wrap">
                    <div class="about-instructor">
                      <div class="abt-instructor-img">
                        <router-link :to="'/instructor-profile/'+item.author_pseudo"
                          ><img
                            :src="item.author_img"
                            alt="img"
                            class="img-fluid"
                        /></router-link>
                      </div>
                      <div class="instructor-detail">
                        <h5>
                          <router-link :to="'/instructor-profile/'+item.author_pseudo">{{ item.author_first_name }} {{ item.author_last_name }}</router-link>
                        </h5>
                      </div>
                    </div>
                    <div v-if="item.note > 0" class="rating flex items-center">
                      <i v-for="items in item.note" :key="items" class="fas fa-star filled me-1"></i>
                      <i v-for="items in (5 - item.note)" :key="items" class="fas fa-star me-1"></i>
                      <span class="d-inline-block average-rating"
                        >{{ item.note }}</span>
                    </div>
                    <div v-else class="rating flex items-center">
                      <i v-for="items in 5" :key="items" class="fas fa-star me-1"></i>
                      <span class="d-inline-block average-rating"
                        >{{ item.note }}</span
                      >
                    </div>
                  </div>
                  <p class="rev-info">
                    “ {{ item.commentaire }} “
                  </p>
                </span>
                <!-- <a href="javascript:;" class="btn btn-reply"
                  ><i class="feather-corner-up-left"></i> Reply</a
                > -->
              </div>
            </div>
            <!-- /Reviews -->

            <!-- Comment -->
            <div v-if="storeAuth.isLogin() === true && storeAuth.user !== null && isComment === false" class="card comment-sec">
              <div class="card-body">
                <h5 class="subs-title">Noter ce cours</h5>
                
                <form @submit.prevent="saveCommentaire">
                  <div class="form-group rating">
                      <i v-for="star in stars" :key="star" style="cursor: pointer;" @click="rate(star)" 
                      :class="{ 'fas fa-star filled me-1': star <= getRating, 'fas fa-star me-1': star > getRating }"></i>
                  </div>
                    
                  <div class="form-group">
                    <textarea
                      rows="4"
                      v-model="commentaire"
                      required
                      class="form-control"
                      placeholder="Commentaire"
                    ></textarea>
                  </div>
                  <div class="submit-section">
                    <button class="btn submit-btn" type="submit">
                      <div v-if="storeNotes.loadingAdd === true" class="spinner-border" role="status">
                        <span class="sr-only">Chargement...</span>
                      </div>
                      <span v-else>
                        Envoyer
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!-- /Comment -->
          </div>

          <div class="col-lg-4">
            <div class="sidebar-sec">
              <!-- Video -->
              <div class="video-sec vid-bg">
                <div class="card">
                  <div class="card-body">
                    <video
                      :poster="storeCourses.course.image"
                      style="width: 100%;height: 250px;"
                      class="video-thumbnail" 
                      data-fancybox=""
                      
                      controls
                      controlsList="nodownload"
                      >
                    <source :src="storeCourses.course.video"  type="video/mp4">
                    </video>
                    <div class="video-details">
                      <div class="course-fee d-flex align-items-center items-center justify-content-between flex" >
                        <h2 class="text-center w-full" v-if="storeCourses.course.price === '0 €'">GRATUIT</h2>
                        <h2 class="" v-else>{{ storeCourses.course.price }}</h2>
                        <div v-if="storeCourses.course.old_price !=='0 €' && storeCourses.course.old_price !==null">
                          <p class="!text-red-500" ><span>{{ storeCourses.course.old_price }}</span> </p>
                        </div>
                      </div>
                      <div class="row gx-2"> 
                        <div class="col-md-12">
                          <a href="javascript:;" class="btn btn-wish w-100 pt-3"
                            ><i class="feather-share-2"></i> Partager</a
                          >
                        </div>
                      </div>
                      <button @click="addCartCourses(storeCourses.course)" style="min-width: 200px; !important" class="btn btn-enroll w-100"
                        >{{ storeAuth.isLogin() === true && storeAuth.user !== null ? storeCourses.course.is_paid === true ? "Acceder au cours" : "Ajouter au panier" : "Ajouter au panier" }} </button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Video -->

              <!-- Include -->
              <div class="card include-sec">
                <div class="card-body">
                  <div class="cat-title">
                    <h4>Inclus dans le cours</h4>
                  </div>
                  <ul> 
                    <li class="d-flex align-items-center">
                      <img
                        src="@/assets/img/icon/video.svg"
                        class="me-2"
                        alt=""
                      />
                      <span>{{ storeCourses.course.duration }} de vidéos</span>

                    </li>
                    <li class="d-flex align-items-center">
                      <img
                        src="@/assets/img/icon/key.svg"
                        class="me-2"
                        alt=""
                      />
                      Accès à vie
                    </li>
                    <li class="d-flex align-items-center"> 
                      <img
                        src="@/assets/img/icon/mobile.svg"
                        class="me-2"
                        alt=""
                      />
                      Accès sur mobile
                    </li>
                    <li class="d-flex align-items-center">
                      <img
                        src="@/assets/img/icon/cloud.svg"
                        class="me-2"
                        alt=""
                      />
                      Métronome
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Pricing Plan -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
  import { useCoursesStore } from '@stores/courses'
  import { useNoteStore } from '@stores/notes'
  import { useCommentaireStore } from '@stores/commentaire'
  import { useAuthStore } from '@stores/auth'
import { useCartStore } from '@stores/cart'
  import { router } from '@/router';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  export default {
    data() {
      return {
        storeCourses: useCoursesStore(),
        storeNotes: useNoteStore(),
        storeCommentaire: useCommentaireStore(),
        storeAuth: useAuthStore(),
        storeCart: useCartStore(),
        commentaire: "",
        isComment: false,
        liked: false,
        currentRating: 0,
        maxRating: 5
      };
    },
    computed: {
      stars: function() {
          return Array.from({ length: this.maxRating }, (_, index) => index + 1);
      },
      getRating: {
        get(){
          return this.currentRating
        },
        set(value){
          this.currentRating = value
        }
      }
    },
    async mounted() {
      await this.storeNotes.listNoteCourses(router.currentRoute.value.params.slug);
      this.storeCourses.$subscribe(async (mutation, state) => {
        // import { MutationType } from 'pinia'
        mutation.type // 'direct' | 'patch object' | 'patch function'
        // same as cartStore.$id
        mutation.storeId // 'cart'
        // only available with mutation.type === 'patch object'
        mutation.payload
        if(state.course !== null){
          if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
            this.isComment = await this.storeNotes.checkNote({
              course_id: this.storeCourses.course.id,
            });
          }
        }
        // persist the whole state to the local storage whenever it changes
        // localStorage.setItem('cart', JSON.stringify(state))
      })
      
      
    },

    async beforeMount() {
      if (this.storeAuth.isLogin() === true) {
        await this.storeCourses.getAuth(router.currentRoute.value.params.slug);
      } else {
        await this.storeCourses.get(router.currentRoute.value.params.slug);
      }
    },

    methods: {
      async addCartCourses(courses){
      if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
        if(courses.is_paid === false){
          await this.storeCart.addCart({ course_id: courses.id })
        } else {
          router.push({ name: 'course-lesson', params: { slug: courses.slug } })
        }
      } else{
        router.push("/login")
      }
    },
      async pay(id){
        if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
          await this.storeCourses.payCourses(
            {
              course_id: id,
            }
          )
        } else{
          router.push("/login")
        }  
      },
      rate(rating) {
        console.log(rating)
        this.getRating = rating;
        console.log(this.getRating)
      },
      async saveCommentaire(){
        try {
          await this.storeNotes.addNoteCourses({
            commentaire: this.commentaire,
            note: this.currentRating,
            course_id: this.storeCourses.course.id
          }, router.currentRoute.value.params.slug)
          this.isComment = await this.storeNotes.checkNote({
            course_id: this.storeCourses.course.id,
          });
        } catch (e) {
          toast.error("Nous avons reçu une erreur pendant la soumission de votre commentaire")
          console.log(e)
        }
      }
    },
  };
</script>