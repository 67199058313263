<!-- eslint-disable vue/no-duplicate-attributes -->
<template>
  <!-- Header -->
  <header  :class="storeAuth.isLogin() === true && storeAuth.user != null || isHome ? '' : 'header header-page'">
    <div :class="storeAuth.isLogin() === true && storeAuth.user != null ? '' : 'header-fixed bg-white'">
      <nav
      :class="storeAuth.isLogin() === true && storeAuth.user != null  ? '' : 'navbar navbar-expand-lg '"
      >
      <!-- v-bind:class="$route.meta.NavbarClass" -->
        <div class="container" v-if="storeAuth.isLogin() === false || storeAuth.user == null">
          <div class="navbar-header ">
            <router-link to="/" class="navbar-brand logo">
              <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <div class="flex md:hidden">
              <div class="dropdown dropdown-bottom dropdown-end">
                <label
                  tabindex="0"
                  class="bg-white border-0 hover:text-base-200 btn m-1"
                >
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    ></path>
                  </svg>
                </label>
                <!-- si le user n'est pas conncté -->
                <ul
                  tabindex="0"
                  class="dropdown-content z-[1] menu p-2 shadow bg-white text-black rounded-box w-52"
                >
                  <li>
                    <router-link to="/register-prof">Donner des cours</router-link>
                  </li>
                  <li>
                    <router-link to="/login">Connexion</router-link>
                  </li>
                  <li class="bg-black text-white rounded shadow" >
                    <router-link to="/register" class="text-white">S'inscrire</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="main-menu-wrapper">
            <div class="menu-header">
              <router-link to="/" class="menu-logo">
                <img src="https://themusichall.fr/assets/logo.svg" class="img-fluid" alt="Logo" />
              </router-link>
              <a
                id="menu_close"
                class="menu-close"
                href="javascript:void(0);"
                @click="closeSidebar"
              >
                <i class="fas fa-times"></i>
              </a>
            </div>
            <mainnav></mainnav>
          </div>
          <signpages></signpages>
        </div>
        <!-- le user est connecté sur la home -->
        <div class="flex justify-between w-full items-end hidden" v-else>
          <div></div>
          <div class="w-full flex justify-end navbar-header p-2">
            <!-- si le user n'est pas connecté -->
            <ul v-if="storeAuth.user !== null" class="nav header-navbar-rht md:hidden" style="display: flex;" >
              <li class="nav-item">
                <router-link to="/course-message">
                  <img src="@/assets/icon/chat.svg" style="width: 24px;"  alt="img"/>
                </router-link>
              </li>
              <li class="nav-item cart-nav">
                <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown">
                  <img src="@/assets/icon/cart.svg" style="width: 24px;"  alt="img" />
                </a>
                <div class="wishes-list dropdown-menu dropdown-menu-right">
                  <div v-if="storeCart.listCart.length > 0" class="wish-header">
                    <router-link to="/cart">Voir le panier</router-link>
                    <button @click="pay" class="float-end">Procéder au paiement</button>
                  </div>
                  <div class="wish-content" :style="storeCart.listCart.length === 0 ? {height: '69px !important'} : null">
                    <ul v-if="storeCart.listCart.length > 0">
                      <li v-for="n in storeCart.listCart.length < 3 ? storeCart.listCart.length : 3" :key="n">
                        <div class="media">
                          <div class="d-flex media-wide">
                            <div class="avatar">
                              <router-link :to="{ name: 'course-details', params: { slug: storeCart.listCart[n-1].course_slug } }">
                                <img alt="" :src="storeCart.listCart[n-1].course_image" />
                              </router-link>
                            </div>
                            <div class="media-body">
                              <h6>
                                <router-link :to="{ name: 'course-details', params: { slug: storeCart.listCart[n-1].course_slug } }">{{ storeCart.listCart[n-1].course_title }}</router-link>
                              </h6>
                              <p>Par {{ storeCart.listCart[n-1].course_prof_name }}</p>
                              <h5>{{ storeCart.listCart[n-1].course_price }}€</h5>  <!--<span v-if="storeCart.listCart[n-1].course_old_price !== null && storeCart.listCart[n-1].course_old_price !== ''">{{ storeCart.listCart[n-1].course_old_price }}€</span>-->
                            </div>
                          </div>
                          <div class="remove-btn">
                            <button @click="deleteCart(storeCart.listCart[n-1].course_id)" class="btn">Supprimer</button>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="d-flex  justify-content-center align-items-center mt-4" v-else>
                      Votre panier est vide
                    </div>
                    <div class="total-item">
                      <!-- <h6>Subtotal : $ 600</h6> -->
                      <h5 v-if="storeCart.totalPrice >0">Total : {{ storeCart.totalPrice }} €</h5>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item noti-nav">
                <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown">
                  <img src="@/assets/icon/notification.svg" width="24px" height="24px" alt="img" />
                </a>
                <div class="notifications dropdown-menu dropdown-menu-right">
                  <div class="topnav-dropdown-header">
                    <span class="notification-title"
                      >Notifications
                    </span>
                  </div>
                  <div class="noti-content">
                    <ul class="notification-list">
                      
                      <li class="notification-message">
                        <div class="media d-flex">
                          <div class="media-body">
                            Aucune notification
                          </div>
                        </div>
                      </li> 
                    </ul>
                  </div>
                </div>
              </li>
              <li class="nav-item user-nav">
                <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown">
                  <span class="user-img ici">
                    <img :src="storeAuth.user.photo !== null ? storeAuth.user.photo : '@/assets/img/user/user11.jpg'" alt="" />
                    <span class="status online"></span>
                  </span>
                </a>
                <div
                  class="users dropdown-menu dropdown-menu-right"
                  data-popper-placement="bottom-end"
                >
                  <div class="user-header">
                    <div class="avatar avatar-sm">
                      <img
                        :src="storeAuth.user.photo !== null ? storeAuth.user.photo : '@/assets/img/user/user11.jpg'"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                    <div class="user-text">
                      <h6>{{ storeAuth.user.first_name }} {{ storeAuth.user.last_name.substring(0,1) }}</h6>
                      <p class="text-muted mb-0">{{ storeAuth.user.role }}</p>
                    </div>
                  </div>
                  <router-link class="dropdown-item" to="/setting-edit-profile"
                    ><i class="feather-user me-1"></i>Mon Profil</router-link
                  >
                  <router-link class="dropdown-item" :to="storeAuth.user.role === 'professeur' ? '/instructor-dashboard' : '/mes-course'"
                    ><i class="feather-circle me-1"></i>{{ storeAuth.user.role === 'professeur' ? 'Dashboard' : 'Mes cours' }}</router-link
                  >

                  <router-link class="dropdown-item" v-if="storeAuth.user.role === 'professeur'" to="'/instructor-course'"
                    ><i class="feather-circle me-1"></i>Gérer mes cours</router-link
                  >
                  <router-link class="dropdown-item" to="/instructor-list">
                    <i class="fa fa-users me-1"></i> Nos professeurs
                  </router-link>
                  
                  <router-link class="dropdown-item" to="/course">
                    <i class="fa fa-book me-1"></i> Catalogue de formation
                  </router-link>
                  <button class="dropdown-item" @click="logOut"
                    ><i class="feather-log-out me-1"></i>Se déconnecter</button
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
import { useAuthStore } from '@stores/auth'
import { useNotificationStore } from '@stores/notification'
import { useCartStore } from '@stores/cart'
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      storeNotification: useNotificationStore(),
      storeCart: useCartStore(),
      isSticky: false,
      isSidebarOpen: true,
    };
  },
  computed: {
    isHome() {
      var check =  this.$route.path === "/" ;
      console.log(check)
      return check;
    },
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
      await this.storeNotification.list();
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
    async logOut(){
      await this.storeAuth.logOut()
    }
  },
};
</script>
