<!-- eslint-disable vue/no-unused-components -->
<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <!--Dashbord Student -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>

          <!-- Student Security -->
          <div class="col-xl-9 col-md-8">
            <div class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3>Sécurité</h3>
                </div>
                <div class="checkout-form personal-address">
                  <div class="personal-info-head">
                    <h4>Changer mon mot de passe</h4> 
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <form method="post" @submit.prevent="updatedPassword">
                        <div class="form-group">
                          <label class="form-control-label">Mot de passe actuel</label>
                          <input required v-model="old_password" type="password" class="form-control" />
                        </div>
                        <div class="form-group">
                          <label class="form-control-label">Nouveau mot de passe</label>
                          <div class="pass-group" id="passwordInput">
                            <input
                              name="password"
                              required
                              :type="showPassword ? 'text' : 'password'"
                              class="form-control pass-input mt-2"
                              :class="{ 'password-error': validationError }"
                              v-model="password"
                              @input="handlePasswordChange"
                            />
                            <span @click="toggleShow" class="toggle-password-sec">
                              <i
                                :class="{
                                  'fas fa-eye': showPassword,
                                  'fas fa-eye-slash': !showPassword,
                                }"
                              ></i>
                            </span>
                            <div class="invalid-feedback">{{ errors.password }}</div>
                            <div class="emailshow text-danger" id="password"></div>

                            <!-- Conditionally render password strength only if there is a password -->
                            <div
                              v-if="password"
                              id="passwordStrength"
                              style="display: flex"
                              :class="[
                                'password-strength',
                                strength === 'poor' ? 'poor-active' : '',
                                strength === 'weak' ? 'avg-active' : '',
                                strength === 'strong' ? 'strong-active' : '',
                                strength === 'heavy' ? 'heavy-active' : '',
                              ]"
                            >
                              <span
                                id="poor"
                                class="active"
                                :class="{ active: strength === 'poor' }"
                              ></span>
                              <span
                                id="weak"
                                class="active"
                                :class="{ active: strength === 'weak' }"
                              ></span>
                              <span
                                id="strong"
                                class="active"
                                :class="{ active: strength === 'strong' }"
                              ></span>
                              <span
                                id="heavy"
                                class="active"
                                :class="{ active: strength === 'heavy' }"
                              ></span>
                            </div>

                            <!-- Conditionally render password information only if there is a password -->
                            <div v-if="password" id="passwordInfo">
                              <span v-if="validationError === 1"></span>
                              <span v-else-if="validationError === 2" style="color: red"
                                >😠 Faible. Doit contenir au moins 8 caractères.</span
                              >
                              <span
                                v-else-if="validationError === 3"
                                style="color: orange"
                                >😲 Average. Must contain at least 1 letter or
                                number</span
                              >
                              <span v-else-if="validationError === 4" style="color: blue"
                                >🙂 Presque. Doit contenir un symbole spécial.</span
                              >
                              <span v-else-if="validationError === 5" style="color: green"
                                >😊 Génial ! Vous avez un mot de passe sécurisé.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="form-control-label">Confirmez votre nouveau mot de passe </label>
                          <input required v-model="new_password_confirmation" type="password" class="form-control" />
                        </div>
                        <div class="update-profile save-password">
                          <button type="submit" class="btn btn-primary">
                            <div v-if="storeAuth.loadingPasse === true" class="spinner-border" role="status">
                              <span class="sr-only">Chargement...</span>
                            </div>
                            <span v-else>
                              Modifier mon mot de passe
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Student Security -->
        </div>
      </div>
    </div>
    <!-- /Dashbord Student -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { Form, Field } from "vee-validate";
import { router } from "@/router";
import { useAuthStore } from '@stores/auth'
import * as Yup from "yup";
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      password: "",
      old_password: "",
      new_password_confirmation: "",
      showPassword: false,
      validationError: 0,
      strength: "",
      errors: {
        password: "",
      },
    };
  },
  components: {
    Form,
    Field,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required").email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    });
    const onSubmit = (values) => {
      if (values.password === values.password) {
        let Rawdata = localStorage.getItem("storedData");
        let Pdata = [];
        Pdata = JSON.parse(Rawdata);
        const Eresult = Pdata.find(({ email }) => email == values.email);
        if (Eresult) {
          document.getElementById("email").innerHTML = "This email are already exist";
        } else {
          Pdata.push(values);
          const jsonData = JSON.stringify(Pdata);
          router.push("/login");
          localStorage.setItem("storedData", jsonData);
        }
      } else {
        document.getElementById("password").innerHTML = "Password not matching";
      }
    };
    return {
      schema,
      onSubmit,
    };
  },
  methods: {
    async updatedPassword() {
      await this.storeAuth.updatePassword({
        old_password: this.old_password,
        new_password: this.password,
        new_password_confirmation: this.new_password_confirmation
      })
    },
    handlePasswordChange() {
      let passwordValue = this.password;
      let passwordLength = passwordValue.length;
      let poorPassword = /[a-z]/.test(passwordValue);
      let weakPassword = /(?=.*?[0-9])/.test(passwordValue);
      let strongPassword = /(?=.*?[#?!@$%^&*-])/.test(passwordValue);
      let whitespace = /^\s*$/.test(passwordValue);

      if (passwordValue !== "") {
        if (whitespace) {
          this.errors.password = "whitespaces are not allowed";
        } else {
          this.errors.password = "";
          this.poorPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.weakPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.strongPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.heavyPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
        }
      } else {
        this.errors.password = "";
        this.validationError = 0;
        this.strength = "";
      }
    },

    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    poorPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword) {
      if (passwordLength < 8) {
        this.validationError = 2;
        this.strength = "poor";
      }
    },

    weakPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword) {
      if (passwordLength >= 8 && (poorPassword || weakPassword || strongPassword)) {
        this.validationError = 3;
        this.strength = "weak";
      }
    },

    strongPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword) {
      if (passwordLength >= 8 && poorPassword && (weakPassword || strongPassword)) {
        this.validationError = 4;
        this.strength = "strong";
      }
    },

    heavyPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword) {
      if (passwordLength >= 8 && poorPassword && weakPassword && strongPassword) {
        this.validationError = 5;
        this.strength = "heavy";
      }
    },
  },
};
</script>
