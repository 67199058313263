<template>
  <div class="tab-content">
    <!-- UI/UX Design -->
    <div class="tab-pane fade active show" id="ux-design-tab">
      <div class="ux-design-five">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-01.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user2.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6>
                        <router-link to="course-details">Cristofer Nolen</router-link>
                      </h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-02.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-03.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user3.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6>
                        <router-link to="course-details">Russell T. Johnson</router-link>
                      </h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-04.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user4.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-05.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user5.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6>
                        <router-link to="course-details">Russell T. Johnson</router-link>
                      </h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-06.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /UI/UX Design -->

    <!--  Development -->
    <div class="tab-pane fade" id="development-tab">
      <div class="ux-design-five">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-06.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-05.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-04.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-03.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-02.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-01.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- / Development -->

    <!-- Marketing -->
    <div class="tab-pane fade" id="marketing-tab">
      <div class="ux-design-five">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-01.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-02.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-03.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-04.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-05.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-06.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /Marketing -->

    <!--  Business -->
    <div class="tab-pane fade" id="business-tab">
      <div class="ux-design-five">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-06.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-05.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-04.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-03.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-02.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-01.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- / Business -->

    <!-- Technology -->
    <div class="tab-pane fade" id="technology-tab">
      <div class="ux-design-five">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-01.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-02.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-03.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-04.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-05.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-06.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /Technology -->

    <!--  All Category -->
    <div class="tab-pane fade" id="all-category-tab">
      <div class="ux-design-five">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-06.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-05.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-04.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-03.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-02.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <div class="course-box-five">
              <div class="product-five">
                <!-- Product image -->
                <div class="product-img-five">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/featured-course-01.jpg"
                    />
                  </router-link>
                  <div class="heart-five">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <!-- /Product image -->

                <!-- Product Content -->
                <div class="product-content-five">
                  <div class="course-group-five">
                    <div class="course-group-img-five">
                      <router-link to="course-details">
                        <img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" />
                      </router-link>
                      <h6><router-link to="course-details">Nicole Brown</router-link></h6>
                    </div>
                    <div class="course-share-five">
                      <div class="rating flex items-center">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <h3 class="product-five-title">
                    <router-link to="course-details"
                      >The Complete Web Developer PHP framework Course 2.0</router-link
                    >
                  </h3>
                  <div class="info-five-middle">
                    <div class="course-view-five">
                      <span class="me-2"
                        ><a href="javascript:;"><i class="fa-regular fa-clockss"></i></a
                      ></span>
                      <p>8hr 30min</p>
                    </div>
                    <div class="rating-img">
                      <span class="me-2"><i class="fa-solid fa-book-open"></i></span>
                      <p>10+ Lesson</p>
                    </div>
                  </div>
                  <div class="price-five-group">
                    <p>Graphic</p>
                    <h3>$300.00</h3>
                  </div>
                </div>
                <!--/ Product Content -->
              </div>
              <!-- Ovelay button -->
              <div class="joing-course-ovelay">
                <router-link to="course-details" class="joing-course-btn"
                  >Join Course</router-link
                >
              </div>
              <!-- Ovelay button -->
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- / All Category -->
  </div>
</template>
