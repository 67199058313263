<template>
  <ul v-if="storeAuth.user !== null" class="nav header-navbar-rht">
    <li class="nav-item">
      <router-link to="/course"
        ><img src="@/assets/icon/search.svg" style="width: 24px;"  alt="img"/>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/course-message"
        ><img src="@/assets/icon/chat.svg" style="width: 24px;"  alt="img"/>
      </router-link>
    </li>
    <li class="nav-item cart-nav">
      <a href="javascript:void(0)" class="dropdown-toggle " data-bs-toggle="dropdown">
        <img src="@/assets/icon/cart.svg" style="width: 24px;"  alt="img" />
      </a>
      <div class="wishes-list dropdown-menu dropdown-menu-right">
        <div v-if="storeCart.listCart.length > 0" class="wish-header">
          <router-link to="/cart">Voir le panier</router-link>
          <button v-if="storeCart.listCart.length > 0" @click="pay" class="float-end">Procéder au paiement</button>
        </div>
        <div class="wish-content" :style="storeCart.listCart.length === 0 ? {height: '69px !important'} : null">
          <ul v-if="storeCart.listCart.length > 0">
            <li v-for="n in storeCart.listCart.length < 3 ? storeCart.listCart.length : 3" :key="n">
              <div class="media">
                <div class="d-flex media-wide">
                  <div class="avatar">
                    <router-link :to="{ name: 'course-details', params: { slug: storeCart.listCart[n-1].course_slug } }">
                      <img alt="" :src="storeCart.listCart[n-1].course_image" />
                    </router-link>
                  </div>
                  <div class="media-body">
                    <h6>
                      <router-link :to="{ name: 'course-details', params: { slug: storeCart.listCart[n-1].course_slug } }">{{ storeCart.listCart[n-1].course_title }}</router-link>
                    </h6>
                    <p>Par {{ storeCart.listCart[n-1].course_prof_name }}</p>
                    <h5>{{ storeCart.listCart[n-1].course_price }}€</h5>
                  </div>
                </div>
                <div class="remove-btn">
                  <button @click="deleteCart(storeCart.listCart[n-1].course_id)" class="btn">Supprimer</button>
                </div>
              </div>
            </li>
          </ul>
          <div class="d-flex  justify-content-center align-items-center mt-4" v-else>
            Votre panier est vide
          </div>
          <div class="total-item">
            <!-- <h6>Subtotal : $ 600</h6> -->
            <h5>Total : {{ storeCart.totalPrice }} €</h5>
          </div>
        </div>
      </div>
    </li>
    <li class="nav-item noti-nav">
      <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown">
        <img src="@/assets/icon/notification.svg" style="width: 24px;" alt="img" />
      </a>
      <div class="notifications dropdown-menu dropdown-menu-right">
        <div class="topnav-dropdown-header">
          <span class="notification-title"
            >Notifications
          </span>
        </div>
        <div class="noti-content">
          <ul class="notification-list">
            <li v-for="(item, index) in storeNotification.listNotifications.slice(0,6)" :key="index" class="notification-message">
              <div class="media d-flex">
                <div>
                  <router-link to="notifications" class="avatar">
                    <img class="avatar-img" alt="" src="@/assets/img/user/user1.jpg" />
                  </router-link>
                </div>
                <div class="media-body">
                  <h6 class="font-light">
                    <router-link to="#"
                      >{{ item.content.substring(0, 100)}}...
                    </router-link>
                  </h6>
                </div>
              </div>
            </li>
            
          </ul>
        </div>
      </div>
    </li>
    <li class="nav-item user-nav">
      <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown">
        <span class="user-img">
          <img :src="storeAuth.user.photo !== null ? storeAuth.user.photo : '@/assets/img/user/user11.jpg'" alt="" />
          <span class="status online"></span>
        </span>
      </a>
      <div
        class="users dropdown-menu dropdown-menu-right"
        data-popper-placement="bottom-end"
      >
        <div class="user-header">
          <div class="avatar avatar-sm">
            <img
              :src="storeAuth.user.photo !== null ? storeAuth.user.photo : '@/assets/img/user/user11.jpg'"
              alt="User Image"
              class="avatar-img rounded-circle"
            />
          </div>
          <div class="user-text">
            <h6>{{ storeAuth.user.first_name }} {{ storeAuth.user.last_name.substring(0,1) }}</h6>
            <p class="text-muted mb-0">{{ storeAuth.user.role }}</p>
          </div>
        </div>
        <router-link class="dropdown-item" to="/setting-edit-profile"
          ><i class="feather-user me-1"></i> Profil</router-link
        >
        <router-link class="dropdown-item" :to="storeAuth.user.role === 'professeur' ? '/instructor-dashboard' : '/mes-course'"
          ><i class="feather-circle me-1"></i>{{ storeAuth.user.role === 'professeur' ? 'Dashboard' : 'Mes cours' }}</router-link
        >
        <router-link class="dropdown-item" to="/instructor-list">
          <i class="fa fa-users me-1"></i> Nos professeurs
        </router-link>
        
        <router-link class="dropdown-item" to="/course">
          <i class="fa fa-book me-1"></i> Catalogue de formation
        </router-link>
        <button class="dropdown-item" @click="logOut"
          ><i class="feather-log-out me-1"></i> Se déconnecter</button
        >
      </div>
    </li>
  </ul>
</template>
<script>
import { useAuthStore } from '@stores/auth'
import { useNotificationStore } from '@stores/notification'
import { useCartStore } from '@stores/cart'
import { router } from '@/router';
export default {
  data() {
    return {
      storeAuth: useAuthStore(),
      storeNotification: useNotificationStore(),
      storeCart: useCartStore()
    };
  },
  async mounted() {
    if(this.storeAuth.user !== null){
      await this.storeNotification.list();
    }
  },
  methods: {
    formatDate(date) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Intl.DateTimeFormat('us-US', options).format(date);
    },

    async deleteCart(courses){
      await this.storeCart.deleteByCart({ course_id: courses })
    },

    async pay(){
      if(this.storeAuth.isLogin() === true && this.storeAuth.user !== null){
        await this.storeCart.payCourses()
      } else{
        router.push("/login")
      }
    },

    async logOut(){
      await this.storeAuth.logOut()
    }
  },
};
</script>
