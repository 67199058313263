<template>
<!-- Breadcrumb -->
<div class="breadcrumb-bar">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="breadcrumb-list">
                    <nav aria-label="breadcrumb" class="page-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">Accueil</router-link></li>
                            <li class="breadcrumb-item" aria-current="page"><router-link to="/course">Catalogue de formation</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ storeCourses.course.title }}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Breadcrumb -->
</template>

<script>
  import { useCoursesStore } from '@stores/courses'
  export default {
    data() {
      return {
        storeCourses: useCoursesStore(),
      };
    },
  };
</script>