<template>
  <!-- Main Wrapper -->
  <section class="flex w-full bg-gray-100 items-center min-h-screen justify-center">
    <div class="flex w-full md:w-[40%] big_screen:w-[40%] h-auto">
      <div class="bg-white w-full rounded-lg xs:mx-2 lg:mx-0 lg:w-full rounded-lg px-6 py-9 flex flex-col space-y-4 justify-center">
        <div class="flex justify-between w-full items-center">
          <img src="https://themusichall.fr/assets/logo.svg"  alt="Logo" class="w-12" />
        </div>
        <h2 class="font-bold text-2xl">Finalisez votre profil enseignant</h2>
        <div class="flex space-x-3 justify-between">
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span >1</span>
            <div class="personal-text">
              <h4>Catégories et Profil</h4>
            </div>
          </div>
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span class="active-color active-bar">2</span>
            <div class="personal-text">
              <h4>Profils sociaux</h4>
            </div>
          </div>
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span>3</span>
            <div class="personal-text">
              <h4>Biographie</h4>
            </div>
          </div> 
        </div>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label class="form-control-label">X Twiter </label>
            <input
              type="text"
              v-model="storeAuth.prof.twitter"
              class="form-control"
              placeholder="URL du profil X"
            />
          </div>
          <div class="form-group">
            <label class="form-control-label">Facebook</label>
            <input
              type="text"
              v-model="storeAuth.prof.facebook"
              class="form-control"
              placeholder="URL du profil Facebook"
            />
          </div>
          <div class="form-group">
            <label class="form-control-label">Instagram</label>
            <input
              type="text"
              v-model="storeAuth.prof.instagram"
              class="form-control"
              placeholder="URL du profil Instagram"
            />
          </div> 
          <div class="form-group">
            <label class="form-control-label">YouTube</label>
            <input  v-model="storeAuth.prof.youtube" type="text" class="form-control" placeholder="URL Youtube" />
          </div>
          <div class="btn-group d-flex flex justify-between">
            <div class="back-btn">
              <router-link
                to="register-step-one"
                class="btn btn-black"
                type="submit"
                >Retour</router-link
              >
            </div>
            <div class="next-btn">
              <button class="btn btn-primary btn-start">Suivant</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  <!-- /Main Wrapper -->
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css";
import { useAuthStore } from '@stores/auth'
export default { 
  data() {
    return {
      storeAuth: useAuthStore(),
    };
  },
  computed: {
    isCheck(){
      if(this.storeAuth.prof.facebook === '' || this.storeAuth.prof.youtube === '' || this.storeAuth.prof.instagram === '' || this.storeAuth.prof.twitter === ''){
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/register-step-three");
    },
  },
};
</script>
