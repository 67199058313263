<template>
  <!-- Main Wrapper -->
  <section class="flex w-full bg-gray-100 items-center min-h-screen justify-center">
    <div class="flex w-full md:w-[50%] big_screen:w-[40%] h-auto">
      <div class="hidden lg:flex !bg-[#58bbde] lg:w-1/3 rounded-l-lg px-6 py-9  flex-col space-y-4 justify-center">
        <img src="https://themusichall.fr/assets/hero.png" class="w-full">
      </div>
      <div class="bg-white w-full rounded-lg md:rounded-l-none xs:mx-2 lg:mx-0 lg:w-2/3 rounded-r-lg px-6 py-9 flex flex-col space-y-4 justify-center">
        <div class="flex justify-between w-full items-center">
          <a href="/"><img src="https://themusichall.fr/assets/logo.svg"  alt="Logo" class="w-12" /></a>
          <a href="/" class="p-2 rounded bg-black text-white font-bold"> <i class="fa fa-arrow-left"></i> Retour</a>
        </div>
        <h2 class="font-bold text-2xl">Inscription</h2>
        <Form @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
            > 
            <div class="flex space-x-3">
              <div class="form-group md:w-1/2">
                <label class="form-control-label">Prénom</label>
                <Field
                  name="last_name"
                  type="text"
                  class="form-control mt-2"
                  :class="{ 'is-invalid': errors.last_name }"
                />
                <div class="invalid-feedback">{{ errors.last_name }}</div>
                <div class="emailshow text-danger" id="last_name"></div>
              </div>
              <div class="form-group md:w-1/2">
                <label class="form-control-label">Nom</label>
                <Field
                  name="first_name"
                  type="text"
                  class="form-control mt-2"
                  :class="{ 'is-invalid': errors.first_name }"
                />
                <div class="invalid-feedback">{{ errors.first_name }}</div>
                <div class="emailshow text-danger" id="first_name"></div>
              </div>
            </div>
            <div class="flex space-x-3">
              <div class="form-group md:w-1/2">
                <label class="form-control-label">Email</label>
                <Field
                  name="email"
                  type="text"
                  class="form-control mt-2"
                  :class="{ 'is-invalid': errors.email }"
                />
                <div class="invalid-feedback">{{ errors.email }}</div>
                <div class="emailshow text-danger" id="email"></div>
              </div>
              <div class="form-group md:w-1/2">
                <label class="form-control-label">Pseudo</label>
                <Field
                  name="pseudo"
                  type="text"
                  class="form-control mt-2"
                  :class="{ 'is-invalid': errors.pseudo }"
                />
                <div class="invalid-feedback">{{ errors.pseudo }}</div>
                <div class="emailshow text-danger" id="pseudo"></div>
              </div>
            </div>
              <div class="form-group">
                <label class="form-control-label">Password</label>
                <div class="pass-group" id="passwordInput">
                  <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control pass-input mt-2"
                      :class="{ 'is-invalid': errors.password }"
                      @input="handlePasswordChange"
                    />
                  <span @click="toggleShow" class="toggle-password">
                    <i
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                  <div class="emailshow text-danger" id="password"></div>

                  <!-- Conditionally render password strength only if there is a password -->
                  <div
                    v-if="password"
                    id="passwordStrength"
                    style="display: flex"
                    :class="[
                      'password-strength',
                      strength === 'poor' ? 'poor-active' : '',
                      strength === 'weak' ? 'avg-active' : '',
                      strength === 'strong' ? 'strong-active' : '',
                      strength === 'heavy' ? 'heavy-active' : '',
                    ]"
                  >
                    <span
                      id="poor"
                      class="active"
                      :class="{ active: strength === 'poor' }"
                    ></span>
                    <span
                      id="weak"
                      class="active"
                      :class="{ active: strength === 'weak' }"
                    ></span>
                    <span
                      id="strong"
                      class="active"
                      :class="{ active: strength === 'strong' }"
                    ></span>
                    <span
                      id="heavy"
                      class="active"
                      :class="{ active: strength === 'heavy' }"
                    ></span>
                  </div>

                  <!-- Conditionally render password information only if there is a password -->
                  <div v-if="password" id="passwordInfo">
                    <span v-if="validationError === 1"></span>
                    <span v-else-if="validationError === 2" style="color: red"
                      >😠 Faible. Doit contenir au moins 8 caractères</span
                    >
                    <span v-else-if="validationError === 3" style="color: orange"
                      >😲 Moyenne. Doit contenir au moins une lettre ou un numéro</span
                    >
                    <span v-else-if="validationError === 4" style="color: blue"
                      >🙂 Presque. Doit contenir un symbole spécial</span
                    >
                    <span v-else-if="validationError === 5" style="color: green"
                      >😊 Génial! Vous avez un mot de passe sécurisé.</span
                    >
                  </div>
                </div>
              </div> 
              
              <div class="d-grid">
                <button class="btn btn-primary btn-start" type="submit">
                  <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                    <span class="sr-only">Chargement...</span>
                  </div>
                  <span v-else>
                    S'inscrire
                  </span>
                </button>
              </div>
              <div class="forgot flex justify-center">
                <span
                  > <router-link class="forgot-link" to="login">
                    Se connecter
                  </router-link></span
                >
                
              </div> 
        </Form>
      </div>
    </div>
  </section>
  <!-- /Main Wrapper -->
</template>
<script>
import { Form, Field } from "vee-validate";
import { useAuthStore } from '@stores/auth'
import * as Yup from "yup";
export default {
  data() {
    return {
      password: "",
      showPassword: false,
      validationError: 0,
      strength: "",
      storeAuth: useAuthStore(),
      errors: {
        password: "",
      },
    };
  },
  components: {
    Form,
    Field,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().required("Email est obligatoire").email("Email is invalid"),
      first_name: Yup.string().required("Nom est obligatoire"),
      last_name: Yup.string().required("Prénom est obligatoire"),
      pseudo: Yup.string().required("pseudo est obligatoire"),
      password: Yup.string()
        .min(6, "Le mot de passe doit comporter au moins 6 caractères")
        .required("Mot de passe est obligatoire"),
    });
    return {
      schema,
    };
  },
  methods: {
    async onSubmit(values) {
      console.log(values)
      await this.storeAuth.register({
        email: values.email, 
        first_name: values.first_name,
        last_name: values.last_name,
        pseudo: values.pseudo,
        password: values.password})
    },
    handlePasswordChange() {
      let passwordValue = this.password;
      let passwordLength = passwordValue.length;
      let poorPassword = /[a-z]/.test(passwordValue);
      let weakPassword = /(?=.*?[0-9])/.test(passwordValue);
      let strongPassword = /(?=.*?[#?!@$%^&*-])/.test(passwordValue);
      let whitespace = /^\s*$/.test(passwordValue);

      if (passwordValue !== "") {
        if (whitespace) {
          this.errors.password = "les espaces ne sont pas autorisés";
        } else {
          this.errors.password = "";
          this.poorPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.weakPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.strongPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
          this.heavyPasswordStrength(
            passwordLength,
            poorPassword,
            weakPassword,
            strongPassword
          );
        }
      } else {
        this.errors.password = "";
        this.validationError = 0;
        this.strength = "";
      }
    },

    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    poorPasswordStrength(passwordLength) {
      if (passwordLength < 8) {
        this.validationError = 2;
        this.strength = "poor";
      }
    },

    weakPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword) {
      if (passwordLength >= 8 && (poorPassword || weakPassword || strongPassword)) {
        this.validationError = 3;
        this.strength = "weak";
      }
    },

    strongPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword) {
      if (passwordLength >= 8 && poorPassword && (weakPassword || strongPassword)) {
        this.validationError = 4;
        this.strength = "strong";
      }
    },

    heavyPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword) {
      if (passwordLength >= 8 && poorPassword && weakPassword && strongPassword) {
        this.validationError = 5;
        this.strength = "heavy";
      }
    },
  },
};
</script>
