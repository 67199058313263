<template>
  <!-- Main Wrapper -->
  <section class="flex w-full bg-gray-100 items-center min-h-screen justify-center">
    <div class="flex w-full md:w-[40%] big_screen:w-[40%] h-auto">
      <div class="bg-white w-full rounded-lg xs:mx-2 lg:mx-0 lg:w-full rounded-lg px-6 py-9 flex flex-col space-y-4 justify-center">
        <div class="flex justify-between w-full items-center">
          <img src="https://themusichall.fr/assets/logo.svg"  alt="Logo" class="w-12" />
        </div>
        <h2 class="font-bold text-2xl">Finalisez votre profil enseignant</h2>
        <div class="flex space-x-3 justify-between">
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span >1</span>
            <div class="personal-text">
              <h4>Catégories et Profil</h4>
            </div>
          </div>
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span >2</span>
            <div class="personal-text">
              <h4>Profils sociaux</h4>
            </div>
          </div>
          <div class="shadow-sm border personal-detail d-flex align-items-center">
            <span class="active-color active-bar">3</span>
            <div class="personal-text">
              <h4>Biographie</h4>
            </div>
          </div> 
        </div>
        <form @submit.prevent="submitForm">
          <div class="row">
            <div class="form-group mb-0">
              <label class="add-course-label">Biographie</label>
              <textarea class="form-control" v-model="bio" name="" id="" cols="30" rows="10"></textarea>
              <span class="flex justify-end " :class="bio.length<400 ? 'text-red-500' : ''"> <span id="text_bio" v-html="bio.length"></span>/400</span>
            </div>
            <div class="col-lg-12 mt-4">
              <div class="btn-group d-flex flex justify-between">
                <div class="back-btn">
                  <router-link to="register-step-two" class="btn btn-black"
                    >Retour</router-link
                  >
                </div>
                <div v-if="isCheck === true" class="next-btn">
                  <button class="btn btn-primary btn-start">
                    <div v-if="storeAuth.loading === true" class="spinner-border" role="status">
                      <span class="sr-only">Chargement...</span>
                    </div>
                    <span v-else>
                      Soumettre
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  <!-- /Main Wrapper -->
</template>
<script>
import "vue3-circle-progress/dist/circle-progress.css";
import { useAuthStore } from '@stores/auth'
export default { 
  data() {
    return {
      bio: "",
      storeAuth: useAuthStore(),
      Select: ["Select", "India", "America", "London"],
    };
  },
  computed: {
    isCheck(){
      if(this.bio === '' || this.bio.length <400){
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    async submitForm() {
      this.storeAuth.prof.description = this.bio
      await this.storeAuth.updateProf("auth")
    },
    summernoteChange(newValue) {
      console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      console.log("summernoteImageLinkInsert()", args);
    },
  },
};
</script>
